import { useStaticQuery, graphql } from "gatsby"

const useFilteredSites = () => {
  let query = useStaticQuery(graphql`
    query {
      allStrapiSites(filter: { sede: { eq: true } }) {
        nodes {
          city
          id
          name
          phone1
          street
          streetNumber
          slug
          sede
          image {
            title
            alt
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      centersAllStrapiSites: allStrapiSites(
        filter: { centroDeExperiencias: { eq: true } }
      ) {
        nodes {
          city
          id
          name
          phone1
          street
          streetNumber
          slug
          sede
          image {
            title
            alt
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  query = query?.allStrapiSites?.nodes.concat(
    query?.centersAllStrapiSites?.nodes
  )
  let uniqueQuery = []
  query?.forEach(item => {
    if (!uniqueQuery.some(i => i.id === item.id)) {
      uniqueQuery.push(item)
    }
  })

  return uniqueQuery
}
export default useFilteredSites
