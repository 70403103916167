import * as React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import "./_numbers.scss"

const Numbers = ({ alt, variant, picture, name, number, cols }) => {
  const image = getImage(picture)

  return (
    <div
      className={`col-12 col-md-${cols} align-items-center justify-content-center ${variant}`}
    >
      <div className="row ">
        <div className="col-4 text-right px-0  image">
          <GatsbyImage image={image} alt={alt} />
        </div>
        <div className="col-8 text-left">
          <p className="cifra">{number}</p>
        </div>
      </div>
      <div className=" col-12 px-0 text-center">
        <p className="name mb-1">{name}</p>
      </div>
    </div>
  )
}

export default Numbers
