import React from "react"
import { usePages } from "../../hooks"
import "./_childPagesSection.scss"
import { ChildPageCard } from "../index"

const ChildPagesSection = ({ pageId, title, theme }) => {
  const data = usePages()

  const childPages = []
  data?.allStrapiPages?.nodes.forEach(page => {
    if (page.strapiParent?.id === pageId) childPages.push(page)
  })

  const childPagesList = childPages.map(page =>   
    <ChildPageCard
      key={page?.id}
      title={page?.title}
      summary={page?.summary}
      slug={page?.slug}
      nameParent={page?.strapiParent?.name}
      img={page?.image?.nameImage}
    />
  )

  return (
    childPages.length > 0 && (
      <div className={`container-fluid childPagesSection ${theme}-theme`}>
      <div className="row mt-2 mb-3">
        <h2 className="col-12 text-center">{title}</h2>
      </div>
      <div className="container d-flex flex-wrap justify-content-center">{childPagesList}</div>
    </div>
    )

  )
}

export default ChildPagesSection
