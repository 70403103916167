import React from "react"
import { AcademicOffer } from "../components"
import Layout from "../templates/layout.template"

const Academic = () => (
  <Layout whatsapp={true}>
    <AcademicOffer />
  </Layout>
)

export default Academic
