import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout.template"
import {
  HeaderPage,
  Seo,
  Content,
  CustomSection,
  Map
} from "../components"
import "./_pageEvent.scss"


const PageEvent = ({ data }) => {
  const page = data.strapiEvents
  let SEO = ""
  if (page.pageMetadata !== null && page.pageMetadata !== undefined && page.pageMetadata.pageTitle !== null) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = (
      <Seo
        title={`${page.title}`}
      />
    )
  }
  let formID = null

  page.sections.forEach(item => {
    if (item.type === "form") {
      formID = item.fields.anchor
    }
  })

  const headerPage = (
    <HeaderPage
      title={page.title}
      subtitle={page.event_type.name}
      image={page.eventImage}
      video={page.video}
      slider={page.slider}
      time={page.time}
      dateMonth={page.formatDateMonth}
      dateDay={page.formatDateDay}
      slug={page.slug}
      form={formID}
      pageUrl={`/eventos/${page.slug}`}
      paginaName={["Eventos", page.title]}
    />
  )

  //Objeto para enviar a al form

  //const dateEvent = page.date + "T" + page.time+".000Z"
  const dateEvent = page.date + "T" + page.time + ".000-03:00"

  const formFields = [
    {
      field: 'int_nombre_evento',
      value: page.title
    },
    {
      field: 'int_fecha_hora_evento',
      value: dateEvent
    }
  ]

  const content =
    page.sections.length !== 0 ? (
      <Content content={page.sections} />
    ) : (
      <div className="col-12 col-md-7"></div>
    )
  const aside = ((page.site !== undefined) && (page.site !== null) && (page.site.latitude !== null)) ? (
    <div className="PageEvent__row__aside col-12 col-md-4">
      <h3>Dónde</h3>
      <Map location={page.site} zoom="15" />
    </div>
  ) : (
    <div className="col-12 col-md-4"></div>
  )
  return (
    <Layout>
      {SEO}
      <div className="headline headline--event">{headerPage}</div>
      <div className="container PageEvent">
        <div className="row PageEvent__row">
          {content}
          {aside}
        </div>

      </div>
      <CustomSection sections={page.sections} formFields={formFields} />
    </Layout>
  )
}

export default PageEvent

export const query = graphql`
  query PageEvent($slug: String!) {
    strapiEvents(slug: { eq: $slug }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      site{
        id
        name
        longitude
        latitude
        observation
      }
      id
      title
      date
      slug
      formatDateMonth: date(formatString: "MMMM", locale: "ES-AR")
      formatDateDay: date(formatString: "DD", locale: "ES-AR")
      time
      eventImage {
        nameImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      video {
        id
        nameVideo
        type
        urlVideo
      }
      slider{
        id
        type
        imageSlider{
          id
          alt
          nameImage {
            id
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      event_type {
        name
      }
      event_category {
        name
        slug
      }
      sections {
        type
        fields {
          id
          title
          summary
          theme
          enable
          anchor
        }
        latest
        paragraph
        icon {
          name
          icon {
            type
            code
          }
        }
        image {
          type
          alt
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        events {
          date
          event_category
          id
          summary
          time
          title
        }
        banner_item {
          id
        }
        news_contents {
          id
        }
        blog_articles {
          id
        }
        testimonies {
          id
        }
        videos {
          id
        }
        careers {
          id
        }
        form {
          id
          Name
          PortalID
          formId
          formType
        }
        video {
          id
          urlVideo
          nameVideo
          type
        }
        externalSrc {
          url
          name
          type
          id
        }
        internalSrc {
          singlePage
          name
          career {
            slug
            id
            name
          }
          page {
            id
            slug
            title
          }
        }
      }
    }
  }
`
