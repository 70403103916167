import React from "react"
import {
  useAttendanceModes,
  useCareerStudyClass,
  useProgramTypes,
  useAcademicOfferMain,
} from "../../hooks"
import { LinkStudyClasses, AcademicOfferCard, Seo } from "../index"
import { getImage } from "gatsby-plugin-image"
import "./_academicOffer.scss"
import { HeaderPage } from "../../components"
import CustomSection from "../CustomSection/customSection.component"
import FloatingWhatsapp from "../FloatingWhatsapp/FloatingWhatsapp.component"

const AcademicOffer = () => {
  const studyClasses = useCareerStudyClass()
  const programsTypes = useProgramTypes()
  const attendanceModes = useAttendanceModes()
  const academicOfferMain = useAcademicOfferMain()

  const pageInfo = academicOfferMain?.allStrapiAcademicOfferMain?.nodes[0]

  const headerPage = (
    <HeaderPage
      title={pageInfo.title}
      summary={pageInfo.summary}
      pageUrl="/oferta-academica"
      paginaName={["Oferta Académica"]}
    />
  )

  const imgStudyClass =
    pageInfo.imageStudyClasses !== null
      ? getImage(pageInfo.imageStudyClasses)
      : ""
  const imageAttendanceModes =
    pageInfo.imageAttendanceModes !== null
      ? getImage(pageInfo.imageAttendanceModes)
      : ""

  const studyClassesList = studyClasses?.allStrapiCareerStudyClasses?.nodes.map(
    studyclass => (
      <LinkStudyClasses studyclass={studyclass} key={studyclass.id} />
    )
  )

  let SEO = ""

  if (
    pageInfo.academicOfferMainSeo !== null &&
    pageInfo.academicOfferMainSeo !== undefined &&
    pageInfo.academicOfferMainSeo.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = pageInfo.academicOfferMainSeo

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = <Seo title={"Oferta Académica"} />
  }

  return (
    <div>
      {SEO}
      <div className="headline headline-center">{headerPage}</div>
      <div className="container containerFilter">
        <div className="row">
          <div className="col-12">
            <h1 className="h2">Áreas de Estudio</h1>
          </div>
        </div>
        <div className="row containerFilter__row">{studyClassesList}</div>
        <div className="row">
          <div className="col-12 col-md-6 containerFilter__col">
            <AcademicOfferCard
              card={programsTypes?.allStrapiStudyClasses?.nodes}
              type={pageInfo?.titleStudyClasses || "Tipos de Programa"}
              id={1}
              image={imgStudyClass}
            />
          </div>
          <div className="col-12 col-md-6">
            <AcademicOfferCard
              card={attendanceModes?.allStrapiAttendanceModes?.nodes}
              type={pageInfo?.titleAttendanceModes || "Modalidades"}
              id={2}
              image={imageAttendanceModes}
            />
          </div>
        </div>
      </div>
      <CustomSection sections={pageInfo.section} />
      <FloatingWhatsapp />
    </div>
  )
}

export default AcademicOffer
