import React from "react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { Button } from "../index"
import "./_career.scss"

const Career = ({ career }) => {
  const {
    name,
    duration,
    state,
    careerImage,
    attendance_modes,
    study_class,
    slug,
    site_types,
    idCareer,
  } = career

  const buttonEcommerce = site_types.map(type => {
    if (type.name === "Ecommerce") {
      return (
        <Button
          content={"Inscribirse"}
          variant={"button-green w-100 mt-2"}
          extPath={`https://inscripciones.21.edu.ar/api/programs/career/${idCareer}`}
        />
      )
    }
  })
  const modalidad = attendance_modes.map((modalidad, index) =>
    index > 0 ? (
      <span key={modalidad}>, {modalidad} </span>
    ) : (
      <span key={modalidad}>{modalidad}</span>
    )
  )
  const tipoPrograma = study_class.name
  const image = getImage(careerImage)

  return (
    <>
      {state === "Active" || state === "Unknown" ? (
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div className="Career ">
            <div className="Career__box">
              <BgImage image={image} className="Career__box__bgImage">
                {state === "Unknown" ? <></> : <span>Inscripción Abierta</span>}
              </BgImage>
            </div>
            <div className="Career__text">
              <div className="Career__text__certificacion">{tipoPrograma}</div>
              <h6 className="Career__text__carrera">{name}</h6>
              <div className="Career__text__duration">
                <p>
                  <strong>Duración: </strong>
                  <span>{duration}</span>
                </p>
              </div>
              <div className="Career__text__modalidad">
                <p>
                  <strong>Modalidad: </strong>
                  {modalidad}
                </p>
              </div>
            </div>
            <div className="Career__button">
              <Button
                content={"Más Información"}
                variant={"button-yellow w-100"}
                path={`/oferta-academica/${slug}`}
              />
              {buttonEcommerce}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
export default Career
