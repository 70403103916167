import React from "react"
import { useBlogArticles } from "../../hooks"
import { StaticImage } from "gatsby-plugin-image"
import { Article, Button } from "../index"
import "./_blogSection.scss"

const BlogSection = ({ blog, latest, theme, title, anchor }) => {
  const data = useBlogArticles()
  const blogArticle = data?.allStrapiBlogArticles?.nodes

  const customArticle = blog.map(article =>
    blogArticle?.find(art => art.strapiId === article.id)
  )

  const destacado = latest ? (
    <Article
      key={blogArticle[0]?.id}
      article={blogArticle[0]}
      variant={"destacado"}
    />
  ) : (
    <Article
      key={customArticle[0].id}
      article={customArticle[0]}
      variant={"destacado"}
    />
  )

  const latestArticles =
    blogArticle.length > 1 ? (
      <div className={`col-12 col-md-6 blogSection__articles `}>
        {blogArticle
          .map(article => (
            <Article key={article.id} article={article} variant={"info"} />
          ))
          .slice(1, 4)}
      </div>
    ) : null

  const customArticles =
    blog.length > 1 ? (
      <div className={`col-12 col-md-6 blogSection__articles `}>
        {customArticle
          .map(article => (
            <Article key={article.id} article={article} variant={"info"} />
          ))
          .slice(1, 4)}
      </div>
    ) : null

  const articles = latest ? latestArticles : customArticles

  const image = latest ? blogArticle[0].blogImage : customArticle[0].blogImage

  const colVariant =
    (!latest && blog.length === 1) || (latest && blogArticle.length === 1)
      ? "col-md-9"
      : "col-md-6"

  const buttonVariant = theme === "dark" ? "button-light" : "button-green"

  return (
    <div id={`${anchor}`} className={`${theme}-theme`}>
      <div className={`container blogSection `}>
        <div className="row">
          <h2 className="col-12 text-center">{title}</h2>
        </div>
        <div className={`row justify-content-center `}>
          <div className={`col-12 ${colVariant}  `} id={`blog-img-container`}>
            <div className={`blogSection__bgImage`}>
              {image.nameImage?.url ? (
                <img src={image.nameImage.url} alt={image.alt} />
              ) : (
                <StaticImage
                  src="../../images/logo-siglo.png"
                  quality={100}
                  layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="no-image"
                  className={`card-bgImage-blog default`}
                />
              )}

              {destacado}
            </div>
          </div>
          {articles}
        </div>
        <div className="row justify-content-center py-5">
          <Button
            content={"Ver más"}
            path={`/identidad21`}
            variant={buttonVariant}
          />
        </div>
      </div>
    </div>
  )
}

export default BlogSection
