import React, { useState } from "react"
import { Link } from "gatsby"
import { usePages } from "../../hooks"
import { Accordion } from "../index"
import "./_childrenMenu.scss"

const ChildrenMenu = ({ id, name }) => {
  const pages = usePages()
  const [activeChild, setActiveChild] = useState(true)
  const handleChildList = () => {
    !activeChild ? setActiveChild(true) : setActiveChild(false)
  }
  const links = pages?.allStrapiPages?.nodes.map(item => {
    if (item.strapiParent?.id === id) {
      return (
        <li key={item?.id}>
          <Link
            to={`/${item.menuSection?.slug}/${item.strapiParent?.slug}/${item.slug}`}
          >
            {item?.name}
          </Link>
        </li>
      )
    } else {
      return null
    }
  })

  return (
    <div className="row childrenMenu">
      <Accordion onActive={handleChildList} title={name} state={activeChild} />
      {activeChild && (
        <nav>
          <ul className="childrenMenu__links">{links}</ul>
        </nav>
      )}
    </div>
  )
}

export default ChildrenMenu
