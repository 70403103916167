import React from "react"
import { useTestimonies } from "../../hooks"
import { ProfileCard } from "../index"
import "./_testimony.scss"

const Testimony = ({ testimony, latest, theme, title }) => {
  const data = useTestimonies()
  const testimonies = data?.allStrapiTestimonies?.nodes
  const customTestimony = testimony.map(tmony =>
    testimonies.find(tmy => tmy.strapiId === tmony.id)
  )

  const latestTestimonies = testimonies
    .map(testimony => (
      <ProfileCard
        key={testimony?.id}
        alt={`Profile picture of ${testimony?.name}`}
        variant="testimony"
        profilePicture={testimony?.profilePicture}
        testimony={testimony?.testimony}
        name={testimony?.name}
        role={testimony?.role}
        cols={columns(testimonies.slice(0, 3))}
      />
    ))
    .slice(0, 3)

  const customTestimonies = customTestimony
    .map(testimony => (
      <ProfileCard
        key={testimony?.id}
        alt={`Profile picture of ${testimony?.name}`}
        variant="testimony"
        profilePicture={testimony?.profilePicture}
        testimony={testimony?.testimony}
        name={testimony?.name}
        role={testimony?.role}
        cols={columns(customTestimony.slice(0, 3))}
      />
    ))
    .slice(0, 3)

  const testimoniesList = latest ? latestTestimonies : customTestimonies

  function columns(arr) {
    let length = arr.length
    let cols = 12 / length
    return cols
  }

  return (
    <div className={`testimony-block container-fluid ${theme}-theme`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="row justify-content-center mt-4">{testimoniesList}</div>
      </div>
    </div>
  )
}

export default Testimony
