function makeQuery(searchField) {
    let querySearch = ""

    if (searchField.length > 0) {
        let eventTypeSearch = ""
        let eventCategorySearch = ""
        let eventSiteSearch = ""
        let nameSearch = ""
        let calendarSearch = ""
        querySearch = "$..nodes[?("

        searchField.forEach(element => {
            switch (element.type) {
                case 1:
                    eventTypeSearch =
                        eventTypeSearch === ""
                            ? eventTypeSearch + "@.event_type.name=='" + element.value + "'"
                            : eventTypeSearch +
                            " || @.event_type.name=='" +
                            element.value +
                            "'"
                    break
                case 2:
                    eventCategorySearch =
                        eventCategorySearch === ""
                            ? eventCategorySearch +
                            "@.event_category.name=='" +
                            element.value +
                            "'"
                            : eventCategorySearch +
                            " || @.event_category.name=='" +
                            element.value +
                            "'"
                    break
                case 3:
                    nameSearch =
                        "@.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes('" +
                        element.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() +
                        "')"
                    break
                case 4:
                    calendarSearch = "@.date==='" + element.value + "'"
                    break
                case 5:
                    eventSiteSearch =
                        eventSiteSearch === ""
                            ? eventSiteSearch + "@.site.name=='" + element.value + "'"
                            : eventSiteSearch + " || @.site.name=='" + element.value + "'"
                    break
                default:
                    break
            }
        })

        querySearch =
            querySearch +
            (eventTypeSearch === "" ? "" : "(" + eventTypeSearch + ")")
        querySearch =
            querySearch +
            (eventCategorySearch === ""
                ? ""
                : (eventTypeSearch === "" ? "" : "&&") +
                "(" +
                eventCategorySearch +
                ")")
        querySearch =
            querySearch +
            (eventSiteSearch === ""
                ? ""
                : (eventTypeSearch === "" ? "" : "&&") || (eventCategorySearch === "" ? "" : "&&") +
                "(" +
                eventSiteSearch +
                ")")

        querySearch =
            querySearch +
            (nameSearch === ""
                ? ""
                : (eventTypeSearch !== "" || eventCategorySearch !== "" || eventSiteSearch !== ""
                    ? " && "
                    : "") +
                "(" +
                nameSearch +
                ")")

        querySearch =
            querySearch +
            (calendarSearch === ""
                ? ""
                : (eventTypeSearch !== "" ||
                    eventCategorySearch !== "" ||
                    eventSiteSearch !== "" ||
                    nameSearch !== ""
                    ? " && "
                    : "") +
                "(" +
                calendarSearch +
                ")")

        querySearch = querySearch + ")]"
    } else {
        querySearch = "$..nodes[*]"
    }

    return querySearch;
}

module.exports = {
    makeQuery
}