import { useStaticQuery, graphql } from "gatsby"

const useProgramTypes = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiStudyClasses(sort: {fields: order}) {
        nodes {
          id
          code
          name
          state
        }
      }
    }
  `)
  return query
}

export default useProgramTypes
