import React from 'react'
import "./_pdfCard.scss"
import { Button } from "../index"
import { FaFilePdf } from "@react-icons/all-files/fa/FaFilePdf"

const PdfCard = ({link, description, title}) => {

    return (
        <div className="container-fluid">
        <div className="pdf"> 
                <div className="pdf__icon"> <FaFilePdf size={50} color="red" /> </div>
                <div className="pdf__text">
                    <div className="pdf__text_title">{title}</div>
                    {description && (
                        <div className="pdf__text_description">{description}</div>                        
                    )}
                </div>
                <div className="pdf__button">
                    <Button content={link?.name} variant={"button-yellow"} extPath={link?.url} />
                </div>            
        </div>            
        </div>

    )
}

export default PdfCard 