import React from "react"
import "./_video.scss"

const VideoYoutube = ({ data, variant, cols }) => {
  const url = data?.urlVideo.replace("watch?v=", "embed/")
  let code = url?.substring(url.lastIndexOf("/") + 1, url.length)
  const codeIndex = code?.indexOf("?")

  if (codeIndex !== -1) {
    code = code?.substring(0, code.indexOf("?"))
  }

  return (
    <>
      <div
        className={`${variant}__video mb-3 col-${cols}`}
        aria-label="Reproductor de video de youtube"
      >
        <div className="embed-responsive embed-responsive-1by1">
          <iframe
            loading="lazy"
            className="embed-responsive-item"
            type="text/html"
            src={url}
            frameBorder="0"
            allowFullScreen
            title="embebed-video"
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{aspect-ratio:4.5/2.5;
            object-fit:cover;position:absolute; width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
              <a href=${url}>
              <img src=https://img.youtube.com/vi/${code}/hqdefault.jpg alt='Video'>
              <span>▶</span></a>`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </>
  )
}
export default VideoYoutube
