import React from "react"
import { HomePage } from "../components/"
import Layout from "../templates/layout.template"
import "bootstrap/dist/css/bootstrap.min.css"


const IndexPage = () => (
  <Layout>
    <HomePage />
  </Layout>
)

export default IndexPage
