import React from 'react'
import "./_sites.scss"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { Button } from "../index"
import { useFilteredSites } from "../../hooks"
import { Link } from 'gatsby'


const SitesBlock = ({theme, title, anchor}) => {

    const sites = useFilteredSites()

    const sitesList = sites?.map(site => {
        const image = getImage(site?.image?.nameImage)
        return (
            <div className="col-12 col-lg-6" key={site.id}>
                <Link to={`/sedes-y-centros/${site?.slug}`} className="sitesBlockCard">
                    <div className="sitesBlockCard__description">
                        <p className='sitesBlockCard__description_title'>{site.sede ? ('Sede') : ('Centro de Experiencia')}</p>
                        <p><FaMapMarkerAlt size={14} color="#00806e"/> <b className="sitesBlockCard__description_title">{site?.image?.title}</b></p>
                        {site.street && <p>{`${site?.street} ${site?.streetNumber}`}</p>}
                        {site.city && <p>{site?.city}</p>}
                        {site.phone1 && <p>{`tel.: ${site?.phone1}`}</p>}
                    </div>
                    <GatsbyImage image={image} alt={site?.image?.alt} className="sitesBlockCard__img"/>

                </Link>
            </div>
        )
    })

    const btnTheme = theme === "dark" ? "light" : "green"

    return (
        <div id={anchor} className={`container-fluid sitesBlock theme-${theme}`}>
            <div className="container">
                <div className="row mt-2 mb-3">
                    <h2 className="col-12 text-center">{title}</h2>
                </div>
                <div className="row justify-content-center">
                   {sitesList}
                </div>   
                <div className="row justify-content-center py-5">
                    <Button
                        content={" Todos sedes y centros "}
                        path={`/sedes-y-centros`}
                        variant={`button-${btnTheme}`}
                    />
                </div>
            </div>
        </div>
    )
}

export default SitesBlock
