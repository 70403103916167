import { useStaticQuery, graphql } from "gatsby"

const useTestimonies = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiTestimonies(sort: { fields: published_at, order: DESC }) {
        nodes {
          strapiId
          id
          name
          testimony
          role
          profilePicture {
            childImageSharp {
              gatsbyImageData(width: 84, height: 84)
            }
          }
          published_at(formatString: "LL", locale: "es-ar")
        }
      }
    }
  `)
  return query
}

export default useTestimonies
