import { useStaticQuery, graphql } from "gatsby"

const useContact = () => {
  const query = useStaticQuery(graphql`
    query {
        allStrapiContacPage {
            nodes {
              id
              title
              subTitle
              contactSeo {
                description: pageDescription
                keywords: pageKeywords
                pageTitle
              }
              navegation {
                id
                type
                externalLinks {
                  id
                  name
                  type
                  url
                }
                internalLinks {
                  id
                  name
                  type
                  page {
                    id
                    slug
                    title
                  }
                  singlePage
                  career {
                    id
                    slug
                    name
                  }
                }
              }
              sections {
                banner_item {
                  id
                }
                blog_articles {
                  id
                }
                careers {
                  id
                }
                events {
                  id
                }
                fields {
                  enable
                  title
                  summary
                  anchor
                  theme
                }
                form {
                  id
                  formId
                  PortalID
                  formType
                }
                news_contents {
                  id
                }
                social_medias {
                  id
                }
                testimonies {
                  id
                }
                image {
                  alt
                  id
                  title
                  type
                  nameImage {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                videos {
                  id
                }
                type
                latest
                paragraph
                icon {
                  name
                  icon {
                    type
                    code
                  }
                }
              }
            }
          }
    }
  `)
  return query
}

export default useContact