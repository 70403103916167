import React from "react"
import { useEventsLatest } from "../../hooks"
import { Button, EventsCard } from "../index"
import "./_eventSection.scss"

const EventSection = ({ events, title, theme, anchor, latest }) => {
  const data = useEventsLatest()

  const allEvents = data?.allStrapiEvents?.nodes

  const customEventsList = events.map(event =>
    allEvents.find(ev => ev.strapiId === event.id)
  )

  const latestEvents = allEvents
    .map(e => e !== undefined && (
      <EventsCard
        date={e.formatDateMonth}
        dateDay={e.formatDateDay}
        title={e.title}
        time={e.time}
        summary={e.summary}
        slug={e.slug}
        img={e.eventImage?.nameImage}
      />
    ))
    .slice(0, 3)

  const eventsList = customEventsList
    .map(e => e !== undefined && (
      <EventsCard
        date={e.formatDateMonth}
        dateDay={e.formatDateDay}
        title={e.title}
        time={e.time}
        summary={e.summary}
        slug={e.slug}
        img={e.eventImage?.nameImage}
      />
    ))
    .slice(0, 3)

  const eventsToDisplay = latest ? latestEvents : eventsList
  const btnTheme = theme === "dark" ? "light" : "green"

  return (
    <>
      <div id={`${anchor}`} className={`container-fluid eventSection ${theme}-theme`}>
        <div className="container">
          <div className="row mt-2 mb-3">
            <h2 className="eventSection__section-title col-12">{title}</h2>
          </div>
          <div className="card-deck">{eventsToDisplay}</div>
          <div className="row justify-content-center py-5">
            <Button
              content={" Ver más "}
              path={`/eventos`}
              variant={`button-${btnTheme}`}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EventSection
