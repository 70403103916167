import React, { useState, useEffect } from "react"
import { useBlogArticles, useBlogCategories, useBlogPage } from "../../hooks"
import * as jp from "jsonpath"
import {
  Seo,
  Breadcrumb,
  NewsCard,
  CustomSection,
  ArticleSearchInput,
  ControlledCarousel,
  Pagination,
} from "../index"

import "./_articlePage.scss"

const ArticlePage = ({ filter }) => {
  const data = useBlogArticles()
  const dataCategories = useBlogCategories()
  const dataBlogPage = useBlogPage()
  const blogs = data?.allStrapiBlogArticles
  const page = dataBlogPage?.allStrapiBlogPage?.nodes[0]
  const blogsDestacados = page?.blogsDestacados?.blog_articles
  const categories = dataCategories?.allStrapiBlogCategories?.nodes
  const sections = page?.sections

  const [query, setQuery] = useState("$..nodes[*]")
  const [results, setResults] = useState([])
  const [title, setTitle] = useState(`${page.title}`)
  const [inicialPage, setInicialPage] = useState(false)

  useEffect(() => {
    if (filter?.state?.value !== null && filter?.state?.value !== undefined) {
      setQuery(`$..nodes[?(@.blog_categories.indexOf("${filter.state.value}") !== -1)]`)
      setInicialPage(true)
      setTitle("Resultados de la Búsqueda")
      filter.state.value = null
    }
    setResults(jp.query(blogs, query))
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  for (let i = 0; i < results.length; i++) {
    if (blogsDestacados?.find(element => element.id === results[i].strapiId) !== undefined) {
      results.splice(i, 1)
      i--
    }
  }

  const blogArticle = results.map(article =>
    <NewsCard
      key={article?.id}
      newsImage={article?.blogImage?.nameImage}
      title={article?.title}
      content={article?.description}
      published_at={article?.publishedAt}
      newsTag={article?.blog_categories}
      slug={article?.slug}
      destacado={article?.destacado}
      cols={3}
      variant={"blogArticle"}
    />
  )

  const handleSearch = textInput => {
    filter.state = null
    setQuery(`$..nodes[?(@.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes("${textInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}"))]`)
    textInput.length === 0
      ? setTitle(`${page.title}`)
      : setTitle("Resultados de la Búsqueda")
  }

  const blogCategories = categories.map(category => (
    <option key={category.id} value={`${category.name}`}>
      {category.name}
    </option>
  ))

  const handleCategory = e => {
    const category = e.target.value
    filter.state = null
    if (category === "Categorías") {
      setTitle(`${page.title}`)
      setQuery("$..nodes[*]")
      setInicialPage(false)
    } else {
      setTitle("Resultados de la Búsqueda")
      setQuery(`$..nodes[?(@.blog_categories.indexOf("${category}") !== -1)]`)
      setInicialPage(true)
    }
  }

  let SEO = ""

  if (page.blogMetadata !== null && page.blogMetadata !== undefined && page.blogMetadata.pageTitle !== null) {
    const { description, keywords, pageTitle } = page.blogMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = (
      <Seo
        title={page.title}
      />
    )
  }

  return (
    <>
      {SEO}
      <div className="headline">
        <div className="container px-0">
          <div className="headline__breadcrumb col-12 pr-0">
            <Breadcrumb title={page.blogMetadata.pageTitle} pageUrl="/identidad21" paginaName={["Identidad 21"]} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between ArticlePage__head">
          <div className="col-12 col-md-6">
            <h1 className="ArticlePage__title">{title}</h1>
            <p className="ArticlePage__subtitle">{page.subTitle}</p>
          </div>
          <div className=" col-12 col-md-5  ">
            <ArticleSearchInput onQuery={handleSearch} placeholder={'Buscar un artículo...'} />
            <div className="ArticlePage__select">
              <select
                name="category"
                id="Select"
                className="col-12 col-lg-6 ArticlePage__categories "
                onChange={handleCategory}
                onBlur={handleCategory}
              >
                <option value="Categorías">Todas las Categorías</option>
                {blogCategories}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div id="Posts" className="container mb-5 px-4">
        {blogsDestacados !== undefined && blogsDestacados !== null ?
          <ControlledCarousel
            className="row justify-content-center"
            articles={blogsDestacados}
            variant={'event'}
          />
          : null}
      </div>
      <div className="container ">
        <Pagination
          posts={blogArticle}
          postPerPage={8}
          inicialState={inicialPage}
        />
      </div>
      <CustomSection sections={sections} />
    </>
  )
}

export default ArticlePage
