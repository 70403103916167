import React from "react"
import { CustomMenu, ChildrenMenu } from ".."

const NavegationContent = ({ content, id, name, hiddecontact }) => {
  const navegationContent = content.map(item => {
    return (
      <div key={item.id}>
        {item.type === "childrenMenu" && <ChildrenMenu id={id} name={name}/>}
        {item.type === "customMenu" && <CustomMenu data={item} hiddecontact={hiddecontact}/>}
      </div>
    )
  })
  return <div className="col-12 col-md-3 col-lg-2 px-5 px-md-3 ">{navegationContent}</div>
}

export default NavegationContent
