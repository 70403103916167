function makeQuery(searchField) {
    let querySearch = ""

    if (searchField.length > 0) {
      let programsTypeSearch = ""
      let modalitySearch = ""
      let studyClassSearch = ""
      let nameSearch = ""
      querySearch = "$..nodes[?("

      searchField.forEach(element => {
        switch (element.type) {
          case 1:
            programsTypeSearch =
              programsTypeSearch === ""
                ? "@.study_class.name=='" +
                element.value +
                "'"
                : programsTypeSearch +
                " || @.study_class.name=='" +
                element.value +
                "'"
            break
          case 2:
            modalitySearch =
              modalitySearch === ""
                ? "@.attendance_modes.indexOf('" +
                element.value +
                "') !== -1"
                : modalitySearch +
                " || @.attendance_modes.indexOf('" +
                element.value +
                "') !== -1"
            break
          case 3:
            nameSearch =
              "@.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes('" +
              element.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() +
              "')"
            break
          case 4:
            studyClassSearch =
              studyClassSearch === ""
                ? "@.career_study_class.name=='" +
                element.value +
                "'"
                : studyClassSearch +
                " || @.career_study_class.name=='" +
                element.value +
                "'"
            break
          default:
            break
        }
      })

      querySearch =
        querySearch +
        (programsTypeSearch === "" ? "" : "(" + programsTypeSearch + ")")
      querySearch =
        querySearch +
        (modalitySearch === ""
          ? ""
          : (programsTypeSearch === "" ? "" : "&&") +
          "(" +
          modalitySearch +
          ")")

      querySearch =
        querySearch +
        (studyClassSearch === ""
          ? ""
          : (programsTypeSearch !== "" || modalitySearch !== "" ? " && " : "") +
          "(" +
          studyClassSearch +
          ")")

      querySearch =
        querySearch +
        (nameSearch === ""
          ? ""
          : (programsTypeSearch !== "" ||
            modalitySearch !== "" ||
            studyClassSearch !== ""
            ? " && "
            : "") +
          "(" +
          nameSearch +
          ")")
      querySearch = querySearch + ")]"
    } else {
      querySearch = "$..nodes[*]"
    }
    
    return querySearch;
  }

module.exports = {
    makeQuery
}