import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import "./_banner.scss"
import BannerLink from "./bannerLink.component"

const BannerItem = ({ banner, type }) => {
 const altText = `Banner-${banner.strapiId}`

  const image =
    type === "mobile"
      ? getImage(banner?.imageMobile)
      : getImage(banner?.imageDesktop)

  return (
    <div className="bannerItem">
      <div className="bannerItem__back">
        <GatsbyImage image={image} className="bannerItem__back__img" alt={altText} />
        <div className="bannerItem__darker">
          <div className="bannerItem__info">
            <div className="bannerItem__category">{banner?.category}</div>
            <div className="bannerItem__title">
              <span>{banner?.title}</span>
            </div>
            {banner?.link && <BannerLink link={banner?.link} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerItem
