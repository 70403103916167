import { useStaticQuery, graphql } from "gatsby"

const useGallery = () => {
  return useStaticQuery(graphql`
    query {
      strapiGalleryPage {
        galleryMetadata {
          description: pageDescription
          keywords: pageKeywords
          pageTitle
        }
        cardsSectionTitle
        content {
          type
          imageSlider {
            id
            alt
            nameImage {
              id
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          paragraph
          banner_item {
            id
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          id
          video {
            nameVideo
            type
            urlVideo
            id
          }
          image {
            type
            title
            id
            alt
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allStrapiGalleryItems(sort: {fields: strapiId, order: DESC}) {
        nodes {
          title
          slug
          description
          extraInfo
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          firstPageOrder
        }
      }
    }
  `)
}

export default useGallery
