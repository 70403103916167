import React from "react"
import { Link } from "gatsby"
// import { getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import "./_card.scss"

const Card = ({ article, variant }) => {
  const link = variant === "event" ? "artículo" : "noticia"
  const image = variant === "event" ? article?.blogImage : article?.newsImage
  return (
    <div className="BgImage__Card">
      {image?.nameImage?.url ? (
        <img
          src={image.nameImage.url}
          alt={image.alt}
          className="BgImage__Card--img"
        />
      ) : (
        <StaticImage
          src="../../images/logo-siglo.png"
          quality={100}
          layout="fixed"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="no-image"
          className="BgImage__Card--img"
        />
      )}
      <div className="Card">
        <div className="Card__item">
          <div className="Card__body">
            {variant === "event" ? (
              <p className="Card__date">{article.publishedAt}</p>
            ) : (
              <p className="Card__date">{article.published_at}</p>
            )}
            <p className="Card__title">{article.title}</p>
            <p className="Card__description">{article.description}</p>
          </div>

          <Link to={article.slug} className="Card__link">
            Leer {link}
            <FaChevronRight color="#00806e" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card
