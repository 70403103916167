import React from "react"
import { Contact } from "../components"
import Layout from "../templates/layout.template"

const ContactPage = () => (
  <Layout>
    <Contact />
  </Layout>
)

export default ContactPage
