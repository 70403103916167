import React from "react"
import { Link } from "gatsby"
import { useCareers, usePages, useNews } from "../../hooks"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import "./_internalLink.scss"

function slug(page, pages) {
  let pagina = ""

  if (page.strapiParent === null || page.strapiParent === undefined) {
    return page.slug
  } else {
    pages.forEach(parent => {
      if (parent.slug === page.strapiParent.slug) {
        pagina = parent
      }
    })
    return slug(pagina, pages) + "/" + page.slug
  }
}

const OneInternalLink = ({ internalLink, variant }) => {
  const careers = useCareers()
  const pages = usePages()
  const news = useNews()

  let link = null

  if (internalLink !== null && internalLink !== undefined) {
    if (internalLink.page !== null && internalLink.page !== undefined) {
      link = pages?.allStrapiPages?.nodes.map((page, idx) => {
        if (page.strapiId === internalLink.page.id) {
          const slugSection =
            page.menuSection !== null ? `/${page.menuSection.slug}/` : "/"
          // const slugParent =
          //   page.strapiParent !== null ? `/${page.strapiParent.slug}` : ""
          const slugParent = slug(page, pages?.allStrapiPages?.nodes)

          return (
            <Link key={`inLink-${idx}`} to={`${slugSection}${slugParent}`}>
              {variant === "menu" ? (
                <p>{internalLink.name}</p>
              ) : (
                <>
                  {internalLink.name} <FaChevronRight size={12} />
                </>
              )}
            </Link>
          )
        } else {
          return null
        }
      })
    }

    if (
      internalLink.singlePage !== null &&
      internalLink.singlePage !== undefined
    ) {
      return (
        <Link to={`/${internalLink.singlePage.replace(/_/g, "-")}`}>
          {variant === "menu" ? (
            <p>{internalLink.name}</p>
          ) : (
            <>
              {internalLink.name} <FaChevronRight size={12} />
            </>
          )}
        </Link>
      )
    }
  } else {
    return null
  }

  if (internalLink.career !== null && internalLink.career !== undefined) {
    link = careers.allStrapiCareers.nodes.map((career, idx) => {
      if (internalLink.career.id === career.strapiId) {
        return (
          <Link key={`inLink-${idx}`} to={`/oferta-academica/${career.slug}`}>
            {variant === "menu" ? (
              <p>{internalLink.name}</p>
            ) : (
              <>
                {internalLink.name} <FaChevronRight size={12} />
              </>
            )}
          </Link>
        )
      } else {
        return null
      }
    })
  }

  if (internalLink.news_content !== null && internalLink.news_content !== undefined) {
    link = news.allStrapiNewsContents.nodes.map((news_content, idx) => {
      if (internalLink.news_content.id === news_content.strapiId) {
        return (
          <Link key={`inLink-${idx}`} to={`/noticias/${news_content.slug}`}>
            {variant === "menu" ? (
              <p>{internalLink.name}</p>
            ) : (
              <>
                {internalLink.name} <FaChevronRight size={12} />
              </>
            )}
          </Link>
        )
      } else {
        return null
      }
    })
  }


  return link
}

export default OneInternalLink
