import React from "react"
import Layout from "../templates/layout.template"
import { Gallery } from "../components"

const ArtCatalogue = () => (
  <Layout>
    <Gallery />
  </Layout>
)

export default ArtCatalogue