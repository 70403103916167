import { useStaticQuery, graphql } from "gatsby"

const useBanner = () => {
  const query = useStaticQuery(graphql`
    {
        allStrapiBannerItems {
            nodes {
              category
              description
              destacado
              id
              strapiId
              title
              order
              link {
                internalLink {
                  id
                  name
                  page {
                    id
                    name
                    menuSection
                    slug
                    title
                  }
                  news_content {
                    title
                    id
                    slug
                  }
                  type
                  singlePage
                  career {
                    id
                    slug
                    name
                  }
                }
                externalLink{
                  id
                  name
                  url
                  type
                }
              }
              imageMobile: image {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, webpOptions: { quality: 50}, layout: CONSTRAINED) 
                }
              }
              imageDesktop: image {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, webpOptions: { quality: 50 }, layout: CONSTRAINED)  
                }
              }
            }
        }
    }
  `)
  return query
}
export default useBanner