import { Link } from "gatsby"
import React from "react"
import { Icon } from ".."
import "./_subMenu.scss"

const SubMenu = ({ sections, titleShort, titleLong }) => {
  const options = sections.map(section => {
    return (
      <>
        {section.type === "form" ? (
          <Link
            to={`/#${section?.fields?.anchor}`}
            className="SubMenu__block__item"
          >
            <Icon type={"fas"} code={"headphones-alt"} />
            <p>Contactanos</p>
          </Link>
        ) : null}
        {(section.events?.length > 0 || (section.latest === true && section.events !== null))  ? (
          <Link
            to={`/#${section?.fields?.anchor}`}
            className="SubMenu__block__item"
          >
            <Icon type={"far"} code={"calendar-alt"} />
            <p>Eventos</p>
          </Link>
        ) : null}
        {(section.blog_articles?.length > 0  || (section.latest === true && section.blog_articles !== null) )? (
          <Link
            to={`/#${section?.fields?.anchor}`}
            className="SubMenu__block__item"
          >
            <Icon type={"fas"} code={"book"} />
            <p>Identidad 21</p>
          </Link>
        ) : null}
        {section.type === "sede" ? (
          <Link
            to={`/#${section?.fields?.anchor}`}
            className="SubMenu__block__item"
          >
            <Icon type={"fas"} code={"map-marker-alt"} />
            <p>Sedes</p>
          </Link>
        ) : null}
        {(section.news_contents?.length > 0 || (section.latest === true && section.news_contents !== null)) ? (
          <Link
            to={`/#${section?.fields?.anchor}`}
            className="SubMenu__block__item"
          >
            <Icon type={"far"} code={"newspaper"} />
            <p>Noticias</p>
          </Link>
        ) : null}
      </>
    )
  })
  return (
    <div>
      <div className="SubMenu">
        <div className="container SubMenu__block">{options}</div>
      </div>
      <div className="mainTitle">
        <div className="container">
          {titleShort &&  
            <h1 className="mainTitle__title">{titleShort}</h1>
          }
          {titleLong && 
            <h1 className="mainTitle__title">{titleLong}</h1>
          }
          
        </div>
      </div>
    </div>
  )
}

export default SubMenu
