import React from 'react'
import BannerItem from './bannerItem.component';
import BannerItemDestacado from './bannerItemDestacado.component';  
import "./_banner.scss"

const BannerDesktopLayout = ({ bannerDestacado, otherBanners, type}) => {

    let itemClassName = {
        1: "Case1",
        2: "Case2",
        3: "Case3"
    }

    const bannersDesktop = otherBanners.map(banner => (
        <div key={banner.id} className={`${bannerDestacado ?`bannerDesktopRight__item${itemClassName[otherBanners.length]}` : "bannerDesktopFull__item"}`}>
            <BannerItem banner={banner} type={type}/>
        </div>
        )
    )

    return (
        <div className="container-fluid banner-desktop">
            <div className="row banner">
                {bannerDestacado && (
                    <div className={`col-12 p-0 ${otherBanners.length && "col-lg-6"}`}>       
                        <BannerItemDestacado bannerDestacado={bannerDestacado} type={type}/>
                    </div>
                )}
                {otherBanners.length > 0 && (
                    <div className={`col-12 p-0 ${bannerDestacado && "col-lg-6"}`}> 
                        <div className={`${bannerDestacado ? "bannerDesktopRight" : "bannerDesktopFull"}`}>
                            {bannersDesktop}
                        </div>
                    </div>
                )} 
            </div>
        </div>
    )
}

export default BannerDesktopLayout
