import React from "react"
import OneInternalLink from "../InternalLink/oneInternalLink.component"

const BannerLink = ({ link }) => {
  if (link !== null && link !== undefined) {
    const linkType = link?.internalLink ? "internal" : "external"
    return (
      <div className="bannerItem__link">
        {linkType === "internal" ? (
          <OneInternalLink internalLink={link?.internalLink} />
        ) : (
          <a
            href={link?.externalLink.url}
            target="_blank"
            rel="external noreferrer"
          >
            {link?.externalLink.name}
          </a>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default BannerLink
