import React from "react"
import { Link } from "gatsby"
import { usePages } from "../../hooks"
import "./_buttonPage.scss"

const ButtonPage = ({ externalSrc, internalSrc }) => {

    let button;
    let slugSection
    let slugParent
    const pages = usePages();

    if (externalSrc !== undefined && externalSrc !== null) {
        button =
            <a href={externalSrc.url} className="button-page-anchor" target="_blank" rel="noreferrer" key={`link-${externalSrc.id}`}>
                <button className="button-page-anchor__button-page">
                    {externalSrc.name}
                </button>
            </a>
    } else { //internal links
        if (internalSrc.page !== null && internalSrc.page !== undefined) {
            pages?.allStrapiPages?.nodes.forEach(page => {
                if (page.strapiId === internalSrc.page.id) {
                    slugSection =
                        page.menuSection !== null ? `/${page.menuSection.slug}` : ""
                    slugParent =
                        page.strapiParent !== null ? `/${page.strapiParent.slug}` : ""

                }
            })

            button =
                <Link to={`${slugSection}${slugParent}/${internalSrc.page?.slug}`} className="button-page-anchor">
                    <button className="button-page-anchor__button-page">
                        {internalSrc.name}
                    </button>
                </Link>
        } else if (internalSrc.singlePage !== null && internalSrc.singlePage !== undefined) {
            button =
                <Link to={`/${internalSrc.singlePage.replace(/_/g, "-")}`} className="button-page-anchor">
                    <button className="button-page-anchor__button-page">
                        {internalSrc.name}
                    </button>
                </Link>

        } else if (internalSrc.career !== null && internalSrc.career !== undefined) {
            button = <Link to={`/oferta-academica/${internalSrc.career.slug}`} className="button-page-anchor">
                <button className="button-page-anchor__button-page">
                    {internalSrc.name}
                </button>
            </Link>
        }
    }


    return (
        <div>{button}</div>
    )
}

export default ButtonPage