import React, { useState, useEffect } from "react"
import { useNews, useNewsPage } from "../../hooks"
import * as jp from "jsonpath"
import {
  Seo,
  Breadcrumb,
  NewsCard,
  CustomSection,
  ArticleSearchInput,
  ControlledCarousel,
  Pagination,
} from "../index"

import "../ArticlePage/_articlePage.scss"

const ArticleNewsPage = ({ filter }) => {
  const data = useNews()
  const dataNewsPage = useNewsPage()

  const news = data?.allStrapiNewsContents
  const page = dataNewsPage?.allStrapiNewsPage?.nodes[0]
  const newsDestacados = page?.newsDestacadas?.news_contents

  const sections = page?.sections
  const [query, setQuery] = useState("$..nodes[*]")
  const [results, setResults] = useState([])
  const [title, setTitle] = useState(`${page.title}`)
  const [inicialPage, setInicialPage] = useState(false)

  useEffect(() => {
    if (filter?.state?.value !== null && filter?.state?.value !== undefined) {
      setQuery(`$..nodes[?(@.newsTag.indexOf("${filter.state.value}") !== -1)]`)
      setInicialPage(true)
      setTitle("Resultados de la Búsqueda")
      filter.state.value = null
    }

    setResults(jp.query(news, query))
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  for (let i = 0; i < results.length; i++) {
    if (newsDestacados?.find(element => element.id === results[i].strapiId) !== undefined) {
      results.splice(i, 1)
      i--
    }
  }

  const newsArticle = results.map(article =>
    <NewsCard
      key={article?.id}
      newsImage={article?.newsImage?.nameImage}
      title={article?.title}
      alt={article?.newsImage?.alt}
      published_at={article?.publishedAt}
      newsTag={article?.newsTag}
      slug={article?.slug}
      destacado={article?.destacado}
      cols={3}
    />
  )

  const handleSearch = textInput => {
    filter.state = null
    setQuery(`$..nodes[?(@.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes("${textInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}"))]`)
    textInput.length === 0
      ? setTitle(`${page.title}`)
      : setTitle("Resultados de la Búsqueda")
  }

  let SEO = ""
  if (page.newsMetadata !== null && page.newsMetadata !== undefined && page.newsMetadata.pageTitle !== null) {
    const { description, keywords, pageTitle } = page.newsMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = (
      <Seo
        title={page.title}
      />
    )
  }

  return (
    <>
      {SEO}
      <div className="headline">
        <div className="container px-0">
          <div className="headline__breadcrumb col-12 pr-0">
            <Breadcrumb title={page.newsMetadata.pageTitle} pageUrl="/noticias" paginaName={["Noticias"]} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between ArticlePage__head">
          <div className="col-12 col-md-6">
            <h1 className="ArticlePage__title">{title}</h1>
            <p className="ArticlePage__subtitle">{page.subTitle}</p>
          </div>
          <div className=" col-12 col-md-5  ">
            <ArticleSearchInput onQuery={handleSearch} placeholder={'Buscar una noticia...'} />
          </div>
        </div>
      </div>
      <div id="Posts" className="container mb-5 px-4">
        {newsDestacados !== undefined && newsDestacados !== null ?
          <ControlledCarousel
            className="row justify-content-center"
            articles={newsDestacados}
            variant={'news'}
          />
          : null}
      </div>
      <div className="container ">
        <Pagination
          posts={newsArticle}
          postPerPage={8}
          inicialState={inicialPage}
        />
      </div>
      <CustomSection sections={sections} />
    </>
  )
}

export default ArticleNewsPage
