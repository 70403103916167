import React from "react"
import { Link } from "gatsby"
import "./_breadcrumb.scss"

const Breadcrumb = ({ pageUrl, paginaName, pageSectionLink }) => {

  let urls = pageUrl.split("/").splice(1)
  let urlTemp = ""

  const urlsTemp = urls.map((url, idx) => {
    urlTemp += `${url}/`
    return (
      pageSectionLink === false && idx === 0 ? null :
        <li key={`url-${idx}`}>
          <Link to={`/${urlTemp}`}>{paginaName[idx]}</Link>
        </li>
    )
  })

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb px-0">
        <li>
          <Link to="/">Home</Link>
        </li>
        {urlsTemp}
      </ol>
    </nav>
  )
}

export default Breadcrumb
