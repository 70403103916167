import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import "./_banner.scss"
import BannerLink from "./bannerLink.component"

const BannerItemDestacado = ({ bannerDestacado, type }) => {
  const altText = `bannerItem-${bannerDestacado.title}`
  const image =
    type === "mobile"
      ? getImage(bannerDestacado?.imageMobile)
      : getImage(bannerDestacado?.imageDesktop)
  return (
    <div className="bannerItem">
      <div className="bannerItem__back">
        <GatsbyImage image={image} className="bannerItem__back__img" alt={altText} />
        <div className="bannerItem__darker destacado__darker">
          <div className="bannerItem__info">
            {bannerDestacado.title && (
              <div className="bannerItem__title__destacado">
                {bannerDestacado?.title}
              </div>
            )}
            {bannerDestacado.description && (
              <div className="bannerItem__description destacado__description">
                {bannerDestacado?.description}
              </div>
            )}
            {bannerDestacado?.link && (
              <BannerLink link={bannerDestacado?.link} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerItemDestacado
