import React from "react"
import { useCareers, usePages } from "../../hooks"
import { Link } from "gatsby"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight"
import "./_internalLink.scss"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"

function slug(page, pages) {
    let pagina = ""

    if (page.strapiParent === null || page.strapiParent === undefined) {
        return page.slug
    } else {
        pages.forEach(parent => {
            if (parent.slug === page.strapiParent.slug) {
                pagina = parent
            }
        })
        return slug(pagina, pages) + "/" + page.slug
    }
}

const InternalLink = ({ internalLinks, variant }) => {

    const careers = useCareers();
    const pages = usePages();

    const internalsLinks = internalLinks.map(link => {

        if (link.page !== null && link.page !== undefined) {
            const links = pages?.allStrapiPages?.nodes.map(page => {
                if (page.strapiId === link.page.id) {
                    const slugSection =
                        page.menuSection !== null ? `/${page.menuSection.slug}/` : "/"
                    // const slugParent =
                    //     page.strapiParent !== null ? `/${page.strapiParent.slug}` : ""
                    const slugParent = slug(page, pages?.allStrapiPages?.nodes)
                    

                    return (
                        <li key={page.id} className={`internalLink ${variant}`}>
                            <Link
                                to={`${slugSection}${slugParent}`}
                            >
                                {link.name}
                            </Link>
                            {variant && (variant === 'internalLink__button' ? <FaArrowRight /> : <FaChevronRight />)}
                        </li>
                    )
                } else {
                    return null
                }
            })
            return links
        } else if (link.singlePage !== null && link.singlePage !== undefined) {
            return <li key={link.id} className={`internalLink ${variant}`}>
                <Link
                    to={`/${link.singlePage.replace(/_/g, "-")}`}
                >
                    {link.name}
                </Link>
                {variant && (variant === 'internalLink__button' ? <FaArrowRight /> : <FaChevronRight />)}
            </li>

        } else if (link.career !== null && link.career !== undefined) {
            const links = careers.allStrapiCareers.nodes.map(career => {
                if (link.career.id === career.strapiId) {
                    return (
                        <li key={career.id} className={`internalLink ${variant}`}>
                            <Link
                                to={`/oferta-academica/${link.career.slug}`}
                            >
                                {link.name}
                            </Link>
                            {variant && (variant === 'internalLink__button' ? <FaArrowRight /> : <FaChevronRight />)}
                        </li>
                    )
                } else {
                    return null
                }
            })
            return links
        } else {
            return null
        }
    })


    return (internalsLinks)
}


export default InternalLink