import React, { useState } from "react"
import {
  useAttendanceModes,
  useProgramTypes,
  useCareerStudyClass,
} from "../../hooks"
import useAppContext from "../../context/useAppContext"
import { CheckBoxList, Accordion, LoadButton } from "../index"
import "./_checkBoxFilters.scss"

const CheckBoxFilters = ({ filter , menuMark }) => {
  const {
    resetSearch,
    checkboxPress
  } = useAppContext()

  const [activeMode, setActiveMode] = useState(true)
  const [activeProgram, setActiveProgram] = useState(true)
  const [activeStudyClass, setActiveStudyClass] = useState(true)
  // const [isChecked, setIsChecked] = useState()

  const dataAttendanceModes = useAttendanceModes()
  const dataProgramType = useProgramTypes()
  const dataCareerStudyArea = useCareerStudyClass()

  const handleAreaList = () => {
    !activeStudyClass ? setActiveStudyClass(true) : setActiveStudyClass(false)
  }
  const handleModalityList = () => {
    !activeMode ? setActiveMode(true) : setActiveMode(false)
  }
  const handleProgramTypeList = () => {
    !activeProgram ? setActiveProgram(true) : setActiveProgram(false)
  }

  const resetCheckbox = () => {
    resetSearch()
    const checkboxLength = document.getElementsByClassName('CheckboxList__input').length

    for (let i = 0; i < checkboxLength; i++) {
      document.getElementsByClassName('CheckboxList__input').item(i).checked = false
    }

  }

  const programs = dataProgramType?.allStrapiStudyClasses
  const program_types = programs ? (
    <div className="row Checkbox__accordion mx-0">
      <Accordion
        onActive={handleProgramTypeList}
        title={"Tipo de Programas"}
        state={activeProgram}
      />
      {activeProgram ? (
        <div>
          {programs.nodes.map(programs => (
            <div key={programs?.id}>
              <CheckBoxList
                name={programs?.name}
                id={programs?.id}
                code={programs?.code}
                onPress={checkboxPress}
                filter={filter}
                idType={1}
                menuMark={menuMark}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  ) : null

  const studyAreas = dataCareerStudyArea?.allStrapiCareerStudyClasses
  const study_areas = studyAreas ? (
    <div className="row Checkbox__accordion mx-0">
      <Accordion
        onActive={handleAreaList}
        title={"Áreas de estudio"}
        state={activeStudyClass}
      />
      {activeStudyClass ? (
        <div>
          {studyAreas.nodes.map(areas => (
            <div key={areas?.id}>
              <CheckBoxList
                name={areas?.name}
                id={areas?.id}
                code={areas?.code}
                onPress={checkboxPress}
                filter={filter}
                idType={4}
                menuMark={menuMark}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  ) : null

  const modalities = dataAttendanceModes?.allStrapiAttendanceModes
  const modality = modalities ? (
    <div className="row Checkbox__accordion mx-0">
      <Accordion
        onActive={handleModalityList}
        title={"Modalidades"}
        state={activeMode}
      />
      {activeMode ? (
        <div>
          {modalities.nodes.map(mode => (
            <div key={mode?.id}>
              <CheckBoxList
                name={mode?.name}
                id={mode?.id}
                code={mode?.code}
                onPress={checkboxPress}
                filter={filter}
                idType={2}
                menuMark={menuMark}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  ) : null

  return (
    <>
      <div className="Checkbox ">
        <p className="Checkbox__title">Filtrar por</p>
        <div className="container px-0">
          {program_types}

          {study_areas}

          {modality}

          <div className="row Checkbox__accordion justify-content-center mx-0">
            <LoadButton
              content={"Reiniciar búsqueda"}
              variant={"button-white-outline"}
              click={resetCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckBoxFilters
