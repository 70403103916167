import { useStaticQuery, graphql } from "gatsby"

const useNewsPage = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiNewsPage {
        nodes {
          newsMetadata {
            description: pageDescription
            keywords: pageKeywords
            pageTitle
          }
          title

          newsDestacadas {
            news_contents {
              id
            }
          }
          sections {
            fields {
              summary
              theme
              title
              enable
              anchor
              id
            }
            testimonies {
              id
            }
            blog_articles {
              id
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            form {
              id
              formId
              PortalID
              Name
              formType
            }
            news_contents {
              id
            }
            banner_item {
              id
            }
            videos {
              id
            }
            latest
          }
        }
      }
    }
  `)
  return query
}

export default useNewsPage
