import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react"
import useCareers from "../hooks/useCareers"
import * as jp from "jsonpath"
import { makeQuery } from "../functions/academicOffer.functions"

const AppContext = createContext()
const useAppContext = () => useContext(AppContext) //Custum Hook para solo importar useAppContext y AppProvider

const numberCareers = 6

export const AppProvider = ({ children }) => {
  const [searchField, setSearchField] = useState([])
  const [query, setQuery] = useState("$..nodes[*]")
  const [results, setResults] = useState([])
  const [loadMore, setLoadMore] = useState(numberCareers)
  const result = useCareers()
  let textInput = useRef()

  useEffect(() => {
    setResults(jp.query(result.allStrapiCareers, query))
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = () => {
    for (var i = 0; i < searchField.length; i++) {
      if (searchField[i].type === 3) {
        searchField.splice(i, 1)
        break
      }
    }

    if (textInput.current.value.length >= 3) {
      searchField.push({
        type: 3,
        value: textInput.current.value,
      })
    }
    setLoadMore(numberCareers)
    createQuery()
  }

  const handleEnterPress = e => {
    for (var i = 0; i < searchField.length; i++) {
      if (searchField[i].type === 3) {
        searchField.splice(i, 1)
        break
      }
    }

    if (textInput.current.value.length >= 3 && e.charCode === 13) {
      searchField.push({
        type: 3,
        value: textInput.current.value,
      })
    }
    setLoadMore(numberCareers)
    createQuery()
  }

  const resetSearch = () => {
    setQuery("$..nodes[*]")
    setSearchField([])
  }

  const seeMore = () => {
    setLoadMore(loadMore + numberCareers)
  }

  const InitialFilter = queryFilter => {
    // setSearchField([])
    searchField.push(queryFilter)
    createQuery()
  }

  const checkboxPress = (e, type) => {
    if (e.target.checked) {
      searchField.push({
        type: type,
        value: e.target.value,
      })
    } else {
      for (var i = 0; i < searchField.length; i++) {
        if (
          searchField[i].value === e.target.value &&
          searchField[i].type === type
        ) {
          searchField.splice(i, 1)
        }
      }
    }
    setLoadMore(numberCareers)
    createQuery()
  }

  function createQuery() {
    const querySearch = makeQuery(searchField)
    setQuery(querySearch)
  }

  return (
    <AppContext.Provider
      value={{
        textInput,
        results,
        loadMore,
        handleKeyPress,
        handleEnterPress,
        seeMore,
        InitialFilter,
        resetSearch,
        checkboxPress,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default useAppContext
