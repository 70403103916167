import React from "react"
import { VideoYoutube, VideoVimeo } from "../index"
import useVideoBlock from "../../hooks/useVideoBlock"
import "./_videoBlock.scss"

const VideoBlock = ({ video, theme, title }) => {
  const data = useVideoBlock()
  const videoList = data?.allStrapiVideos?.nodes

  const selectedVideos = video.map(vid =>
    videoList.find(v => v.strapiId === vid.id)
  )
  const classVideo =
    selectedVideos.length > 1 ? "VideoBlock" : "VideoBlock__one"

  const videos = selectedVideos?.map(video => {

    if (video.video.source === "youtube") {
      return <VideoYoutube key={video?.id} data={video?.video} variant={`${classVideo}`} />
    } else {
      return <VideoVimeo key={video?.id} data={video?.video} variant={`${classVideo}`} />
    }
  })

  return (
    <div className={`VideoBlock ${theme}-theme`}>
      <div className="container">
        <div className="row mt-2 mb-3">
          <h2 className="col-12 text-center VideoBlock__title">{title}</h2>
          <div className="col-12 VideoBlock__list">{videos}</div>
        </div>
      </div>
    </div>
  )
}

export default VideoBlock
