import React from 'react'
import { GatsbyImage,  getImage } from "gatsby-plugin-image"

const ImagesBlock = ({title, images}) => {

    const imagesList = images.map((item, idx) => {
        const image = getImage(item?.nameImage)
        return (
            <div key={idx}>
                <GatsbyImage
                  alt={item?.alt}
                  image={image}
                />
            </div>
        )
    })

    return (
        <div className="footer__main__acreditado col-12 col-lg-2">
            <h6>{title}</h6>
            <div className="footer__main__acreditado__logos">
                {imagesList}    
            </div>
          </div>
    )
}

export default ImagesBlock
