import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./layout.template"

import {
  HeaderPage,
  Seo,
  Content,
  CustomSection,
  AsideNews,
  PrevNext,
} from "../components"


import "./_pageBlog.scss"

const PageBlogTemplate = ({ data }) => {
  const page = data.strapiBlogArticles

  let SEO = ""

  if (page.pageMetadata !== null && page.pageMetadata !== undefined && page.pageMetadata.pageTitle !== null) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = (
      <Seo
        title={`${page.title}`}
      />
    )
  }

  const headerPage = (
    <HeaderPage
      title={page.title}
      subtitle={page.blog_categories[0]?.name}
      summary={page.publishedAt}
      image={page.blogImage}
      video={page.video}
      slider={page.slider}
      pageUrl={`/identidad21/${page.slug}`}
      paginaName={["Identidad 21", page.title]}
    />
  )
  const content =
    page.sections.length !== 0 ? (
      <Content content={page.sections} />
    ) : (
      <div className="col-12 col-md-7"></div>
    )
  const tags = page.blog_categories.map(tag => (
    <Link
      key={tag.id}
      to={"/identidad21"}
      state={{ id: tag.id, value: tag.name }}
      className="tags__link btn btn-light"
    >
      {tag.name}
    </Link>
  ))
  const aside =
    page.relatedArticles.length !== 0 ? (
      <AsideNews
        content={page.relatedArticles}
        title="Articulos relacionados"
        type="identidad21"
      />
    ) : null
  return (
    <Layout>
      {SEO}
      <div className="headline headline--news">{headerPage}</div>
      <div className="container PageNews">
        <div className="row PageBlog__row">
          <div className="col-12 col-md-7">{content}</div>
          {aside}
        </div>

      </div>
      <div className="container">
      <PrevNext id={page.strapiId}></PrevNext>
      <div className="tags">
          <h5 className="tags__title">Tags</h5>
          {tags}
        </div>
      </div>
      <CustomSection sections={page.sections} />
    </Layout>
  )
}

export default PageBlogTemplate

export const query = graphql`
  query PageBlog($slug: String!) {
    strapiBlogArticles(slug: { eq: $slug }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      relatedArticles {
        id
        title
        slug
        publishedAt(formatString: "LL", locale: "es-ar")
      }
      id
      strapiId
      title
      slug
      blog_categories {
        name
        id
      }
      publishedAt(formatString: "LL", locale: "es-ar")
      blogImage {
        alt
        nameImage {
          id
          name
          url
        }
      }
      slider{
        id
        type
        imageSlider{
          id
          alt
          nameImage {
            id
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      video {
        id
        nameVideo
        type
        urlVideo
      }
      sections {
        fields {
          anchor
          enable
          summary
          theme
          title
        }
        id
        paragraph
        icon {
          name
          icon {
            type
            code
          }
        }
        image {
          alt
          id
          title
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          urlVideo
        }
        type
        latest
        events {
          date
          event_category
          id
          summary
          time
          title
        }
        banner_item {
          id
        }
        news_contents {
          id
        }
        blog_articles {
          id
        }
        testimonies {
          id
        }
        form {
          id
          formId
          PortalID
          Name
          formType
        }
        videos {
          id
        }
        careers {
          id
        }
        externalSrc {
          url
          name
          type
          id
        }
        internalSrc {
          singlePage
          name
          career {
            slug
            id
            name
          }
          page {
            id
            slug
            title
          }
        }
      }
    }
  }
`
