import React from "react"
import { Link } from "gatsby"
import { useBlogArticles } from "../../hooks"
import "./_prevNext.scss"

const PrevNext = ({ id }) => {
  const data = useBlogArticles()
  const blogArticles = data?.allStrapiBlogArticles?.nodes
  let prevArticle = null
  let nextArticle = null
  blogArticles.map((article, index) => {
    if (article.strapiId === id) {
      prevArticle = index === 0 ? null : blogArticles[index - 1]
      nextArticle =
        index === blogArticles.length - 1 ? null : blogArticles[index + 1]
    }
    return null
  })

  const prev = prevArticle && (
    <div className="col-12 col-md-6 px-0 pr-md-4 nextPrev__item">
      <h5 className="nextPrev__title">Anterior</h5>
      <Link to={`/identidad21/${prevArticle?.slug}`} className="nextPrev__link">
        {prevArticle?.title}
      </Link>
      <p className="nextPrev__date">{prevArticle?.publishedAt}</p>
    </div>
  )
  const next = nextArticle && (
    <div className="col-12 col-md-6 px-0 pl-md-4 text-md-right ml-auto nextPrev__item">
      <h5 className="nextPrev__title">Siguiente</h5>
      <Link to={`/identidad21/${nextArticle?.slug}`} className="nextPrev__link">
        {nextArticle?.title}
      </Link>
      <p className="nextPrev__date">{nextArticle?.publishedAt}</p>
    </div>
  )
  return (
    <div className="nextPrev col-12 col-md-7 px-0">
      {prev}
      {next}
    </div>
  )
}

export default PrevNext
