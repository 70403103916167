import { useStaticQuery, graphql } from "gatsby"

const useBlogCategories = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiBlogCategories {
        nodes {
          id
          name
        }
      }
    }
  `)
  return query
}

export default useBlogCategories
