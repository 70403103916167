import React from "react"
import { ArticleNewsPage } from "../components"
import Layout from "../templates/layout.template"

const NewsPage = ({ location }) => (
  <Layout>
    <ArticleNewsPage filter={location} />
  </Layout>
)

export default NewsPage
