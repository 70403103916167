import React, { useEffect, useState } from "react"
import "./_video.scss"

const VideoVimeo = ({ data, variant, cols }) => {
    const urlApi = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/'
    const url = data?.urlVideo
    let videoId = url.substring(url.lastIndexOf("/") + 1, url.length)

    const [image, setImage] = useState();
    const codeIndex = videoId.indexOf("?")

    if (codeIndex !== -1) {
        videoId = videoId.substring(0, videoId.indexOf("?"))
    }

    useEffect(() => {
        getImgVimeo()
    }, [])

    const getImgVimeo = async () => {
        const resp = await fetch(`${urlApi}${videoId}`)
        const data = await resp.json()
        const urlImg = data?.thumbnail_url
        setImage(urlImg)
    }

    return (
        <>
            <div
                className={`${variant}__video mb-3 col-${cols}`}
                aria-label="Reproductor de video de vimeo"
            >
                <div className="embed-responsive embed-responsive-1by1">
                    <iframe
                        loading="lazy"
                        className="embed-responsive-item"
                        type="text/html"
                        src={url}
                        frameBorder="0"
                        allowFullScreen
                        title="embebed-video"
                        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;
                            width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
                            <a href=${url}>
                            <img src=${image} alt='Video'>
                            <span>▶</span></a>`}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                </div>
            </div>
        </>
    )
}
export default VideoVimeo
