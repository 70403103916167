import React, { useState } from "react"
import { Link } from "gatsby"
import DropdownMenu from "./dropdown.component"
import { Icon } from "../.."
import dataAcademicOffer from "../academicOfferMenuData"

const Menu = ({ section, active, handleLocation, handleOverlay }) => {
  const [isShown, setIsShown] = useState(false)
  const rotate = active && isShown ? "rotate" : ""
  let childs = false

  const handleMenuShown = () => {
    setIsShown(false)
    handleOverlay()
  }

  const academicOffer = dataAcademicOffer?.map(submenuItem => {
    if (submenuItem?.parent === null) {
      childs = true
      return (
        <DropdownMenu
          key={submenuItem?.id}
          submenuItem={submenuItem}
          section={section.slug}
          active={active}
          handleLocation={handleLocation}
          handleMenuShown={handleMenuShown}
        />
      )
    } else {
      return null
    }
  })
  const submenuPages = section?.pages.map(submenuItem => {
    if (submenuItem?.parent === null) {
      childs = true
      return (
        <DropdownMenu
          key={submenuItem?.strapiId}
          submenuItem={submenuItem}
          section={section.slug}
          active={active}
          pageExist={true}
        />
      )
    } else {
      return null
    }
  })
  const submenuLevel1 = section?.submenu.map(submenuItem => {
    if (
      submenuItem?.level1external !== null &&
      submenuItem?.level1external !== undefined
    ) {
      childs = true
      return (
        <DropdownMenu
          key={submenuItem?.level1external.id}
          submenuItem={submenuItem}
          section={section.slug}
          active={active}
        />
      )
    } else {
      return (
        <DropdownMenu
          key={submenuItem?.level1internal.id}
          submenuItem={submenuItem}
          section={section.slug}
          active={active}
        />
      )
    }
  })

  return (
    <li>
      <div
        role="button"
        tabIndex="0"
        className="listItem"
        onMouseEnter={() => setIsShown(active ? isShown : true)}
        onMouseLeave={() => setIsShown(active ? isShown : false)}
      >
        {section.link ? (
          <Link to={`/${section.slug}`}>
            {section.nameSection}
            <div className="dropdown d-none d-lg-block">
              <Icon type={"fas"} code={"angle-down"} />
            </div>
          </Link>
        ) : (
          <>
             <p>{section.nameSection}
              <div className="dropdown d-none d-lg-block">
                <Icon type={"fas"} code={"angle-down"} />
              </div>                
             </p>
          </>
        )}
        {childs && (
          <button
            className={`dropdown d-lg-none ${rotate}`}
            onClick={() => setIsShown(!isShown && active)}
          >
            <Icon type={"fas"} code={"angle-down"} />
          </button>
        )}

        {isShown && (
          <>
            <div className="submenu">
              <div className="container px-0">
                <div className="submenu__level1">
                  <ul>
                    {section.slug === "oferta-academica" && academicOffer}
                    {section.submenu.length > 0 && submenuLevel1}
                    {submenuPages}
                  </ul>
                </div>
                <div className="m-auto image-block">
                  <div
                    className="submenu__image"
                    style={{
                      backgroundImage: `url("${section.imageSection.publicURL}")`,
                    }}
                  >
                    <div className="submenu__image--shadow"></div>
                    <div className="submenu__image--text">
                      <h5>{section.titleImageSection}</h5>
                      <p>{section.descriptionImage}</p>
                      <Link to={`/${section.linkImage}`}>
                        {section.nameLinkImage}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </li>
  )
}
export default Menu
