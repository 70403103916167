import { useStaticQuery, graphql } from "gatsby"

const usePages = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiPages {
        nodes {
          title
          name
          strapiId
          slug
          summary
          id
          strapiParent {
            id
            menuSection
            slug
            name
          }
          menuSection {
            slug
            nameSection
          }
          image {
            title
            nameImage {
              childImageSharp {
                gatsbyImageData(formats: WEBP, webpOptions: { quality: 25 })
              }
            }
          }
        }
      }
    }
  `)
  return query
}

export default usePages
