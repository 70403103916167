import React from "react"
import useAppContext from "../../context/useAppContext"
import { FaSearch } from "../icons"
import "./_searchInput.scss"

//hacer dinamico los textos con title, summary, placeholder en props//

const SearchInput = ({ title, summary, placeholder }) => {
  const { textInput, handleKeyPress, handleEnterPress } = useAppContext()

  return (
    <div className="Search">
      <div className="Search__programs container ">
        <div className="Search__programs__titles ">
          <h3>{title}</h3>
          <div className="text-center">
            <p>
              {summary}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 Search__programs__group">
            <input
              className="Search__programs__input"
              type="text"
              name="query"
              id="seach"
              key="query"
              placeholder={placeholder}
              ref={textInput}
              onKeyPress={handleEnterPress}
            />
            <button
              className="Search__programs__button "
              onClick={handleKeyPress}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SearchInput
