import React from "react"
import { useMetrics } from "../../hooks"
import { ProfileCard, Numbers, Button } from "../index"
import "./_metricas.scss"

const Metrics = ({ metrics, title, theme }) => {
  const data = useMetrics()

  const metricsList = data?.allStrapiMetrics?.nodes

  const customMetricsList = metrics.map(metric =>
    metricsList.find(art => art.strapiId === metric.id)
  )

  const numbersList = customMetricsList
    .map(number => (
      <Numbers
        key={number?.id}
        alt={`Picture of ${number?.name}`}
        variant={"number"}
        picture={number?.iconCifra}
        name={number?.name}
        number={number?.cifra}
        cols={4}
      />
    ))
    .slice(0, 3)

  const customMetrics = customMetricsList
    .map(metric => (
      <ProfileCard
        key={metric?.id}
        alt={`Profile picture of ${metric?.name}`}
        variant={"metric"}
        profilePicture={metric?.picture}
        testimony={metric?.subtitle}
        name={metric?.description}
        cols={4}
      />
    ))
    .slice(0, 3)

  const btnTheme = theme === "dark" ? "light" : "green"

  return (
    <div className={`metric-block container-fluid theme-${theme}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="row mt-4 mb-5 justify-content-center">
          {numbersList}
        </div>
        <div className="row mt-4 justify-content-center">{customMetrics}</div>
        <div className="row justify-content-center mt-4">
          <Button
            content={"Más acerca de Siglo 21"} /* Ver si sale de STRAPI */
            path={`#`}
            variant={`button-${btnTheme}`}
          />
        </div>
      </div>
    </div>
  )
}

export default Metrics
