import React from 'react';
import './_socialMediaIcons.scss';
import { Icon }from "../"

const SocialMediaIcons = ({ message, iconLinks }) => (
  <div className="socialMediaIconsContainer">
    <div className="container">
    <p className="socialMediaIconsContainer__message col-12 col-lg-8">{message}</p>
    <div className="socialMediaIconsContainer__icons col-12 col-lg-4">
      {iconLinks.map((iconLink, idx) => {
        const icon = iconLink?.icon?.icon
        const link = iconLink?.link
        return (
        <a href={link?.url} target="_blank" rel="noreferrer" title={link?.name} key={idx}>
          <Icon type={icon?.type} code={icon?.code} />  
        </a>
      )})}
     </div>
    </div>
  </div>
);

export default SocialMediaIcons;