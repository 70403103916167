import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useFooter } from "../../hooks"

const FiscalData = () => {
    const footer = useFooter()

    const { dataFiscal } = footer?.allStrapiFooter?.nodes[0]
    const imageDataFiscal = getImage(dataFiscal?.image)

    return (
        <div className="footer__logo">
            {dataFiscal?.visible && (
                <a
                    href={dataFiscal.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <GatsbyImage
                        image={imageDataFiscal}
                        alt={dataFiscal.title}
                    />
                </a>
            )}
        </div>
    )

}

export default FiscalData