import React, { useState } from "react"
import { FaSearch } from "../icons"
import "./_articleSearchInput.scss"

const ArticleSearchInput = ({ onQuery, placeholder }) => {
  const [textInput, setTextInput] = useState("")
  const handleInputChange = txt => {
    setTextInput(txt.target.value)
  }
  const handlePressKey = (e) => {
    if(((e.charCode === 13)&&(textInput.length>=3)) || (textInput.length === 0) ){
      onQuery(textInput, e)      
    }
  }
  const handleClick = (e) => {
      onQuery(textInput, e)      
  }

  return (
    <>
      <div className="col-12 Search__article__group">
        <input
          className="Search__article__input"
          type="text"
          name="query"
          id="seach"
          key="query"
          placeholder={placeholder}
          value={textInput}
          onChange={handleInputChange}
          onKeyPress={handlePressKey}
        />
        <button className="Search__article__button" onClick={handleClick}>
          <FaSearch />
        </button>
      </div>
    </>
  )
}

export default ArticleSearchInput
