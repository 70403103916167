import React from "react"
import { Link } from "gatsby"
import { Icon } from "../../components"
import "./_linkStudyClass.scss"

const LinkStudyClasses = ({ studyclass }) => {
    const {
        name,
        icon
    } = studyclass

    return (
        <>
            <div className="col-12 col-md-6 mb-3 studyLink" >
                <div className="studyLink__container" >
                    <div className="studyLink__icon">
                        {icon !== null && <Icon type={icon?.type} code={icon?.code} />}
                    </div>
                    <div className="studyLink__link">
                        <Link
                            to={'/oferta-academica-filtro'}
                            state={{ id: 4, value: name}}
                        >
                            {name}
                        </Link>
                    </div>
                </div>

            </div>

        </>
    )
}
export default LinkStudyClasses
