import React from "react"
import { ArticlePage } from "../components"
import Layout from "../templates/layout.template"

const Identidad = ({ location }) => (
  <Layout>
    <ArticlePage filter={location} />
  </Layout>
)

export default Identidad
