import { useStaticQuery, graphql } from "gatsby"

const useEventCategories = () => {
    const query = useStaticQuery(graphql`
    query {
        allStrapiEventCategories {
            nodes {
              name
              slug
              id
            }
          }
    }
  `)
    return query
}

export default useEventCategories