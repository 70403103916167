import React from "react"
import { Events } from "../components"
import Layout from "../templates/layout.template"

const EventsPage = () => (
  <Layout>
    <Events />
  </Layout>
)

export default EventsPage
