import { useStaticQuery, graphql } from "gatsby"

const useFooter = () => {
  const query = useStaticQuery(graphql`
    {
        allStrapiFooter {
            nodes {
              logo {
                nameImage {
                  childImageSharp {
                    gatsbyImageData (
                      width: 120
                    )
                  }
                }
                alt
                title
              }
              blocks {
                id
                type
                title
                iconsContacts {
                  id
                  name
                  title
                  type
                  url
                  iconContact {
                    id
                    content
                    icon {
                      code
                      type
                    }
                  }
                }
                titleLink {
                  name
                    page {
                      id
                      slug
                    }
                    singlePage
                    career {
                      id
                      slug
                      name
                    }
                }
                links {
                  externalLinks {
                    id
                    name
                    url
                  }
                  internalLinks {
                    id
                    name
                    page {
                      id
                      slug
                    }
                    singlePage
                    career {
                      id
                      slug
                      name
                    }
                  }
                }
                images {
                  alt
                  id
                  title
                  nameImage {
                    childImageSharp {
                        gatsbyImageData (
                          width: 45
                        )
                    }
                  }
                }
              }
              dataFiscal {
                title
                url
                visible
                id
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 70
                    )
                  }
                }
              }
              socialMediaIcons {
                title
                iconLinks {
                  icon {
                    name
                    icon {
                      type
                      code
                    }
                  }
                  link {
                    url
                    name
                  }
                }
              }
            }
        }
    }
  `)
  return query
}
export default useFooter