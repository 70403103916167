import { useStaticQuery, graphql } from "gatsby"

const useCareers = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiCareers(
        filter: { site_types: { elemMatch: { name: { eq: "CMS" } } } }
        sort: { order: ASC, fields: study_class___orderFilterAcademy }
      ) {
        nodes {
          id
          strapiId
          name
          code
          duration
          state
          slug
          idCareer
          site_types {
            id
            name
          }
          attendance_modes {
            name
          }
          study_class {
            name
          }
          career_study_class {
            name
          }
          careerImage {
            childImageSharp {
              gatsbyImageData(formats: WEBP, webpOptions: { quality: 50 })
            }
          }
        }
      }
    }
  `)

  query.allStrapiCareers.nodes.forEach(entity => {
    entity.attendance_modes = entity.attendance_modes.map(modality =>
      modality.name !== undefined ? modality.name : modality
    )
  })

  return query
}

export default useCareers
