import React from "react"
import { Link } from "gatsby"
import "./_asideNews.scss"

function AsideNews({ content, title, type }) {
  const asideBlock = content.map(item => (
    <div className="asideNews__card">
      <Link to={`/${type}/${item.slug}`} className="asideNews__card__title">
        {item.title}
      </Link>
      <p className="asideNews__card__text">{item.publishedAt}</p>
    </div>
  ))
  return (
    <aside className="asideNews col-12 col-md-3">
      <h3 className="asideNews__title">{title}</h3>
      {asideBlock}
    </aside>
  )
}

export default AsideNews
