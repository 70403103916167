import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./layout.template"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import {
  HeaderPage,
  Icon,
  Button,
  TabComponent,
  CustomSection,
  Content,
  Seo,
  HorizontalCardsList,
} from "../components"
import "./_pageCareer.scss"
import { useCareerStudyClass } from "../hooks"

const PageCareer = ({ data }) => {
  const careerStudyClasses = useCareerStudyClass()
  const page = data.strapiCareers
  const cards = page?.CardsList

  let formExist = null
  let SEO = ""
  if (
    page.pageMetadata !== null &&
    page.pageMetadata !== undefined &&
    page.pageMetadata.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = <Seo title={`${page.name}`} />
  }
  const image = getImage(page.careerImage)
  const icon = {}
  careerStudyClasses.allStrapiCareerStudyClasses?.nodes.map(item => {
    if (item.icon?.id === page.career_study_class?.icon) {
      icon.type = item.icon?.type
      icon.code = item.icon?.code
    }
    return null
  })

  const headerPage = (
    <HeaderPage
      title={page.name}
      subtitle={page.study_class?.name}
      category={page.career_study_class?.name}
      icon={icon?.code}
      iconType={icon?.type}
      pageUrl={`/oferta-academica/${page.slug}`}
      paginaName={["Oferta Académica", page.name]}
    />
  )

  //Objeto para enviar a al form
  const formFields = [
    {
      field: "modalidad_de_interes",
      value: page.attendance_modes,
    },
    {
      field: "tipo_de_programa",
      value: page.study_class.code,
    },
    {
      field: "programa_de_interes",
      value: page.name,
    },
    {
      field: "codigo_programa_de_interes",
      value: page.code,
    },
    {
      field: "id_programa_de_interes",
      value: page.idCareer,
    },
  ]

  const detailsItems = page?.Details.map(item => (
    <li className="career-details__item">
      <Icon type={item?.icon?.type} code={item?.icon?.code} />
      <b>{item?.name}: </b>
      {item?.description}{" "}
    </li>
  ))
  const modalidad = page.attendance_modes.map((modalidad, index) =>
    index > 0 ? (
      <span key={modalidad.name}>, {modalidad.name} </span>
    ) : (
      <span key={modalidad.name}>{modalidad.name}</span>
    )
  )
  const tabs = <TabComponent content={page.tabs} />
  const content =
    page.content.length !== 0 ? (
      <Content content={page.content} />
    ) : (
      <div className="col-12 col-md-7"></div>
    )
  const links = page?.Links.map(link => (
    <li className="career-details__links__link">
      <Link to={link?.url} className="">
        {link?.name}
      </Link>
    </li>
  ))

  page.section?.forEach(item => {
    if (item.type === "form") {
      if (item.form?.formType === "Career") {
        formExist =
          item.fields.anchor !== null &&
          item.fields.anchor !== undefined &&
          item.fields.anchor !== ""
            ? item.fields.anchor
            : "form"
      }
    }
  })

  const buttonEcommerce = page.site_types.map(type => {
    if (type.name === "Ecommerce") {
      return (
        <Button
          content={"Inscribirse"}
          variant={"button-green mt-2 w-100"}
          extPath={`https://inscripciones.21.edu.ar/api/programs/career/${page.idCareer}`}
        />
      )
    }
  })
  return (
    <Layout>
      {SEO}
      <div className="headline headline--academic">{headerPage}</div>
      <div className="container pageCareer">
        <div className="col-12 col-md-8 px-0 pr-0 pr-md-5 my-4">
          {tabs} {content}
        </div>
        <div className="col-12 col-md-4 px-0">
          <div className="pageCareer__aside">
            <div className="pageCareer__aside__box">
              <BgImage
                image={image}
                className="pageCareer__aside__box__bgImage"
              >
                {page.state === "Unknown" ? (
                  <></>
                ) : (
                  <span>Inscripción Abierta</span>
                )}
              </BgImage>
            </div>
            <div className="pageCareer__aside__title">
              <h3>{page.name}</h3>
            </div>
            <div className="pageCareer__aside__body">
              <ul className="career-details">
                <li className="career-details__item">
                  <Icon type="fas" code="clock" />
                  <b>Duración:</b> {page.duration}
                </li>
                <li className="career-details__item">
                  <Icon type="fas" code="university" />
                  <b>Modalidad:</b> {modalidad}
                </li>
                {detailsItems}
              </ul>

              <div className="d-flex flex-column px-5 mx-3">
                {formExist !== null && formExist !== undefined && (
                  <Button
                    content={"Más información"}
                    variant={"button-yellow w-100"}
                    path={`#${formExist}`}
                  />
                )}
                {buttonEcommerce}
              </div>
              <ul className="career-details__links">{links}</ul>
            </div>
          </div>
        </div>
      </div>

      <HorizontalCardsList data={cards?.card} title={cards?.fields?.title} />

      <CustomSection sections={page.section} formFields={formFields} />
    </Layout>
  )
}

export default PageCareer

export const query = graphql`
  query PageCareer($slug: String!) {
    strapiCareers(slug: { eq: $slug }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      CardsList {
        type
        id
        fields {
          anchor
          enable
          id
          theme
          title
        }
        card {
          description
          id
          subtitle
          title
          image {
            title
            type
            id
            alt
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          internalLink {
            id
            name
            type
            singlePage
            page {
              name
              id
              slug
              summary
              title
            }
            news_content {
              destacado
              id
              slug
              title
            }
            career {
              career_study_class
              code
              duration
              id
              idCareer
              name
              slug
              state
              study_class
              careerImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      Links {
        id
        name
        url
      }
      id
      name
      code
      slug
      state
      duration
      idCareer
      site_types {
        id
        name
      }
      buttonEcommerce {
        label
        url
      }
      study_class {
        name
        code
      }
      career_study_class {
        name
        icon
      }
      attendance_modes {
        name
        code
      }
      careerImage {
        childImageSharp {
          gatsbyImageData(formats: WEBP, webpOptions: { quality: 50 })
        }
      }
      Details {
        name
        id
        description
        icon {
          type
          code
        }
      }
      tabs {
        type
        fields {
          title
          summary
        }
        Items {
          id
          title
          detail
        }
        accordionBlock {
          id
          title
          itemsAccordion {
            id
            title
            detail
          }
        }
        textBlock {
          fields {
            anchor
            enable
            summary
            theme
            title
          }
          id
          paragraph
          type
          image {
            id
            alt
            title
            type
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          video {
            urlVideo
          }
        }
      }
      section {
        type
        fields {
          enable
          title
          summary
          anchor
          theme
        }
        blog_articles {
          id
        }
        testimonies {
          id
        }
        careers {
          id
        }
        banner_item {
          id
        }
        events {
          id
        }
        form {
          formId
          PortalID
          Name
          formType
        }
        news_contents {
          id
        }
        videos {
          id
        }
      }
      content {
        fields {
          anchor
          enable
          summary
          theme
          title
        }
        id
        paragraph
        image {
          alt
          id
          title
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          urlVideo
        }
        type
      }
    }
  }
`
