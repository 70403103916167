import React from "react"
import "./_tab.scss"
import "../../styles/_markdown.scss"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { TabContent, Content } from ".."
import Markdown from "react-markdown"

const TabComponent = ({ content }) => {

  const tabs = content?.map(
    item => item.type === "programBlock" && <Tab>{item.fields?.title}</Tab>
  )
  const contentTab = content?.map(
    item =>
      item.type === "programBlock" && (
        <TabPanel>
          {item?.fields?.summary !== undefined && item?.fields?.summary !== null ? (
            <div className="markdown">
              <Markdown escapeHtml={true}>{item.fields.summary}</Markdown>
            </div>
          ) : null}
          {item.Items.length > 0 && item.Items !== undefined && item.Items !== null ? (
            <div className="contentAcordion">
              {item.Items?.map(accordeonItem => (
                <TabContent item={accordeonItem}/>
              ))}
            </div>
          ) : null}
          {item.accordionBlock.length > 0 && item.accordionBlock !== undefined && item.accordionBlock !== null && (
            <div className="acordionBlocks">
            {item.accordionBlock?.map(block => (
              <div key="block.id">
                <div className="contentAcordion">
                  {block.itemsAccordion?.map(blockItem => (
                    <TabContent item={blockItem}/>
                  ))}
                </div>
                <h2>{block.title}</h2>
              </div>
            ))}
          </div>
          )}

          
          {item.textBlock && (
          <div className="blockText row">
            <Content key="block.id" content={item.textBlock} />
          </div>
          )}

        </TabPanel>
      )
  )

  return (
    <div>
      <Tabs>
        <TabList>{tabs}</TabList>
        {contentTab}
      </Tabs>
    </div>
  )
}

export default TabComponent
