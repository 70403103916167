import React from "react"
import Carousel from "react-bootstrap/Carousel"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const Slider = ({ slider }) => {
  const sliders = slider.imageSlider.map((item, idx) => {
    const image = getImage(item?.nameImage)
    return (
      <Carousel.Item key={idx} className="">
        <BgImage image={image} className="bgImage" />
      </Carousel.Item>
    )
  })

  return (
    <Carousel fade indicators={false}>
      {sliders}
    </Carousel>
  )
}

export default Slider
