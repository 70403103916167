import React, { useState } from 'react'
//import { FaPhoneAlt } from "../icons"
import { Accordion } from "../index"
import { FiscalData } from "../"
import { Icon } from "../"

const ContactsBlock = ({ title, iconsContacts }) => {

    const [activeItem, setActiveItem] = useState(false)
    const handleItem = () => {
        !activeItem ? setActiveItem(true) : setActiveItem(false)
    }

    const iconsContactList = iconsContacts?.map((contact) => {
        const icon = contact?.iconContact?.icon.code
        return (
            <li key={contact?.id}>
                <div >
                    {(icon.includes('phone')) ? (
                        <>
                            <p>{contact?.title}</p>
                            <a className="d-flex" href={`tel:${contact?.url}`} >
                                <Icon type={contact?.iconContact?.icon?.type} code={contact?.iconContact?.icon?.code} />
                                {contact?.iconContact?.content}
                            </a>

                        </>
                    ) : (
                        <>
                            <p>{contact?.title}</p>
                            <a className="d-flex" href={contact?.url} target="_blank" rel="noreferrer" title={contact?.name} >
                                <Icon type={contact?.iconContact?.icon?.type} code={contact?.iconContact?.icon?.code} />
                                {contact?.iconContact?.content}
                            </a>
                        </>
                    )}

                </div>

            </li>
        )
    })

    return (
        <div className="footer__main__links col-12 col-lg-2">
            <div className="d-lg-none">
                <Accordion onActive={handleItem} title={title} state={activeItem} />
                {activeItem && (
                    <>
                        <ul>
                            {iconsContactList}
                            <FiscalData />
                        </ul>

                    </>
                )}
            </div>

            <h6 className="d-none d-lg-block">{title}</h6>
            <ul className="d-none d-lg-block">
                {iconsContactList}
            </ul>

            <div className="d-none d-lg-block">
                <FiscalData />
            </div>

        </div>
    )
}

export default ContactsBlock 
