import { useStaticQuery, graphql } from "gatsby"

const useEventsLatest = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiEvents(sort: {fields: date, order: DESC}) {
        nodes {
          site{
            id
            name
            longitude
            latitude
            
          }
          slug
          title
          date
          formatDateMonth: date(formatString: "MMMM", locale: "ES-AR")
          formatDateDay: date(formatString: "DD", locale: "ES-AR")
          time
          summary
          strapiId
          expiredDate
          sections {
            fields {
              title
              theme
              enable
              summary
            }
            paragraph
            type
            image {
              nameImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          eventImage {
            nameImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          event_category {
            name
            id
            slug
          }
          event_type {
            id
            name
            slug
          }
        }
      }
    }
  `)

  const arrayResult = query.allStrapiEvents.nodes.filter(item => item.expiredDate >= new Date().toISOString().slice(0, 10))
  query.allStrapiEvents.nodes = arrayResult
  return query
}

export default useEventsLatest
