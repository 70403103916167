import { useStaticQuery, graphql } from "gatsby"

const useExternalServiceLinks = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiExternalServiceLinks(filter: { visible: { eq: true } }) {
        nodes {
          id
          name
          theme
          image {
            publicURL
          }
          externalLink {
            name
            url
          }
          icon {
            code
            type
          }
          internalLink {
            name
            page {
              id
              slug
            }
          }
        }
      }
    }
  `)
  return query
}

export default useExternalServiceLinks
