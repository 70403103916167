import { useStaticQuery, graphql } from "gatsby"

const useAttendanceModes = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiAttendanceModes(sort: {fields: order}) {
        nodes {
          id
          name
          slug
          code
        }
      }
    }
  `)
  return query
}

export default useAttendanceModes
