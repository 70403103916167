import React, { useState } from "react"
import { useEventTypes, useEventCategories, useSites } from "../../hooks"
import { EventCheckBoxList, Accordion } from "../index"
import "./_eventsCheckBoxFilters.scss"

const EventsCheckBoxFilters = ({ onQuery }) => {

    const [activeEventTypes, setactiveEventTypes] = useState(true)
    const [activeEventCategories, setactiveEventCategories] = useState(true)
    const [activeEventSites, setactiveEventSites] = useState(true)

    const dataEventTypes = useEventTypes()
    const dataEventsCategories = useEventCategories()
    const dataEventsSites = useSites()

    const handleEventTypesList = () => {
        !activeEventTypes ? setactiveEventTypes(true) : setactiveEventTypes(false)
    }
    const handleEventCategoriesList = () => {
        !activeEventCategories ? setactiveEventCategories(true) : setactiveEventCategories(false)
    }
    const handleEventSitesList = () => {
        !activeEventSites ? setactiveEventSites(true) : setactiveEventSites(false)
    }

    const eventTypes = dataEventTypes?.allStrapiEventTypes
    const eventTypesList = eventTypes ? (
        <div className="row Checkbox__accordion mx-0">
            <Accordion
                onActive={handleEventTypesList}
                title={"Tipo de Evento"}
                state={activeEventTypes}
            />
            {activeEventTypes ? (
                <div>
                    {eventTypes.nodes.map(eventType => (
                        <div key={eventType?.id}>
                            <EventCheckBoxList
                                name={eventType?.name}
                                id={eventType?.id}
                                onPress={onQuery}
                                value={eventType?.name}
                                idType={1}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    ) : null

    const eventCategories = dataEventsCategories?.allStrapiEventCategories
    const eventCategoriesList = eventCategories ? (
        <div className="row Checkbox__accordion mx-0">
            <Accordion
                onActive={handleEventCategoriesList}
                title={"Tema de Evento"}
                state={activeEventCategories}
            />
            {activeEventCategories ? (
                <div>
                    {eventCategories.nodes.map(eventCategory => (
                        <div key={eventCategory?.id}>
                            <EventCheckBoxList
                                name={eventCategory?.name}
                                id={eventCategory?.id}
                                onPress={onQuery}
                                value={eventCategory?.name}
                                idType={2}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    ) : null

    const eventSites = dataEventsSites?.allStrapiSites
    const eventSitesList = eventSites ? (
        <div className="row Checkbox__accordion mx-0">
            <Accordion
                onActive={handleEventSitesList}
                title={"Sedes"}
                state={activeEventSites}
            />
            {activeEventSites ? (
                <div>
                    {eventSites.nodes.map(eventSite => {
                        if (eventSite.sede === true) {
                            return (
                                <div key={eventSite?.id}>
                                    <EventCheckBoxList
                                        name={eventSite?.name}
                                        id={eventSite?.id}
                                        onPress={onQuery}
                                        value={eventSite?.name}
                                        idType={3}
                                    />
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            ) : null}
        </div>
    ) : null
    return (
        <>
            <div className="Checkbox ">
                <div className="container px-0">
                    {eventCategoriesList}
                    {eventTypesList}
                    {eventSitesList}
                </div>
            </div>
        </>
    )
}

export default EventsCheckBoxFilters
