import React, { useState } from "react"
import { Accordion } from "../index"
import Markdown from "react-markdown"

const TabContent = ({ item }) => {
  const [activeItem, setActiveItem] = useState(false)
  const handleItem = () => {
    !activeItem ? setActiveItem(true) : setActiveItem(false)
  }
  return (
    <div>
      <Accordion onActive={handleItem} title={item.title} state={activeItem} />
      {activeItem && (
        <div className="markdown">
          <Markdown escapeHtml={true}>{item.detail}</Markdown>
          {/* <p>{item.detail}</p> */}
        </div>
      )}
    </div>
  )
}

export default TabContent
