import React, { useState } from "react"
import { FaSearch } from "../icons"
import "./_eventsSearchInput.scss"

const EventSearchInput = ({ onQuery }) => {
  const [textInput, setTextInput] = useState("")
  const handleInputChange = txt => {
    setTextInput(txt.target.value)
  }
  const handlePressKey = () => {
    onQuery(textInput)
  }

  const handleEnterPress = (e) => {
    if(e.charCode===13){
      onQuery(textInput)
    }
  }

  return (
    <>
      <div className="col-12 Search__article__group">
        <input
          className="Search__article__input"
          type="text"
          name="query"
          id="seach"
          key="query"
          placeholder="Buscar eventos..."
          value={textInput}
          onChange={handleInputChange}
          onKeyPress={handleEnterPress}
        />
        <button className="Search__article__button" onClick={handlePressKey}>
          <FaSearch />
        </button>
      </div>
    </>
  )
}

export default EventSearchInput
