import React from 'react'
import { Link } from "gatsby"
import { Icon } from "../"

const ExternalServiceLink = ({
    name,
    theme,
    externalLink,
    internalLink,
    internalLinkRoute,
    image,
    icon
}) => {

    return (
        <div className="col-12 col-lg-4 mb-3">
            {internalLink && (
                <Link
                    to={internalLinkRoute} 
                    className={`button login-${theme}`} 
                    style={{
                        backgroundImage: `url("${image?.publicURL}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "137px 70px",
                        backgroundPosition: "90% 0px",
                        display: "flex",
                        justifyContent: "flex-start"
                    }}>
                    {icon && (
                        <div>
                            <Icon type={icon.type} code={icon.code} />
                        </div>
                    )}
                    <div>
                        {name}
                    </div>
                </Link> 
            )}
            {externalLink && (
                <a 
                    href={externalLink?.url} 
                    target="_blank"
                    rel="noreferrer"
                    className={`button login-${theme}`} 
                    style={{
                        backgroundImage: `url("${image?.publicURL}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "137px 70px",
                        backgroundPosition: "90% 0px",
                        display: "flex",
                        justifyContent: "flex-start"
                    }}>
                    {icon && (
                        <div>
                            <Icon type={icon.type} code={icon.code} />
                        </div>
                    )}
                    <div>
                        {name}
                    </div>
                </a>
            )}
            
        </div>
    )
}

export default ExternalServiceLink
