import React from "react";
import { Seo, CustomSection, Banner, SubMenu, BotIframe} from "../index";
import { useHome } from "../../hooks";

const HomePage = () => {
  const data = useHome();
  const pageInfo = data?.allStrapiHome?.nodes[0];
  const sections = data?.allStrapiHome?.nodes[0]?.sections;
  const bannerItems = data?.allStrapiHome?.nodes[0]?.banner?.banner_items;
  const titleShort = data?.allStrapiHome?.nodes[0]?.titleHome?.title;
  const titleLong = data?.allStrapiHome?.nodes[0]?.titleHome?.detail;
  const subMenu = (
    <SubMenu
      titleShort={titleShort}
      titleLong={titleLong}
      sections={sections}
    />
  );

  let SEO = "";

  if (
    pageInfo?.homeSeo !== null &&
    pageInfo?.homeSeo !== undefined &&
    pageInfo?.homeSeo.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = pageInfo?.homeSeo ?? {};

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    );
  } else {
    SEO = <Seo title={"Home"} />;
  }

  return (
    <>
      {SEO}
      {bannerItems !== undefined &&
        bannerItems !== null &&
        bannerItems.length > 0 && <Banner bannerItems={bannerItems} />}
      {sections !== undefined &&
        sections !== null &&
        sections.length > 0 &&
        subMenu}
      {sections !== undefined && sections !== null && sections.length > 0 && (
        <CustomSection sections={sections} />
      )}
      <BotIframe />
    </>
  );
};

export default HomePage;
