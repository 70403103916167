import React from "react"
import { Link } from "gatsby"
import "./_article.scss"

const Article = ({ article, variant }) => {
  const { blog_categories, publishedAt, title, slug } = article

  const category =
    blog_categories[0] !== undefined && blog_categories[0] !== null ? (
      <p>{blog_categories[0]}</p>
    ) : null
  return (
    <>
      <div className={`article article__${variant}`}>
        <div className={`article__${variant}__categoria`}>{category}</div>
        <h3 className={`article__${variant}__titulo`}>
          <Link to={`/identidad21/${slug}`}>{title}</Link>
        </h3>
        <p className={`article__${variant}__fecha`}>{publishedAt}</p>
      </div>
    </>
  )
}

export default Article
