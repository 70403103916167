import React from "react"
import { useCareers } from "../../hooks"
import { CareerCard, Button } from "../index"
import "./_relatedPrograms.scss"

const RelatedPrograms = ({ careers, title, theme }) => {
  const data = useCareers()
  const careersList = data?.allStrapiCareers?.nodes

  const customCareer = careers.map(career =>
    careersList.find(item => item?.strapiId === career.id)
  )

  const customCareers = customCareer
    .map(career => career !== undefined ? <CareerCard key={career?.id} career={career} /> : null)
    .slice(0, 3)
  const buttonVariant = (theme === "dark") ? "button-white" : "button-green"
  return (
    <div className={`relatedPrograms ${theme}-theme`}>
      <h2 className="relatedPrograms__title">{title}</h2>
      <div className="container relatedPrograms__cards">{customCareers}</div>
      <div className="d-flex justify-content-center py-5">
      <Button
          content={"Ver todos los programas"}
          path={`/oferta-academica`}
          variant={buttonVariant}
        />
      </div>
      
    </div>
  )
}

export default RelatedPrograms
