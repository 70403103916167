import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout.template"
import { HeaderPage, Seo, Map, Button, Icon } from "../components"
import "./_pageSite.scss"

const PageSite = ({ data }) => {
  const page = data.strapiSites
  let SEO = ""
  SEO = (
    <Seo
      title={`${page.name}`}
      description={`Dirección: ${page.street}, ${page.streetNumber}`}
      keywords={`${page.name}`}
    />
  )

  const street = (
    <div className="site__item">
      Dirección: {page.street}
      {page.streetNumber?.replace(/ /g, "") !== "" &&
        page.streetNumber !== null && <span>,{page.streetNumber}</span>}
      {page.floor?.replace(/ /g, "") !== "" && page.floor !== null && (
        <span>, Piso: {page.floor}</span>
      )}
      {page.apartment?.replace(/ /g, "") !== "" && page.apartment !== null && (
        <span>, Departamento: {page.apartment}</span>
      )}
      {page.tower?.replace(/ /g, "") !== "" && page.tower !== null && (
        <span>, Torre: {page.tower}</span>
      )}
    </div>
  )

  const phones = (
    <div className="site__item">
      <Icon type={"fas"} code={"phone"} />
      <span>Tel: {page.phone1}</span>
      {page.phone2?.replace(/ /g, "") !== "" && page.phone2 !== null && (
        <span>, {page.phone2}</span>
      )}
    </div>
  )

  const headerPage = (
    <HeaderPage
      title={page.name}
      pageUrl={`/sedes-y-centros/${page.slug}`}
      paginaName={["Sedes y Centros", page.name]}
    />
  )
  const content = (
    <div className="col-12 col-md-7">
      <div className="site">
        <h2>{page.name}</h2>
        {page.street && street}
        {page.phone1 && phones}
        {page.email && (
          <div className="site__item">
            <Icon type={"fas"} code={"envelope"} />{" "}
            <a href={`mailto:${page.email}`}>{page.email}</a>
          </div>
        )}
      </div>
    </div>
  )

  const map = (
    <div className="col-12 col-md-7 my-3">
      <h3>Ubicación</h3>
      <div className="map">
        <Map location={page} zoom="15" />
      </div>
      <Button
        content={"Volver a sedes y centros"}
        path={"/sedes-y-centros"}
        variant={"button-green my-3"}
      />
    </div>
  )

  return (
    <Layout>
      {SEO}
      <div className="headline headline--news">{headerPage}</div>
      <div className="container PageSite py-4">
        <div className="row PageSite__row">
          {content}
          {map}
        </div>
      </div>
    </Layout>
  )
}

export default PageSite

export const query = graphql`
  query PageSite($slug: String!) {
    strapiSites(slug: { eq: $slug }) {
      id
      name
      slug
      street
      streetNumber
      floor
      apartment
      tower
      phone1
      phone2
      email
      latitude
      longitude
    }
  }
`
