import React, { useState } from 'react'
import { Accordion, InternalLink, OneInternalLink } from "../index"

const LinksBlock = ({titleLink, internalLinks, externalLinks}) => {

    const [activeItem, setActiveItem] = useState(false)
    const handleItem = () => {
        !activeItem ? setActiveItem(true) : setActiveItem(false)
    }

    const extLinks = externalLinks?.map((link => (
        <li key={link?.id}>
            <a  target="_blank" rel="noreferrer" href={link?.url}>
                {`${link?.name}`}
            </a>
        </li>
        
    )))

    return (
        <div className="footer__main__links col-12 col-lg-2">
            <div className="d-lg-none">
                <Accordion isLink={true} onActive={handleItem} state={activeItem} titleLink={titleLink} />
                {activeItem && (
                    <ul>
                        { [...extLinks] }
                        {  internalLinks.length > 0 && <InternalLink internalLinks={internalLinks} />}
                    </ul>
                )}
            </div>
            
            <h6 className="d-none d-lg-block">
                <OneInternalLink internalLink={titleLink} />
            </h6>
            <ul className="d-none d-lg-block">
                { [...extLinks] }
                { internalLinks.length > 0 && <InternalLink internalLinks={internalLinks} /> }
            </ul>
        </div>
    )
}

export default LinksBlock