import React from "react"
import Script from "@gumgum/react-script-tag"
import {
  Text,
  Image,
  Video,
  EmbebedMap,
  ButtonPage,
  CardsList,
  PdfCard,
  Slider,
  BannerSimple,
} from ".."
import "./_content.scss"

const Content = ({ content }) => {
  const haveData = field => {
    return field !== null && field !== undefined
  }

  const contentBlock = content.map((block, idx) => {
    const {
      fields,
      paragraph,
      image,
      video,
      type,
      source,
      title,
      externalSrc,
      internalSrc,
      card,
      script,
      link,
      description,
      icon,
      imageSlider,
      banner_item,
    } = block

    const fieldsContent = fields?.enable && (
      <p className={"Content__summary"}>{fields?.summary}</p>
    )
    const textContent = paragraph && (
      <Text text={paragraph} variant={"Content"} icon={icon} />
    )

    const imageContent = image?.length > 0 && (
      <div className="Content__images row">
        {image.map((img, idx) => (
          <Image
            data={img}
            key={idx}
            variant={"Content__images"}
            cols={columns(image)}
          />
        ))}
      </div>
    )

    const videoContent = video?.length > 0 && (
      <div className="Content__video row">
        {video.map((vid, idx) => (
          <Video
            data={vid}
            key={idx}
            variant={"Content__video"}
            cols={columns(video)}
          />
        ))}
      </div>
    )

    const embebedMap = haveData(source) ? (
      <div>
        <div className="Content__title_embebedMap">{title}</div>
        <div className="Content__embebedMap row">
          <EmbebedMap source={source} />
        </div>
      </div>
    ) : null

    const buttonPage =
      //Agregar la solucion del video block en pages, en la query
      //en el txt del desktop esta
      (haveData(externalSrc)) ||
      (haveData(internalSrc)) ? (
        <div className="Content__buttonPage">
          {<ButtonPage externalSrc={externalSrc} internalSrc={internalSrc} />}
        </div>
      ) : null

    const cardsList =
      haveData(card) ? (
        <CardsList data={card} title={fields?.title} />
      ) : null

    const pageScript =
      haveData(script?.src) &&
      script?.enable === true ? (
        <Script
          type="text/javascript"
          src={script?.src}
          id={script?.name}
          async
          defer
        />
      ) : null

    const pdfFile =
      haveData(link) && type === "pdfFile" ? (
        <PdfCard link={link} description={description} title={title} />
      ) : null

    const slider =
      haveData(imageSlider)  ? (
        <Slider slider={block} />
      ) : null

    const simpleBanner =
      haveData(banner_item) ? (
        <BannerSimple bannerItems={banner_item} />
      ) : null

    function columns(arr) {
      let length = arr.length
      let cols = 12 / length
      return cols
    }

    return (
      <div key={idx}>
        <>
          {fieldsContent}
          {type === "text" && textContent}
          {type === "text" && imageContent}
          {type === "text" && videoContent}
          {type === "map" && embebedMap}
          {type === "Button" && buttonPage}
          {type === "cardsList" && cardsList}
          {type === "pageScript" && pageScript}
          {type === "pdfFile" && pdfFile}
          {type === "slider" && slider}
          {type === "simpleBanner" && simpleBanner}
        </>
      </div>
    )
  })

  return <section className="Content">{contentBlock}</section>
}

export default Content

/* content {
        fields {
          anchor
          enable
          summary
          theme
          title
        }
        id
        paragraph
        image {
          alt
          id
          title
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        type */
