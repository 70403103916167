import React from "react"
import "./_checkBoxList.scss"

const CheckBoxList = props => {
  const { code, id, name, onPress, filter, idType, menuMark } = props
  let mark = false

  const handlePressCheckBox = e => {
    onPress(e,idType)
  }

  if (filter.state !== null && filter.state !== undefined) {
    mark = filter.state.id === idType && filter.state.value === name ? true : false

  }
  return (
    <div>
      <label className="CheckboxList__label">
        {menuMark ?  <input
          className="CheckboxList__input"
          type="checkbox"
          id={`${id}`}
          name={`${code}`}
          value={`${name}`}
          onClick={handlePressCheckBox}
          checked={mark}
         
        /> :
        <input
        className="CheckboxList__input"
        type="checkbox"
        id={`${id}`}
        name={`${code}`}
        value={`${name}`}
        onClick={handlePressCheckBox}
        defaultChecked={mark}
       
      />
        }
        {name}
      </label>
    </div>
  )
}

export default CheckBoxList
