import "./_backToTop.scss";
import { Link } from "gatsby";
import { FaArrowUp } from "../icons/index";
import React from "react";


const BackToTop = () => {
  return (
    <Link to="#top">
      <div className="backToTop">
        <FaArrowUp color="#212529" size={18} />
      </div>
    </Link>
  );
};

export default BackToTop;
