import React from "react"


const SelectOptions = ({ data, name, onChangeSelect }) => {

    const values = data.map(value => (
        <option value={name === "careers" ? value.slug : value.name} key={value.name}>{value.name}</option>
    ))

    return (
        <select name={name} onChange={onChangeSelect} defaultValue="">
            <option value="">Seleccionar...</option>
            {values}
        </select>
    )
}


export default SelectOptions