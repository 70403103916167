import React from "react"
import { Image, Text , Video} from ".."
import "./_aside.scss"

const Aside = ({ content }) => {
  const asideBlock = content.map((block, idx) => {
    const { fields, paragraph, image, video, type, icon } = block

    const fieldsContent = fields.enable && (
      <p className={"Aside__summary"}>{fields.summary}</p>
    )
    const textContent = paragraph && (
      <Text text={paragraph} variant={"Aside"} icon={icon} />
    )
    const imageContent = (
      <div className="Aside__images row">
        {image &&
          image.map((img, idx) => (
            <Image
              data={img}
              key={idx}
              variant={"Aside__images"}
              cols={columns(image)}
            />
          ))}
      </div>
    )
    const videoContent = (
      <div className="row">
        {video &&
          video.map((vid, idx) => (
            <Video
              data={vid}
              key={idx}
              variant={"Aside"}
              cols={columns(video)}
            />
          ))}
      </div>
    )

    /* ¿¿¿¿¿¿   FUNCTION PARA LLEVAR A CONTEXT    ??? */
    function columns(arr) {
      let length = arr.length
      let cols = 12 / length
      return cols
    }
    /* ============================================== */

    return (
      <div key={idx}>
        {type === "text" ? (
          <>
            {fieldsContent}
            {textContent}
            {imageContent}
            {videoContent}
          </>
        ) : null}
      </div>
    )
  })
  return (
    <aside className="aside col-12 col-lg-3">{asideBlock}</aside>
  )
}

export default Aside
