import { useStaticQuery, graphql } from "gatsby"

const useVideoBlock = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiVideos {
        nodes {
          title
          strapiId
          id
          video {
            nameVideo
            urlVideo
            source
          }
        }
      }
    }
  `)
  return query
}

export default useVideoBlock
