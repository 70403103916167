import React from 'react'
import { useAcademicOfferMain } from '../../hooks'
import LogoWhatsapp from '../../images/whatsapp.png'

const FloatingWhatsapp = () => {
    const academicOfferMain = useAcademicOfferMain()
    const pageInfo = academicOfferMain?.allStrapiAcademicOfferMain?.nodes[0]
    const urlWhatsappFlotante = pageInfo?.whatsappFlotante?.url
    return (
        <>
            {urlWhatsappFlotante && (
                <div>
                    <a href={urlWhatsappFlotante} target="_blank" rel="noreferrer">
                        <img className="icon-whatsapp" src={LogoWhatsapp} alt="Whatsapp" />
                    </a>
                </div>
            )}
        </>

    )
}

export default FloatingWhatsapp
