import React, { useState } from "react"
import { Link } from "gatsby"
import {
  useAttendanceModes,
  useCareerStudyClass,
  usePages,
  useProgramTypes,
  useCareers,
} from "../../../hooks"
import { Icon, OneInternalLink } from "../.."

const DropdownMenu = ({
  submenuItem,
  active,
  section,
  handleLocation,
  handleMenuShown,
  pageExist,
}) => {
  const [isShown, setIsShown] = useState(false)
  const pages = usePages()
  const careers = useCareers()
  let childs = false
  const activeClass = isShown ? "active" : ""
  const dataProgramTypes = useProgramTypes()
  const dataCareerStudyClass = useCareerStudyClass()
  const dataAttendaceModes = useAttendanceModes()

  if (
    (submenuItem.level2 !== null && submenuItem.level2 !== undefined) ||
    submenuItem.name === "Tipo de programa" ||
    submenuItem.name === "Programas y Carreras" ||
    submenuItem.name === "Modalidades"
  ) {
    childs = true
  } else {
    childs = false
  }

  const handleType = (value, id) => {
    handleLocation(value, id)
    handleMenuShown()
  }

  const programTypes = dataProgramTypes?.allStrapiStudyClasses?.nodes.map(
    type => {
      return (
        <li key={type.id}>
          {handleLocation !== null && handleLocation !== undefined ? (
            <button className onClick={() => handleType(type.name, 1)}>
              <p>{type.name}</p>
            </button>
          ) : (
            <Link
              to={"/oferta-academica-filtro/"}
              state={{ id: 1, value: type.name }}
            >
              <p>{type.name}</p>
            </Link>
          )}
        </li>
      )
    }
  )
  const careerStudyClasses = dataCareerStudyClass?.allStrapiCareerStudyClasses?.nodes.map(
    type => {
      return (
        <li key={type.id}>
          {handleLocation !== null && handleLocation !== undefined ? (
            <button
              className
              onClick={() => {
                handleType(type.name, 4)
              }}
            >
              <p>{type.name}</p>
            </button>
          ) : (
            <Link
              to={"/oferta-academica-filtro/"}
              state={{ id: 4, value: type.name }}
            >
              <p>{type.name}</p>
            </Link>
          )}
        </li>
      )
    }
  )
  const attendaceModes = dataAttendaceModes?.allStrapiAttendanceModes?.nodes.map(
    type => {
      return (
        <li key={type.id}>
          {handleLocation !== null && handleLocation !== undefined ? (
            <button
              className
              onClick={() => {
                handleType(type.name, 2)
              }}
            >
              <p>{type.name}</p>
            </button>
          ) : (
            <Link
              to={"/oferta-academica-filtro/"}
              state={{ id: 2, value: type.name }}
            >
              <p>{type.name}</p>
            </Link>
          )}
        </li>
      )
    }
  )

  const submenuPagesL2 = pages?.allStrapiPages?.nodes.map(submenuL2Item => {
    if (submenuL2Item?.strapiParent?.id === submenuItem.id && pageExist) {
      childs = true
      return (
        <li key={submenuL2Item.id}>
          <Link to={`/${section}/${submenuItem.slug}/${submenuL2Item.slug}`}>
            <p>{submenuL2Item.name}</p>
          </Link>
        </li>
      )
    } else {
      return null
    }
  })

  const submenuL2external = submenuItem.level2?.externalLinks.map(
    externalLink => {
      return (
        <li key={externalLink.id}>
          <a href={externalLink.url} target="_blank" rel="noreferrer">
            <p>{externalLink.name}</p>
          </a>
        </li>
      )
    }
  )
  const submenuL2internal = submenuItem.level2?.internalLinks.map(
    internalLink => {
      return (
        <li>
          <OneInternalLink
            internalLink={internalLink}
            variant={"menu"}
          ></OneInternalLink>
        </li>
      )
    }
  )

  let customLink = ""
  if (
    submenuItem.level1external !== null &&
    submenuItem.level1external !== undefined
  ) {
    customLink = (
      <a
        className="submenuLink"
        href={submenuItem.level1external.url}
        target="_blank"
        rel="noreferrer"
      >
        <p>{submenuItem.level1external.name}</p>
        {childs && (
          <div className="dropdown d-none d-lg-block">
            <Icon type={"fas"} code={"angle-down"} />
          </div>
        )}
      </a>
    )
  } else if (
    submenuItem.level1internal !== null &&
    submenuItem.level1internal !== undefined
  ) {
    if (
      submenuItem.level1internal.singlePage !== null &&
      submenuItem.level1internal.singlePage !== undefined
    ) {
      customLink = (
        <Link
          className="submenuLink"
          to={`/${submenuItem.level1internal.singlePage.replace(/_/g, "-")}`}
        >
          <p>{submenuItem.level1internal.name}</p>
          {childs && (
            <div className="dropdown d-none d-lg-block">
              <Icon type={"fas"} code={"angle-down"} />
            </div>
          )}
        </Link>
      )
    } else if (
      submenuItem.level1internal.page !== null &&
      submenuItem.level1internal.page !== undefined
    ) {
      customLink = pages?.allStrapiPages?.nodes.map(page => {
        if (page.strapiId === submenuItem.level1internal.page.id) {
          const slugSection =
            page.menuSection !== null ? `/${page.menuSection.slug}` : ""
          const slugParent =
            page.strapiParent !== null ? `/${page.strapiParent.slug}` : ""
          return (
            <Link
              key={page.id}
              className="submenuLink"
              to={`${slugSection}${slugParent}/${submenuItem.level1internal?.page?.slug}`}
            >
              <p>{submenuItem.level1internal.name}</p>
              {childs && (
                <div className="dropdown d-none d-lg-block">
                  <Icon type={"fas"} code={"angle-down"} />
                </div>
              )}
            </Link>
          )
        }
        return null
      })
    } else if (
      submenuItem.level1internal.career !== null &&
      submenuItem.level1internal.career !== undefined
    ) {
      for(let career of careers.allStrapiCareers.nodes){
        if (submenuItem.level1internal.career.id === career.strapiId) {
          customLink = 
            <Link
              className="submenuLink"
              to={`/oferta-academica/${submenuItem.level1internal.career.slug}`}
            >
              <p>{submenuItem.level1internal.name}</p>
              {childs && (
                <div className="dropdown d-none d-lg-block">
                  <Icon type={"fas"} code={"angle-down"} />
                </div>
              )}
            </Link>
            break
        }else{
          customLink = null
        }
      }

    } else {
      customLink = (
        <div className="submenuLink">
          <p>{submenuItem.level1internal.name}</p>
          {childs && (
            <div className="dropdown d-none d-lg-block">
              <Icon type={"fas"} code={"angle-down"} />
            </div>
          )}
        </div>
      )
    }
  } else {
    customLink = (
      <Link className="submenuLink" to={`/${section}/${submenuItem.slug}`}>
        <p>{submenuItem.name}</p>
        {childs && (
          <div className="dropdown d-none d-lg-block">
            <Icon type={"fas"} code={"angle-down"} />
          </div>
        )}
      </Link>
    )
  }
  
  return (
    <>
      {customLink !== null ? (
        <>
          <li>
            <div
              role="button"
              tabIndex="0"
              className="subListItem"
              onMouseEnter={() => setIsShown(active ? isShown : true)}
              onMouseLeave={() => setIsShown(active ? isShown : false)}
            >
              {customLink}
              {childs && (
                <button
                  className={`dropdown  d-lg-none ${active && isShown}`}
                  onClick={() => setIsShown(!isShown && active)}
                >
                  <Icon type={"fas"} code={"angle-down"} />
                </button>
              )}
            </div>
          </li>

          <div
            role="button"
            tabIndex="0"
            onMouseEnter={() => setIsShown(active ? isShown : true)}
            onMouseLeave={() => setIsShown(active ? isShown : false)}
            className={`submenu__level2 ${activeClass}`}
            aria-labelledby={submenuItem?.id}
          >
            {isShown && childs && (
              <ul>
                {submenuItem.name === "Tipo de programa" && programTypes}
                {submenuItem.name === "Programas y Carreras" &&
                  careerStudyClasses}
                {submenuItem.name === "Modalidades" && attendaceModes}
                {submenuPagesL2}
                {submenuItem.level2 !== null &&
                  submenuItem.level2 !== undefined &&
                  submenuL2external}
                {submenuItem.level2 !== null &&
                  submenuItem.level2 !== undefined &&
                  submenuL2internal}
              </ul>
            )}
          </div>
        </>
      ) : null}
    </>
  )
}
export default DropdownMenu
