var dataAcademicOffer = [
  {
    id: 0,
    name: "Tipo de programa",
    menuSection: {},
    slug: '#',
    parent: null
  },
  {
    id: 1,
    name: "Programas y Carreras",
    slug: '#',
    parent: null
  },
  {
    id: 2,
    name: "Modalidades",
    slug: '#',
    parent: null
  },

]

export default dataAcademicOffer
