import { useStaticQuery, graphql } from "gatsby"

const useNews = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiNewsContents(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          newsImage {
            alt
            nameImage {
              id
              name
              url
            }
          }
          slug
          title
          content
          published_at(formatString: "LL", locale: "es-ar")
          publishedAt(formatString: "LL", locale: "es-ar")
          newsTag {
            tagName
          }
          strapiId
          destacado
        }
      }
    }
  `)
  query.allStrapiNewsContents.nodes.forEach(entity => {
    entity.newsTag = entity.newsTag.map(category =>
      category.tagName !== undefined ? category.tagName : category
    )
  })
  return query
}

export default useNews
