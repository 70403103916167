import React from "react"
import GoogleMapReact from "google-map-react"
import { StaticImage } from "gatsby-plugin-image"
import "./_mapSites.scss"

const MapSites = ({ sites, zoom, handleSite }) => {
  const defaultProps = {
    center: {
      lat: -34,
      lng: -64,
    },
    zoom: parseInt(zoom),
  }
  const markers = sites.map(mark => (
    <button
      className="markSites"
      onClick={() => handleSite(mark)}
      key={mark.id}
      lat={mark.latitude}
      lng={mark.longitude}
    >
      {mark.sede === true ? (
          <StaticImage
            src="../../images/house-pin.png"
            quality={100}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="sede"
          />
        ) : (
          <StaticImage
            src="../../images/21-pin.png"
            quality={100}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="centro"
          />
        )}
    </button>
  ))
  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCPjToC-kSDrc0UD7J__LlIY9WWUq9yLNA" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {markers}
      </GoogleMapReact>
    </div>
  )
}

export default MapSites
