import { useEffect } from 'react';

const useLinkTag = url => {
  useEffect(() => {
    const tag = document.createElement('link');
    tag.setAttribute("rel", "stylesheet");
    tag.setAttribute("type", "text/css");
    tag.setAttribute("href", url);

    document.head.appendChild(tag);

    return () => {
      document.head.removeChild(tag);
    }
  }, [url]);
};

export default useLinkTag;