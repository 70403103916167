import React from "react"
import { useSocialMedia } from "../../hooks"

import "./_socialMedia.scss"

const SocialMedia = ({ social_medias }) => {
  const data = useSocialMedia()

  const socialMediaList = data?.allStrapiSocialMedias?.nodes

  const customSocialMediaList = social_medias.map(media =>
    socialMediaList.find(art => art.strapiId === media.id)
  )

  const customSocialMedia = customSocialMediaList.map(item => (
    <div
      className={`socialMediaCard ${(item?.name?.toLowerCase() === "instagram" ||
          item?.name.toLowerCase() === "twitter") &&
        "scroll"
        }`}
      key={item?.id}
      dangerouslySetInnerHTML={{ __html: item?.socialMediaCode }}
    />
  ))
  return (
    customSocialMedia.length > 0 && (
      <div className="socialMediaSection">
        <div className="socialMediaContainer">
          {customSocialMedia}
        </div>
      </div>
    )
  )
}
export default SocialMedia
