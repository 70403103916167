import { BgImage } from "gbimage-bridge"
import React from "react"
import { useBanner } from "../../hooks"
import { getImage } from "gatsby-plugin-image"
import "./_bannerSimple.scss"

const BannerSimple = ({ bannerItems }) => {

    const data = useBanner()
    const allBanners = data?.allStrapiBannerItems?.nodes

    const banner = allBanners.find(it => it.strapiId === bannerItems?.id)
    const image = getImage(banner?.imageDesktop)

    return (
        <div>
            <div><BgImage image={image} className="bgImage my-3"></BgImage></div>

        </div>
    )
}

export default BannerSimple
