import React, { Fragment } from "react"
import { Link } from "gatsby"
import "./_customMenu.scss"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight"
import { InternalLink } from "../index"

const CustomMenu = ({ data, hiddecontact }) => {
  // const pages = usePages()

  const externalLinks = data?.externalLinks.map(link => {
    return (
      <li key={link?.id} className="customMenu__link">
        <a href={`${link?.url}`} target="_blank" rel="noreferrer">{link?.name}</a>
        <FaArrowRight />
      </li>
    )
  })
  
  return (
    <div className="row customMenu">
      <ul>
        <li className="customMenu__link">
          <Link to={`/noticias`}>Novedades</Link>
          <FaArrowRight />
        </li>
        {!hiddecontact && 
        <li className="customMenu__link">
          <Link to={`/contacto`}>Contacto</Link>
          <FaArrowRight />
        </li>
        }
        {externalLinks}
        {data?.internalLinks !== undefined && data?.internalLinks !== null ? 
        <Fragment key={'1'}><InternalLink internalLinks={data.internalLinks} variant={'internalLink__button'}/></Fragment>
        : null}
        {/* {internalLinks} */}
      </ul>
    </div>
  )
}

export default CustomMenu
