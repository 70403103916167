import "./_galleryItem.scss"
import Markdown from "react-markdown"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const GalleryItem = ({ item }) => {
  const img = getImage(item.image)
  const altText = item.title
  return (
    <div to={item.slug} className="flip flip-vertical col-12 col-md-4 galleryItem">
      <div className="front">
        <GatsbyImage image={img} className="galleryItem_img" alt={altText}/>
      </div>
      <div className="back galleryItem_descr markdown">
          <h3>{item.title}</h3>
          <hr />
          <Markdown escapeHtml={true}>{item.description}</Markdown> 
          <Link to={item.slug}>Ver mas</Link>  
      </div>
    </div>
  )
}

export default GalleryItem
