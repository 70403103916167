import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FaUser } from "@react-icons/all-files/fa/FaUser"
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap"
import Markdown from "react-markdown"
import { OneInternalLink } from "../index"

import "./_horizontalCardsList.scss"

const HorizontalCardsList = ({ data, title }) => {
  const cardsListHorizontal = data
    ?.map(card => {
      const image = getImage(card?.image?.nameImage)

      return (
        <div
          className={`personHorizontal pb-5 px-3 px-xl-0 ${
            data.length === 1
              ? "col-12"
              : data.length === 2
              ? "col-lg-5"
              : "col-lg-4"
          }`}
        >
          <div
            className={`personCardHorizontal ${
              data.length === 1
                ? "oneCard"
                : data.length === 2
                ? "twoCards"
                : "threeCards"
            }`}
          >
            <GatsbyImage
              image={image}
              alt={card?.image?.alt}
              className="personCardHorizontal__img"
            />
            <div className="personCardHorizontal__description">
              <div>
                {card?.subtitle && (
                  <p>
                    <b>{card?.subtitle}</b>
                  </p>
                )}
                <p>
                  {card?.subtitle ? (
                    <FaUser size={16} color="#00806e" />
                  ) : (
                    <FaGraduationCap size={16} color="#00806e" />
                  )}
                  <b className="personCardHorizontal__description_title">{` ${card.title}`}</b>
                </p>
                {card?.description && (
                  <div className="markdown personCardHorizontal__description_text">
                    <Markdown escapeHtml={true}>{card.description}</Markdown>
                  </div>
                )}
              </div>

              {card?.internalLink ? (
                <OneInternalLink
                  internalLink={card?.internalLink}
                  variant={`menu`}
                />
              ) : null}
            </div>
          </div>
        </div>
      )
    })
    .slice(0, 3)

  return (
    <div className="container-fluid">
      {title && (
        <div className="row mt-2 mb-3">
          <h2 className="col-12 text-center cardsListHorizontal__title">
            {title}
          </h2>
        </div>
      )}
      <div className="row justify-content-center mt-4">
        {cardsListHorizontal}
      </div>
    </div>
  )
}

export default HorizontalCardsList
