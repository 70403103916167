import React, { useState, useEffect } from "react"
import { useEvents, useEventsPage } from "../../hooks"
import * as jp from "jsonpath"
import "./_events.scss"
import {
  Seo,
  HeaderPage,
  CustomSection,
  EventSearchInput,
  CarouselEvents,
  Pagination,
  EventsCard,
  EventsCheckBoxFilters,
  Calendar,
} from "../index"
import { makeQuery } from "../../functions/event.functions"

let searchField = []

const Events = () => {
  const data = useEvents()
  const eventsArray = data?.allStrapiEvents
  const dataEventPage = useEventsPage()
  const page = dataEventPage?.allStrapiEventPage?.nodes[0]
  const sections = page?.sections
  const eventsDestacados = page?.eventsDestacados?.events

  const [query, setQuery] = useState("$..nodes[*]")
  const [results, setResults] = useState([])
  const [inicialPage, setInicialPage] = useState(false)

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  //Estado para el calendario
  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)

  useEffect(() => {
    setResults(jp.query(eventsArray, query))
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const nextMonth = () => {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  const lastMonth = () => {
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }

  // Funciones para el manejo de filtrado
  const checkboxPress = (e, idType) => {
    if (e.target.checked) {
      searchField.push({
        type: idType,
        value: e.target.value,
      })
    } else {
      for (var i = 0; i < searchField.length; i++) {
        if (
          searchField[i].value === e.target.value &&
          searchField[i].type === idType
        ) {
          searchField.splice(i, 1)
        }
      }
    }
    setInicialPage(true)
    createQuery()
  }

  const handleKeyPress = textInput => {
    for (var i = 0; i < searchField.length; i++) {
      if (searchField[i].type === 3) {
        searchField.splice(i, 1)
        break
      }
    }

    if (textInput.length >= 3) {
      searchField.push({
        type: 3,
        value: textInput,
      })
      setInicialPage(true)
    } else {
      setInicialPage(false)
    }

    createQuery()
  }

  const calendarDayPress = date => {
    searchField.push({
      type: 4,
      value: date,
    })
    setInicialPage(true)
    createQuery()
  }

  function createQuery() {
    const querySearch = makeQuery(searchField)
    setQuery(querySearch)
  }

  // Eventos para el calendario
  const eventsCalendar = eventsArray?.nodes.map(eventCalendar => ({
    title: eventCalendar.title,
    date: eventCalendar.date,
  }))

  for (let i = 0; i < results.length; i++) {
    if (eventsDestacados?.find(element => element.id === results[i].strapiId) !== undefined) {
      results[i].destacado = true
    }
  }

  const eventsList = results.map(event => {
    return (<EventsCard
      key={event?.strapiId}
      date={event?.formatDateMonth}
      dateDay={event?.formatDateDay}
      title={event?.title}
      time={event?.time}
      summary={event?.summary}
      slug={event?.slug}
      img={event?.eventImage?.nameImage}
      destacado={event?.destacado}
    />)
  })

  let SEO = ""
  if (page.eventsMetadata !== null && page.eventsMetadata !== undefined && page.eventsMetadata.pageTitle !== null) {
    const { description, keywords, pageTitle } = page.eventsMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = (
      <Seo
        title={page.title}
      />
    )
  }
  const headerPage = <HeaderPage title={page.title} summary={page.subTitle} pageUrl="/eventos" paginaName={["Eventos"]}/>
  return (
    <>
      {SEO}
      <div className="headline headline--news">{headerPage}</div>
      <div className="container pt-5">
        <div className="row mx-0">
          <div className="col-12 col-lg-3">
            <div>Encontrar eventos</div>
            <EventSearchInput onQuery={handleKeyPress}  />

            <Calendar
              month={month}
              year={year}
              nextMonth={nextMonth}
              lastMonth={lastMonth}
              events={eventsCalendar}
              action={calendarDayPress}
            />
            <EventsCheckBoxFilters onQuery={checkboxPress} />
          </div>
          <div className="col-12 col-lg-9">
            <div id="Posts" className="container mb-5 px-0">
              {eventsDestacados !== undefined && eventsDestacados !== null ? (
                <CarouselEvents
                  className="row justify-content-center mx-0"
                  events={eventsDestacados}
                />
              ) : null}
            </div>
            <Pagination
              posts={eventsList}
              postPerPage={8}
              inicialState={inicialPage}
            />
          </div>
        </div>
      </div>
      <CustomSection sections={sections} />
    </>
  )
}

export default Events