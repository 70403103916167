import React from "react"
import "./_eventsCard.scss"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { Link } from "gatsby"

const EventsCard = ({
    date,
    dateDay,
    title,
    time,
    summary,
    slug,
    img,
    destacado
}) => {
    const image = getImage(img)

    const eventDestacado = destacado && (
        <p>
          <span>Destacado</span>
        </p>
      )

    return (
        // <div className={`col-12 col-md-4 col-lg-${cols} mb-sm-3 p-0`}>
        <div className={`col-12 col-md-4 mb-3 p-1`}>
            <div className="card">
                <BgImage image={image} className={`card-bgImage`}>
                    {eventDestacado}
                </BgImage>
                {/* <BgImage image={image} className={`card__bgImage`} /> */}
                <div className="card-body-events">
                    <div>
                        <div className={'card__month'}>
                            {date.toUpperCase().substring(0, 3) + '.'}
                        </div>
                        <div className={'card__day'}>
                            {dateDay}
                        </div>
                    </div>
                    <div className={'card__text'}>
                        <Link to={`/eventos/${slug}`} className="card-title">
                            {title}
                        </Link>
                        <p className={'card__hour'}>{time.substring(0, time.length - 3) + ' hs.'}</p>
                        <p className={'card__summary'}>{summary}</p>
                    </div>
                </div>
                <Link to={`/eventos/${slug}`} className="card-hover"></Link>
            </div>
        </div>
    )
}

export default EventsCard
