import { useStaticQuery, graphql } from "gatsby"

const useSites = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiSites {
        nodes {
          CP
          apartment
          city
          code
          country
          email
          fax
          floor
          id
          latitude
          longitude
          name
          neighborhood
          observation
          phone1
          phone2
          province
          street
          streetNumber
          tower
          slug
          IDsite
          sede
        }
      }
    }
  `)
  return query
}

export default useSites
