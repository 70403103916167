import { useStaticQuery, graphql } from "gatsby"

const useAcademicOfferMain = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiAcademicOfferMain {
        nodes {
          title
          summary
          section {
            fields {
              enable
              title
              summary
              anchor
              theme
            }
            blog_articles {
              id
            }
            testimonies {
              id
            }
            news_contents {
              id
            }
            videos {
              id
            }
            form {
              id
              Name
              PortalID
              formId
              formType
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            careers {
              id
            }
            banner_item {
              id
            }
          }
          whatsappFlotante {
            id
            name
            type
            url
          }
          imageAttendanceModes {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageStudyClasses {
            childImageSharp {
              gatsbyImageData
            }
          }
          academicOfferMainSeo {
            pageDescription
            pageKeywords
            pageTitle
          }
          titleStudyClasses
          titleAttendanceModes
        }
      }
    }
  `)
  return query
}
export default useAcademicOfferMain
