import React from "react"
import "./_childPageCard.scss"
import { Link } from "gatsby"
import { Button } from "../index"

const ChildPageCard = ({ key, title, summary, slug, nameParent }) => {

  return (
    <div className="col-12 col-md-6 mb-sm-1 p-2">
      <div className="childPageCard ">
        <div className="childPageCard__text col-8">
          <p className="childPageCard__text__parent">{nameParent}</p>
          <Link to={slug} className="card-title">
            {title}
          </Link>
          <p className="childPageCard__text__summary">{summary}</p>
        </div>
        <div className="childPageCard__button col-4">
          <Button content={"Ver más"} variant={"button-yellow"} path={slug} />
        </div>
      </div>
    </div>
  )
}

export default ChildPageCard
