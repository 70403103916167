import React from "react"
import { Sites, Seo } from "../components"
import Layout from "../templates/layout.template"

const SitesPage = () => (
  <Layout>
    <Seo title={"Sedes y Centros"} />
    <Sites />
  </Layout>
)

export default SitesPage
