import React from "react"
import { Link } from "gatsby"
import { BgImage } from "gbimage-bridge"
import "./_academicOfferCard.scss"


const AcademicOfferCard = ({ card, type, id, image }) => {
    const links = card.map(card =>
        <div className="cardFilter__div" key={card.id}>
            <Link
                to={'/oferta-academica-filtro'}
                state={{ id: id, value: card.name }}
            >
                {card.name}{' >'}
            </Link>
        </div>
    )

    const img = image !== '' ?
        <BgImage
            image={image}
            className="cardFilter__bgImage"
            alt={`image-${id}`}
        >
        </BgImage>
        : ''

    return (
        <div className="card cardFilter">
            {/* <GatsbyImage image={image} /> */}
            {img}
            <div className="card-body programs">
                <p>{type}</p>
                {links}
            </div>
        </div>

    )
}
export default AcademicOfferCard
