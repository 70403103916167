import React from "react"
import "./_embebedMap.scss"

const EmbebedMap = ({ source }) => {

    return (
        <div className="embebedMap">
            <iframe
                loading="lazy"
                src={source}
                title="embebedMap"
            >
            </iframe>
        </div>
    )
}

export default EmbebedMap