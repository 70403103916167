import { useStaticQuery, graphql } from "gatsby"

const useCareerStudyClass = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiCareerStudyClasses(sort: {fields: order, order: ASC}) {
        nodes {
          id
          code
          name
          state
          icon {
            code
            type
            id
          }
          order
        }
      }
    }
  `)
  return query
}

export default useCareerStudyClass
