import React from "react"
import { useNews } from "../../hooks"
import { Button, NewsCard } from "../index"
import "./_newsSection.scss"

const NewsSection = ({ news, latest, theme, title, anchor }) => {
  const data = useNews()

  const newsList = data?.allStrapiNewsContents?.nodes

  const customNewsList = news.map(article =>
    newsList.find(art => art.strapiId === article.id)
  )

  const latestNews = newsList
    .map(article => (
      <NewsCard
        key={article?.id}
        newsImage={article?.newsImage?.nameImage}
        title={article?.title}
        alt={article?.newsImage?.alt}
        content={article?.content}
        published_at={article?.publishedAt}
        newsTag={article?.newsTag}
        slug={article?.slug}
        cols={3}
      />
    ))
    .slice(0, 4)

  const customNews = customNewsList
    .map(article => (
      <NewsCard
        key={article?.id}
        newsImage={article?.newsImage?.nameImage}
        title={article?.title}
        alt={article?.newsImage?.alt}
        content={article?.content}
        published_at={article?.publishedAt}
        newsTag={article?.newsTag}
        slug={article?.slug}
        cols={3}
      />
    ))
    .slice(0, 4)

  const newsToDisplay = latest ? latestNews : customNews
  const btnTheme = theme === "dark" ? "light" : "green"

  return (
    <div id={`${anchor}`} className={`container-fluid newsSection ${theme}-theme`}>
      <div className="container">
        <div className="row mt-2 mb-3">
          <h2 className="col-12 text-center">{title}</h2>
        </div>
        <div className="row justify-content-center">{newsToDisplay}</div>
        <div className="row justify-content-center py-5">
          <Button
            content={" Ver más noticias "}
            path={`/noticias`}
            variant={`button-${btnTheme}`}
          />
        </div>
      </div>
    </div>
  )
}

export default NewsSection
