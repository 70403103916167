import React, { useState } from "react"
import { AcademicOfferFilter } from "../components"
import Layout from "../templates/layout.template"

const Academic = ({ location }) => {

  const [filterMenu, setFilterMenu] = useState(undefined)

  const handleLocation = (value, id) => {
    setFilterMenu({ id: id, value: value })
  }

  return (
    <Layout handleLocation={handleLocation} whatsapp={true}>
      <AcademicOfferFilter filter={location} filterMenu={filterMenu} />
    </Layout>
  )
}

export default Academic
