import { useStaticQuery, graphql } from "gatsby"

const useEventsPage = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiEventPage {
        nodes {
          sections {
            fields {
              enable
              title
              anchor
              theme
            }
            latest
            type
            blog_articles {
              id
            }
            careers {
              id
            }
            news_contents {
              id
            }
            testimonies {
              id
            }
            form {
              id
              formId
              PortalID
              Name
              formType
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            careers {
              id
            }
            banner_item {
              id
            }
          }
          subTitle
          title
          strapiId
          eventsMetadata {
            pageDescription
            pageKeywords
            pageTitle
          }
          eventsDestacados {
            events {
              id
            }
          }
        }
      }
    }
  `)
  return query
}

export default useEventsPage