import React from "react"
import "./_video.scss"
import { VideoYoutube } from "../index"

const Video = ({ data, variant, cols }) => {

    return (
        <VideoYoutube key={data?.id} data={data} variant={variant} cols={cols} />
    )
}
export default Video
