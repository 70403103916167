import { useStaticQuery, graphql } from "gatsby"

const useBlogArticles = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiBlogArticles(
        sort: { fields: [publishedAt, destacado], order: [DESC] }
      ) {
        nodes {
          strapiId
          id
          blog_categories {
            name
          }
          title
          blogImage {
            alt
            nameImage {
              id
              name
              url
            }
          }
          publishedAt(formatString: "LL", locale: "es-ar")
          slug
          destacado
          description
        }
      }
    }
  `)
  query.allStrapiBlogArticles.nodes.forEach(entity => {
    entity.blog_categories = entity.blog_categories.map(category =>
      category.name !== undefined ? category.name : category
    )
  })
  return query
}

export default useBlogArticles
