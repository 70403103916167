import React from "react"
import { Icon } from ".."
import { Link } from "gatsby"
import { Popover, OverlayTrigger, Button } from "react-bootstrap"
const mapMarker = ({ text, observation, slug }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title>
        <h5>{text}</h5>
      </Popover.Title>
      <Popover.Content>
        <p>{observation}</p>
        {slug && <Link to={`./${slug}`}>
          Contáctanos
        </Link>}
      </Popover.Content>
    </Popover>
  )
  const marker = (
    <div className="d-flex mt-2">
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Button variant="button-outline">
          <Icon
            className="map__marker__icon"
            type="fas"
            code="map-marker-alt"
          />
        </Button>
      </OverlayTrigger>
    </div>
  )

  return (
    <div className="map__marker">
      {marker}
      {/* <p className="map__marker__text">{text}</p> */}
    </div>
  )
}

export default mapMarker
