import React from "react"
import {
  BlogSection,
  Testimony,
  NewsSections,
  RelatedPrograms,
  VideoBlock,
  ChildPagesSection,
  Metrics,
  SocialMedia,
  ContactForm,
  EventSection,
  BenefitsVideo,
  BannerSimple,
  SitesBlock,
  SearchCareer,
} from "../"

const CustomSection = ({ sections, pageId, formFields }) => {
  const section = sections.map((section, index) => {

    return (
      <>
        {section.careers !== null && section.careers !== undefined ? (
          section.fields?.enable ? (
            <RelatedPrograms
              key={index}
              careers={section.careers}
              title={section.fields.title}
              theme={section.fields.theme}
            />
          ) : null
        ) : null}

        {section.testimonies !== null && section.testimonies !== undefined ? (
          section.fields?.enable ? (
            <Testimony
              key={index}
              testimony={section.testimonies}
              latest={section.latest}
              theme={section.fields.theme}
              title={section.fields.title}
            />
          ) : null
        ) : null}

        {section.blog_articles !== null &&
        section.blog_articles !== undefined ? (
          section.fields?.enable ? (
            <BlogSection
              key={index}
              blog={section.blog_articles}
              latest={section.latest}
              theme={section.fields.theme}
              title={section.fields.title}
              anchor={section.fields.anchor}
            />
          ) : null
        ) : null}

        {section.videos !== null && section.videos !== undefined ? (
          section.fields?.enable ? (
            <VideoBlock
              key={index}
              video={section.videos}
              theme={section.fields.theme}
              title={section.fields.title}
            />
          ) : null
        ) : null}

        {(section.news_contents) !== null &&
        (section.news_contents) !== undefined ? (
          section.fields?.enable ? (
            <NewsSections
              key={index}
              news={section.news_contents}
              latest={section.latest}
              theme={section.fields.theme}
              title={section.fields.title}
              anchor={section.fields.anchor}
            />
          ) : null
        ) : null}

        {section.type === "childPages" ? (
          section.fields?.enable ? (
            <ChildPagesSection
              pageId={pageId}
              key={index}
              title={section.fields.title}
              theme={section.fields.theme}
            />
          ) : null
        ) : null}

        {(section.metrics) !== null &&
        (section.metrics) !== undefined ? (
          section.fields?.enable ? (
            <Metrics
              metrics={section.metrics}
              title={section.fields.title}
              theme={section.fields.theme}
            />
          ) : null
        ) : null}

        {(section.social_medias) !== null &&
        (section.social_medias) !== undefined ? (
          section.fields?.enable ? (
            <SocialMedia social_medias={section.social_medias} />
          ) : null
        ) : null}

        {(section.form) !== null &&
        (section.form) !== undefined ? (
          section.fields?.enable ? (
            <ContactForm
              form={section.form}
              theme={section.fields.theme}
              anchor={section.fields.anchor}
              title={section.fields.title}
              formFields={formFields}
            />
          ) : null
        ) : null}

        {(section.events) !== null &&
        (section.events) !== undefined ? (
          section.fields?.enable ? (
            <EventSection
              events={section.events}
              title={section.fields.title}
              theme={section.fields.theme}
              anchor={section.fields.anchor}
              latest={section.latest}
            />
          ) : null
        ) : null}

        {section?.fields?.enable &&
          section.type === "benefitsVideo" &&
          (section.fields?.enable ? <BenefitsVideo data={section} /> : null)}

        {section?.fields?.enable &&
          section.type === "sede" &&
          (section.fields?.enable ? (
            <SitesBlock
              title={section.fields.title}
              theme={section.fields.theme}
              anchor={section.fields.anchor}
            />
          ) : null)}

        {section?.banner_item !== undefined &&
          section?.banner_item !== null && (
            <BannerSimple bannerItems={section?.banner_item} />
          )}

        {section?.fields?.enable &&
          section.type === "searchCareer" &&
          (section.fields?.enable ? (
            <SearchCareer
              title={section.fields.title}
              theme={section.fields.theme}
              anchor={section.fields.anchor}
            />
          ) : null)}
      </>
    )
  })

  return <div>{section}</div>
}

export default CustomSection
