import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./layout.template"
import {
  HeaderPage,
  Seo,
  Content,
  AsideNews,
  CustomSection,
} from "../components"
import "./_pageNews.scss"

const pageNews = ({ data }) => {
  const page = data.strapiNewsContents
  let SEO = ""

  if (
    page.pageMetadata !== null &&
    page.pageMetadata !== undefined &&
    page.pageMetadata.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = <Seo title={`${page.title}`} />
  }
  const headerPage = (
    <HeaderPage
      title={page.title}
      subtitle={page.newsTag[0]?.tagName}
      summary={page.publishedAt}
      image={page.newsImage}
      video={page.video}
      slider={page.slider}
      pageUrl={`/noticias/${page.slug}`}
      paginaName={["Noticias", page.title]}
    />
  )
  const content =
    page.sections.length !== 0 ? (
      <Content content={page.sections} />
    ) : (
      <div className="col-12 col-md-7"></div>
    )
  const aside =
    page.relatedNews.length !== 0 ? (
      <AsideNews
        content={page.relatedNews}
        title="Noticias relacionadas"
        type="noticias"
      />
    ) : null
  const tags = page.newsTag.map(tag => (
    <Link
      key={tag.id}
      to={"/noticias"}
      state={{ id: tag.id, value: tag.tagName }}
      className="tags__link btn btn-light"
    >
      {tag.tagName}
    </Link>
  ))
  return (
    <Layout>
      {SEO}
      <div className="headline headline--news">{headerPage}</div>
      <div className="container PageNews">
        <div className="row Page__row">
          {content}
          {aside}
        </div>
        <div className="tags">
          <h5 className="tags__title">Tags</h5>
          {tags}
        </div>
      </div>
      <CustomSection sections={page.sections} />
    </Layout>
  )
}

export default pageNews

export const query = graphql`
  query PageNews($slug: String!) {
    strapiNewsContents(slug: { eq: $slug }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      id
      title
      newsTag {
        tagName
        id
      }
      publishedAt(formatString: "LL", locale: "es-ar")
      newsImage {
        nameImage {
          id
          name
          url
        }
      }
      slider {
        id
        type
        imageSlider {
          id
          alt
          nameImage {
            id
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      video {
        id
        nameVideo
        type
        urlVideo
      }
      relatedNews {
        id
        title
        slug
        publishedAt(formatString: "LL", locale: "es-ar")
      }
      sections {
        fields {
          anchor
          enable
          summary
          theme
          title
        }
        id
        paragraph
        icon {
          name
          icon {
            type
            code
          }
        }
        image {
          alt
          id
          title
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          urlVideo
        }
        type
        latest
        events {
          date
          event_category
          id
          summary
          time
          title
        }
        banner_item {
          id
        }
        news_contents {
          id
        }
        blog_articles {
          id
        }
        testimonies {
          id
        }
        form {
          id
          formId
          PortalID
          Name
          formType
        }
        videos {
          id
        }
        careers {
          id
        }
        externalSrc {
          url
          name
          type
          id
        }
        internalSrc {
          singlePage
          name
          career {
            slug
            id
            name
          }
          page {
            id
            slug
            title
          }
        }
      }
    }
  }
`
