import { useStaticQuery, graphql } from "gatsby"

const useEventTypes = () => {
    const query = useStaticQuery(graphql`
    query {
        allStrapiEventTypes {
            nodes {
              name
              id
              slug
            }
          }
    }
  `)
    return query
}

export default useEventTypes