import * as React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ProfileCard = ({
  alt,
  variant,
  profilePicture,
  testimony,
  name,
  role,
  cols,
}) => {
  const image = getImage(profilePicture)
  const columnsMetric =
    variant === "metric" ? "col-12 col-md-8 box" : "col-8 texto"

  const onlyTestimony =
    variant === "testimony" && cols === 12
      ? ` ${variant} one-testimony `
      : ` col-lg-${cols} ${variant} `

  return (
    <div className={`col-12  ${onlyTestimony}`}>
      <div className="col-4 px-0 image ">
        <GatsbyImage image={image} alt={alt} />
      </div>
      <div className={columnsMetric}>
        <p className="info">{testimony}</p>
        <p className="author ">{name}</p>
        <p className="role">{role}</p>
      </div>
    </div>
  )
}

export default ProfileCard
