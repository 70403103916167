import * as React from "react"

const LoadButton = ({ content, variant, click, disabled }) => {
  const button = disabled ? (
    <button className={`button-disabled`}>{content}</button>
  ) : (
    <button className={`${variant}`} onClick={click}>
      {/* Si se coloca un ícono, darle margen izquierdo o derecho con style-inline */}
      {content}
    </button>
  )

  return button
}

export default LoadButton
