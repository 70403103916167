import React from "react"
import { useExternalServiceLinks, usePages } from "../../hooks"
import ExternalServiceLink from "./externalServiceLink.component"

const ExternalServices = () => {
  const data = useExternalServiceLinks()
  const pages = usePages()
  
  const links = data?.allStrapiExternalServiceLinks?.nodes.map(link => {
    let internalRoute = null
    if (link.internalLink !== null) {
      pages?.allStrapiPages?.nodes.map((page) => {
        if (page.strapiId === link.internalLink.page.id) {
            const slugSection =
            page.menuSection !== null ? `/${page.menuSection.slug}` : ""
            const slugParent =
            page.strapiParent !== null ? `/${page.strapiParent.slug}` : "" 
            internalRoute = `${slugSection}${slugParent}/${link?.internalLink?.page?.slug}`
        }
        return internalRoute
      })
    }
    return (
    <ExternalServiceLink
      key={link?.id}
      image={link?.image}
      icon={link?.icon}
      name={link?.name}
      theme={link?.theme}
      externalLink={link?.externalLink}
      internalLink={link?.internalLink}
      internalLinkRoute = {internalRoute}
    />
  )})

  return (
    <div className="login">
      <div className="container px-0 py-5">{links}</div>
    </div>
  )
}
export default ExternalServices
