import React from "react";
import GoogleMapReact from 'google-map-react';
import { MapMarker } from "..";
import "./_map.scss"

const Map = ({location, zoom}) => {
  const defaultProps = {
    center: {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude)
    },
    zoom: parseInt(zoom)
  };

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCPjToC-kSDrc0UD7J__LlIY9WWUq9yLNA" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapMarker
          lat={location.latitude}
          lng={location.longitude}
          text={location.name}
          observation={location.observation}
        />
      </GoogleMapReact>
    </div>
  );
}

export default Map