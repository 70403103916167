import React, { useEffect, useState } from "react"

import { useCareerStudyClass, useProgramTypes, useCareers } from "../../hooks"
import { SelectOptions, Icon } from "../index"
import * as jp from "jsonpath"
import "./_searchCareer.scss"
import { Link } from "gatsby"


const SearchCareer = ({ title, theme, anchor }) => {
    const studyClassesData = useProgramTypes()
    const careerStudyClassData = useCareerStudyClass()
    const careers = useCareers()

    const [query, setQuery] = useState("$..nodes[-1]")
    const [results, setResults] = useState([])
    const [careerStudyClass, SetCareerStudyClass] = useState("")
    const [studyClass, SetStudyClass] = useState("")
    const [career, SetCareer] = useState("")

    useEffect(() => {
        setResults(jp.query(careers.allStrapiCareers, query))
    }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (studyClass !== "" && careerStudyClass !== "") {
            SetCareer("")
            setQuery(`$..nodes[?(@.career_study_class.name=='${careerStudyClass}' && @.study_class.name=='${studyClass}')]`)
            
        } else {
            SetCareer("")
            setQuery("$..nodes[-1]")
            
        }
    }, [careerStudyClass]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (studyClass !== "" && careerStudyClass !== "") {
            setQuery(`$..nodes[?(@.career_study_class.name=='${careerStudyClass}' && @.study_class.name=='${studyClass}')]`)
            SetCareer("")
        } else {
            setQuery("$..nodes[-1]")
            SetCareer("")
        }
    }, [studyClass]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSelectStudyClass = (event) => {
        SetStudyClass(event.target.value)
    }

    const onChangeSelectCareerStudyClass = (event) => {
        SetCareerStudyClass(event.target.value)
    }


    const onChangeSelectCareer = (event) => {
        SetCareer(event.target.value)
    }

    return (
        <div id={`${anchor}`} className={`container-fluid searchCareerSection ${theme}-theme`}>
            <div className="container w-100 d-flex flex-column align-items-center">
                <div className="row mt-2 mb-3">
                    <h2 className="col-12 text-center">{title}</h2>
                </div>
                <div className="row w-100 mt-2 mb-3 justify-content-center">
                    <div className="col-12 col-md-4">
                        <div className="searchCareerSection__header">
                            <div className="searchCareerSection__header__circle">
                                {studyClass === "" ? <p>1</p> : <Icon type="fas" code="check" />}
                            </div>
                            {studyClass === "" ? <p className="searchCareerSection__header__title">Seleccionar tipo de programa</p> : <p className="searchCareerSection__header__title active">Seleccionar tipo de programa</p>}

                            <div className="searchCareerSection__header__line"></div>

                        </div>

                        <SelectOptions
                            data={studyClassesData.allStrapiStudyClasses.nodes}
                            name="studyClasses"
                            onChangeSelect={onChangeSelectStudyClass}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="searchCareerSection__header">
                            <div className="searchCareerSection__header__circle">
                                {careerStudyClass === "" ? <p>2</p> : <Icon type="fas" code="check" />}
                            </div>
                            {careerStudyClass === "" ? <p className="searchCareerSection__header__title">Seleccionar área de estudio</p> : <p className="searchCareerSection__header__title active">Seleccionar área de estudio</p>}

                            <div className="searchCareerSection__header__line"></div>

                        </div>
                        <SelectOptions
                            data={careerStudyClassData.allStrapiCareerStudyClasses.nodes}
                            name="careerStudyClass"
                            onChangeSelect={onChangeSelectCareerStudyClass}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="searchCareerSection__header">
                            <div className="searchCareerSection__header__circle">
                                {career === "" ? <p>3</p> : <Icon type="fas" code="check" />}
                            </div>
                            {career === "" ? <p className="searchCareerSection__header__title">Seleccionar carrera</p> : <p className="searchCareerSection__header__title active">Seleccionar carrera</p>}
                            <div className="searchCareerSection__header__line"></div>
                        </div>
                        <SelectOptions
                            data={results}
                            name="careers"
                            onChangeSelect={onChangeSelectCareer}
                        />
                    </div>

                </div>
                <div className="row searchCareerSection__button w-100">
                    <div className="col-12 col-md-2">
                        <Link
                            // content={"Buscar"}
                            to={`/oferta-academica/${career}/#form`}
                            className={`button-yellow justify-content-center ${career === "" ? 'disabled-link' : ''} `}
                            // variant={"button-yellow h-20 w-100 justify-content-center"}
                        >Continuar</Link>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default SearchCareer