import { useStaticQuery, graphql } from "gatsby"
const useBlogPage = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiBlogPage {
        nodes {
          blogMetadata {
            description: pageDescription
            keywords: pageKeywords
            pageTitle
          }
          title
          subTitle
          blogsDestacados {
            blog_articles {
              id
            }
          }
          sections {
            fields {
              summary
              theme
              title
              enable
              anchor
              id
            }
            testimonies {
              id
            }
            videos {
              id
            }
            blog_articles {
              id
            }
            form {
              id
              Name
              PortalID
              formId
              formType
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            careers {
              id
            }
            banner_item {
              id
            }
            latest
            news_contents {
              id
            }
          }
        }
      }
    }
  `)
  return query
}
export default useBlogPage