import { useStaticQuery, graphql } from "gatsby"

const useAcademicOffer = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiAcademicOffer {
        nodes {
          title
          summary
          placeholder
          sections {
            fields {
              enable
              title
              summary
              anchor
              theme
            }
            latest
            blog_articles {
              id
            }
            testimonies {
              id
            }
            news_contents {
              id
            }
            videos {
              id
            }
            form {
              id
              Name
              PortalID
              formId
              formType
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            careers {
              id
            }
            banner_item {
              id
            }
          }
          academicOfferSeo {
            pageDescription
            pageKeywords
            pageTitle
          }
          whatsappFlotante {
            id
            name
            type
            url
          }
        }
      }
    }
  `)
  return query
}
export default useAcademicOffer
