import React, { useState, useEffect } from "react"
import { ArticleSearchInput, Button, HeaderPage, MapSites, Icon } from ".."
import { StaticImage } from "gatsby-plugin-image"
import { useSites } from "../../hooks"
import * as jp from "jsonpath"
import "./_sites.scss"

let searchField = []

const Sites = () => {
  const sites = useSites()
  const sitesArray = sites.allStrapiSites
  const [isActive, setIsActive] = useState(false)
  const [activeSite, setActiveSite] = useState({})
  const [query, setQuery] = useState("$..nodes[*]")
  const [results, setResults] = useState([])
  const [activeButton, setActiveButton] = useState([false, false])
  const [listActive, setListActive] = useState(false);
  searchField = []

  useEffect(() => {
    setResults(jp.query(sitesArray, query))
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const classActive = [
    activeButton[0] ? "active" : "",
    activeButton[1] ? "active" : "",
  ]
  const handleSearch = textInput => {
    for (var i = 0; i < searchField.length; i++) {
      if (searchField[i].type === 2) {
        searchField.splice(i, 1)
        break
      }
    }
    if (textInput.length >= 3) {
      searchField.push({
        type: 2,
        value: textInput,
      })
    }
    makeQuery()
  }

  const handleSearchButton = type => {
    const valueType = type === 0 ? 1 : 0
    if (type === 0) {
      setActiveButton([!activeButton[type], false])
    } else {
      setActiveButton([false, !activeButton[type]])
    }
    let exist = false
    for (var i = 0; i < searchField.length; i++) {
      if (searchField[i].type === type) {
        exist = true
        searchField.splice(i, 1)
        break
      } else if (valueType === searchField[i].type) {
        searchField.splice(i, 1)
      }
    }
    if (!exist) {
      searchField.push({
        type: type,
        value: "",
      })
    }
    makeQuery()
  }

  const makeQuery = () => {
    let querySearch = ""
    if (searchField.length > 0) {
      setListActive(true)
      let sede = ""
      let center = ""
      let nameSearch = ""
      querySearch = "$..nodes[?("

      searchField.forEach(element => {
        switch (element.type) {
          case 0:
            sede = "@.sede == true"
            break
          case 1:
            center = "@.sede == false"
            break
          case 2:
            nameSearch =
              "@.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes('" +
              element.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() +
              "')"
            break
          default:
            break
        }
      })

      querySearch = querySearch + (sede === "" ? "" : "(" + sede + ")")
      querySearch =
        querySearch +
        (center === "" ? "" : (sede === "" ? "" : "&&") + "(" + center + ")")

      querySearch =
        querySearch +
        (nameSearch === ""
          ? ""
          : (sede !== "" || center !== "" ? " && " : "") +
            "(" +
            nameSearch +
            ")")
      querySearch = querySearch + ")]"
    } else {
      querySearch = "$..nodes[*]"
      setListActive(false)
    }
    setQuery(querySearch)
  }

  const headerPage = (
    <HeaderPage title={"Sedes y Centros"} pageUrl="/sedes-y-centros" paginaName={["Sedes y Centros"]} />
  )

  const openSite = site => {
    setActiveSite(site)
    setIsActive(true)
    handleSearch(site.name)
  }
  const closeSite = () => {
    setIsActive(false)
    handleSearch("")
  }
  const listSites = results.map(site => (
    <button
      className="cardSite"
      key={site.id}
      onClick={() => openSite(site)}
    >
      <div className="cardSite__image">
        {site.sede === true ? (
          <StaticImage
            src="../../images/house-pin.png"
            quality={100}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="sede"
          />
        ) : (
          <StaticImage
            src="../../images/21-pin.png"
            quality={100}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="centro"
          />
        )}
      </div>
      <div className="cardSite__text">
        <h5>{site.name}</h5>
        <div>
        {site.street !== null && site.street !== '' && <span>{site.street}</span> }
        {site.streetNumber !== null && site.streetNumber !== '' && <span>, {site.streetNumber}</span>}
        </div>
        
        
      </div>
    </button>
  ))
  return (
    <div>
      <div className="headline headline--news">{headerPage}</div>
      <div className="mapContent">
        <div className="container mapContent__search">
          <div className="box-search">
            {!isActive && (
              <div>
                <ArticleSearchInput onQuery={handleSearch} placeholder={"Ingresa una dirección, ciudad o nombre "}></ArticleSearchInput>
                <div className="box-search__actions">
                  <button
                    type="button"
                    className={`button-search ${classActive[0]}`}
                    onClick={() => {
                      handleSearchButton(0)
                    }}
                  >
                    Sedes <Icon type={"fas"} code={"times"} />
                  </button>
                  <button
                    type="button"
                    className={`button-search ml-2 ${classActive[1]}`}
                    onClick={() => handleSearchButton(1)}
                  >
                    Centros <Icon type={"fas"} code={"times"} />
                  </button>
                </div>
                { listActive && <div className="box-search__list">{listSites}</div>}
              </div>
            )}

            {isActive && (
              <div className="box-search__activeItem">
                <button
                  className="activeItem_button"
                  onClick={() => closeSite()}
                >
                  <Icon type={"fas"} code={"arrow-left"} />
                </button>
                <h5>{activeSite.name}</h5>
                <p>
                  <b>Dirección</b>
                </p>
                <p>
                  {activeSite.street !== null && activeSite.street !== '' && <span>{activeSite.street}</span>}
                  {activeSite.streetNumber !== null && activeSite.streetNumber !== '' && <span>, {activeSite.streetNumber}</span>}
                </p>

                <p>
                  <b>Contacto</b>
                </p>
                <p>{activeSite.phone1}</p>
                <p>
                  <b>Horario de Atención</b>
                </p>
                <p>{activeSite.observation}</p>
                <Button
                  content={"Contáctanos"}
                  path={`./${activeSite.slug}`}
                  variant={"button-green mt-2"}
                ></Button>
              </div>
            )}
          </div>
        </div>
        <MapSites sites={results} zoom={"5"} handleSite={openSite} />
      </div>
    </div>
  )
}

export default Sites
