import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout.template"
import {
  Aside,
  HeaderPage,
  Content,
  NavegationContent,
  TabComponent,
  CustomSection,
  Seo,
} from "../components"

import "./_page.scss"

const PageTemplate = ({ data, pageContext }) => {
  const page = data.strapiPages

  let SEO = ""

  if (
    page.pageMetadata !== null &&
    page.pageMetadata !== undefined &&
    page.pageMetadata.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = <Seo title={`${page.title}`} />
  }
  const pageSectionLink =
    page.menuSection === null ||
      (page.menuSection !== null && page.menuSection.link)
      ? true
      : false
  const headerPage = (
    <HeaderPage
      title={page.title}
      summary={page.summary}
      image={page.image}
      video={page.video}
      slider={page.slider}
      pageUrl={pageContext?.url}
      pageSectionLink={pageSectionLink}
      paginaName={pageContext?.paginaName}
    />
  )

  const navegation =
    page.navegation.length !== 0 ? (
      <NavegationContent
        content={page.navegation}
        id={page?.strapiId}
        name={page.name}
      />
    ) : null

  const content =
    page.content.length !== 0 ? (
      <Content content={page.content} />
    ) : (
      <div className="col-12 col-md-7"></div>
    )

  const tabs = page.tabs?.length !== 0 ? <TabComponent content={page.tabs} /> : null

  const aside = page.aside?.length !== 0 ? <Aside content={page.aside} /> : null

  return (
    <Layout>
      {SEO}
      <div className="headline">{headerPage}</div>
      <div className="container Page">
        <div className="row Page__row">
          {navegation}
          <div className={`col-12 ${(navegation || aside) && "col-md-9 col-lg-7"}`}>
            <div className="p-md-3">{tabs}</div>
            {content}
          </div>
          {aside}
        </div>
      </div>
      <CustomSection sections={page.section} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
query PageTemplate($slug: String!) {
  strapiPages(slug: {eq: $slug}) {
    pageMetadata {
      keywords: pageKeywords
      pageTitle
      id
      description: pageDescription
    }
    name
    strapiId
    slug
    summary
    title
    video {
      id
      nameVideo
      type
      urlVideo
    }
    strapiParent {
      id
      title
      slug
      name
    }
    slider {
      id
      type
      imageSlider {
        id
        alt
        nameImage {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    section {
      type
      fields {
        enable
        title
        summary
        anchor
        theme
      }
      careers {
        id
      }
      form {
        id
        Name
        PortalID
        formId
        formType
      }
      blog_articles {
        id
      }
      testimonies {
        id
      }
      news_contents {
        id
      }
      events {
        date
        event_category
        id
        summary
        time
        title
      }
      videos {
        id
      }
    }
    aside {
      id
      paragraph
      fields {
        anchor
        enable
        theme
        title
      }
      icon {
        id
        description
        name
      }
      image {
        id
        alt
        title
        type
        nameImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      type
      video {
        id
        nameVideo
        type
        urlVideo
      }
    }
    content {
      imageSlider {
        alt
        title
        type
        nameImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      description
      link {
        name
        url
      }
      icon {
        name
      }
      banner_item {
        id
      }
      script {
        enable
        name
        src
      }
      fields {
        anchor
        enable
        summary
        theme
        title
      }
      id
      paragraph
      source
      title
      image {
        alt
        id
        title
        type
        nameImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      video {
        urlVideo
      }
      type
      externalSrc {
        url
        name
        type
        id
      }
      internalSrc {
        singlePage
        name
        career {
          slug
          id
          name
        }
        page {
          id
          slug
          title
        }
      }
      card {
        id
        title
        subtitle
        label
        description
        image {
          alt
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    tabs {
      type
      fields {
        title
        summary
      }
      Items {
        id
        title
        detail
      }
      accordionBlock {
        id
        title
        itemsAccordion {
          id
          title
          detail
        }
      }
      textBlock {
        fields {
          anchor
          enable
          summary
          theme
          title
        }
        id
        paragraph
        type
        image {
          id
          alt
          title
          type
          nameImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          urlVideo
        }
      }
    }
    image {
      alt
      id
      title
      type
      nameImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    internal {
      content
      description
      ignoreType
      mediaType
    }
    menuSection {
      id
      nameSection
      slug
      link
    }
    navegation {
      id
      type
      enable
      externalLinks {
        id
        name
        type
        url
      }
      internalLinks {
        id
        name
        type
        page {
          id
          slug
          title
        }
        singlePage
        career {
          id
          slug
          name
        }
      }
    }
  }
}
`
