import { useStaticQuery, graphql } from "gatsby"

const useMenuSections = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiMenuSections(sort: { order: ASC, fields: order }) {
        nodes {
          strapiId
          nameSection
          slug
          link
          pages {
            slug
            title
            id
            parent
            name
          }
          linkImage
          nameLinkImage
          descriptionImage
          titleImageSection
          imageSection {
            publicURL
          }
          submenu{
            id
            level1internal{
              id
              name
              page{
                id
                name
                slug
              }
              singlePage
              career{
                id
                name
                slug
              }
            }
            level1external{
                id
                name
                url
            }
              level2{
                id
                type
                externalLinks{
                  id
                  name
                  url
                }
                internalLinks{
                  id
                  name
                  page{
                    id
                    name
                    slug
                  }
                  singlePage
                  career{
                    id
                    name
                    slug
                  }
                }
              
            }
          }
        
        }
      }
    }
  `)
  return query
}

export default useMenuSections
