import React from "react"
import "./newsCard.scss"
// import { getImage } from "gatsby-plugin-image"
// import { BgImage } from "gbimage-bridge"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const NewsCard = ({
  newsTag,
  newsImage,
  title,
  alt,
  published_at,
  slug,
  destacado,
  cols,
  variant,
}) => {
  const categories =
    variant === "blogArticle"
      ? newsTag?.map((category, i) => (
          <p key={i} className="card-category">
            {category}
          </p>
        ))
      : null

  const articuloDestacados = destacado && (
    <p>
      <span>Destacado</span>
    </p>
  )

  return (
    <div className={`col-12 col-md-4 col-lg-${cols} mb-3 p-0`}>
      <div className="card">
        {newsImage?.url ? (
          <img src={newsImage.url} alt={alt} className={`card-bgImage`} />
        ) : (
          <StaticImage
            src="../../images/logo-siglo.png"
            quality={100}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="no-image"
            className={`card-bgImage-news default`}
          />
        )}
        <div className="card-newsBody">
          {categories}
          <h3 className="card-title">{title}</h3>
          <p className="card-text">{published_at}</p>
        </div>
        {variant === "blogArticle" ? (
          <Link to={`/identidad21/${slug}`} className="card-hover"></Link>
        ) : (
          <Link to={`/noticias/${slug}`} className="card-hover"></Link>
        )}
      </div>
    </div>
  )
}

export default NewsCard
