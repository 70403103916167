import React, { useEffect, useState } from "react"
import useAppContext from "../../context/useAppContext"
import "./_academicOfferFilter.scss"
import { useAcademicOffer } from "../../hooks"

import {
  SearchInput,
  CareerCard,
  CheckBoxFilters,
  CustomSection,
  LoadButton,
  Seo,
} from "../index"
import FloatingWhatsapp from "../FloatingWhatsapp/FloatingWhatsapp.component"

const AcademicOfferFilter = ({ filter, filterMenu }) => {
  const { results, loadMore, seeMore, InitialFilter } = useAppContext()
  const data = useAcademicOffer()
  const totalCareers = results.length
  const sections = data?.allStrapiAcademicOffer?.nodes[0].sections
  const pageInfo = data?.allStrapiAcademicOffer?.nodes[0]
  const [menuMark, setMenuMark] = useState(false)

  useEffect(() => {
    if (filterMenu !== null && filterMenu !== undefined) {
      const queryFilter = {
        type: filterMenu.id,
        value: filterMenu.value,
      }
      filter.state.id = filterMenu.id
      filter.state.value = filterMenu.value
      InitialFilter(queryFilter)
      setMenuMark(true)
    } else {
      if (filter.state !== null && filter.state !== undefined) {
        const queryFilter = {
          type: filter.state.id,
          value: filter.state.value,
        }
        InitialFilter(queryFilter)
      }
    }
  }, [filterMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  const careers = results
    .slice(0, loadMore)
    .map(career => <CareerCard key={career.id} career={career} />)

  const buttonDisabled = loadMore >= totalCareers ? true : false

  let SEO = ""
  if (
    pageInfo.academicOfferSeo !== null &&
    pageInfo.academicOfferSeo !== undefined &&
    pageInfo.academicOfferSeo.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = pageInfo.academicOfferSeo

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  } else {
    SEO = <Seo title={"Búsqueda Oferta Académica"} />
  }

  return (
    <div className="AcademicOffer">
      {SEO}
      <div>
        <SearchInput
          title={pageInfo.title ? pageInfo.title : "Oferta academica"}
          summary={
            pageInfo.summary
              ? pageInfo.summary
              : "Programas de pregrado, grado y posgrado para tu formación profesional."
          }
          placeholder={
            pageInfo.placeholder ? pageInfo.placeholder : "Buscar programa"
          }
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-3">
            <CheckBoxFilters filter={filter} menuMark={menuMark} />
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">{careers}</div>
              <div className="row justify-content-center py-5">
                <LoadButton
                  content={"Cargar más entradas"}
                  variant={"button-green"}
                  click={seeMore}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CustomSection sections={sections} />
      </div>
      <FloatingWhatsapp />
    </div>
  )
}

export default AcademicOfferFilter
