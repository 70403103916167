import React from "react"
import { LoadButton } from "../index"
import "./_calendar.scss"

const Calendar = ({ month, year, nextMonth, lastMonth, events, action }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]
  const monthFilter = month < 9 ? "0" + (month + 1) : month + 1

  //tomar el nro maximo de dias del mes
  const maxNroDays = new Date(year, month + 1, 0).getDate()

  //tomar que dia es el primero de ese mes-año
  const firstDay = new Date(year + "-" + (month + 1) + "-01 00:00:01").getDay()

  //tomar el nombre del mes
  const monthName = months[month]

  //Funcionar para filtrar x calendario
  const filterCalendar = day => {
    action(year + "-" + monthFilter + "-" + (day < 10 ? "0" + day : day))
  }

  let cols = []
  let rows = []
  let check = 0

  for (let i = 0; i <= 6; i++) {
    if (firstDay === i) {
      if (check === 6) {
        cols.push({
          value: 1,
          isEvent:
            events.find(
              event => event.date === year + "-" + monthFilter + "-01"
            ) !== undefined
              ? 1
              : 0,
          start: check,
        })
        rows.push({ cols: cols })
        cols = []
        check = 0
      } else {
        cols.push({
          value: 1,
          isEvent:
            events.find(
              event => event.date === year + "-" + monthFilter + "-01"
            ) !== undefined
              ? 1
              : 0,
          start: check,
        })
        check++
      }

      break
    } else {
      cols.push({ value: "", isEvent: 0, start: check })
      check++
    }
  }

  for (let day = 2; day <= maxNroDays; day++) {
    if (check < 6) {

      cols.push({
        value: day,
        isEvent:
          events.find(
            event =>
              event.date ===
              year + "-" + monthFilter + "-" + (day < 10 ? "0" + day : day)
          ) !== undefined
            ? 1
            : 0,
        start: check,
      })
      check++
      if (day === maxNroDays) {
        if (cols.length < 7) {
          const colNumber= 7 - cols.length 
          for (let j = 1 ; j <= colNumber; j++) {
              cols.push({value:"", isEvent:0, start:check})
          }
      }
        rows.push({ cols: cols })
      }
    } else {
      cols.push({
        value: day,
        isEvent:
          events.find(
            event =>
              event.date ===
              year + "-" + monthFilter + "-" + (day < 10 ? "0" + day : day)
          ) !== undefined
            ? 1
            : 0,
        start: check,
      })
      rows.push({ cols: cols })
      cols = []
      check = 0
    }
  }

  const arrayToPrint = rows.map(row => (
    <tr>
      {row.cols.map(col =>
        col.isEvent === 1 ? (
          <td>
            <button onClick={() => filterCalendar(col.value)}>
              {col.value}
            </button>
          </td>
        ) : (
          <td>{col.value}</td>
        )
      )}
    </tr>
  ))

  return (
    <div className="calendar">
      <div className="calendar__header">
        <div>
          {monthName} <span> </span>
          {year}
        </div>
        <div className="calendar__header__buttons">
          <LoadButton
            content={"<"}
            variant={"button-green mr-1"}
            click={lastMonth}
          />
          <LoadButton
            content={">"}
            variant={"button-green"}
            click={nextMonth}
          />
        </div>
      </div>

      <table class="calendar__table">
        <thead className="calendar__table__head">
          <tr>
            <td>Dom</td>
            <td>Lun</td>
            <td>Mar</td>
            <td>Mié</td>
            <td>Jue</td>
            <td>Vie</td>
            <td>Sab</td>
          </tr>
        </thead>
        <tbody className="calendar__table__body">{arrayToPrint}</tbody>
      </table>
    </div>
  )
}

export default Calendar
