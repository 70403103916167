import { useStaticQuery, graphql } from "gatsby"

const useHome = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiHome {
        nodes {
          sections {
            fields {
              enable
              title
              summary
              anchor
              theme
            }
            latest
            type
            links {
              externalLinks {
                id
                name
                url
              }
              internalLinks {
                id
                name
                page {
                  id
                  slug
                  title
                }
                singlePage
                career {
                  id
                  slug
                  name
                }
              }
            }
            description
            title
            video {
              id
              nameVideo
              urlVideo
            }
            blog_articles {
              id
            }
            testimonies {
              id
            }
            news_contents {
              id
            }
            videos {
              id
            }
            metrics {
              id
            }
            social_medias {
              id
            }
            form {
              id
              Name
              PortalID
              formId
              formType
            }
            events {
              date
              event_category
              id
              summary
              time
              title
            }
            careers {
              id
            }
            banner_item {
              id
            }
          }
          banner {
            banner_items {
              id
            }
          }
          homeSeo {
            pageDescription
            pageKeywords
            pageTitle
          }
          titleHome {
            title
            detail
          }
        }
      }
    }
  `)
  return query
}
export default useHome