import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../icons/fontawesome"

const Icon = ({ type, code }) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={[ type, code]} />
    </React.Fragment>
  )
}

export default Icon
