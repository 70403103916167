import React from 'react'
import BannerItem from './bannerItem.component';
import BannerItemDestacado from './bannerItemDestacado.component';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const BannerCarousel = ({ bannerDestacado, otherBanners, type }) => {

    const bannersInCarousel = otherBanners.map((banner, idx) => (
        <div key={idx}>
            <BannerItem banner={banner} type={type} />
        </div>
    ))

    return (
        <Carousel autoPlay={true} infiniteLoop={true} showStatus={false} showArrows={false} showThumbs={false}>
            {bannerDestacado && (
                <div>
                    <BannerItemDestacado bannerDestacado={bannerDestacado} type={type} showStatus={false} />
                </div>
            )}
            {bannersInCarousel}
        </Carousel>
    );
}

export default BannerCarousel
