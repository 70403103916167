import React from "react"
import "./_eventCheckBoxList.scss"

const EventCheckBoxList = props => {
    const { id, name, onPress, value, idType } = props

    const handlePressCheckBox = (e) => {
        onPress(e, idType)
    }

    return (
        <div>
            <label className="CheckboxList__label">
                <input
                    className="CheckboxList__input"
                    type="checkbox"
                    id={`${id}`}
                    name={`${name}`}
                    value={`${value}`}
                    onClick={handlePressCheckBox}
                />
                {name}
            </label>
        </div>
    )
}

export default EventCheckBoxList
