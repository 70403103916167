import React from "react"
import { Breadcrumb, Image, Video, Icon, Slider } from ".."
import "./_headerPage.scss"
// import { Button } from "../index"
import { Popover, OverlayTrigger, Button } from "react-bootstrap"

const HeaderPage = ({
  title,
  summary,
  category,
  subtitle,
  image,
  video,
  slider,
  icon,
  iconType,
  time,
  dateDay,
  dateMonth,
  slug,
  form,
  pageUrl,
  paginaName,
  pageSectionLink,
}) => {
  let shareUrl = ""
  if (typeof window !== "undefined") {
    shareUrl = window.location.href
  }
  const media =
    video ? (
      <Video data={video} variant="headerPage" cols={12} />
    ) : slider ? (
      <Slider slider={slider} />
    ) : !image?.nameImage?.url ? (
      <Image data={image} background={true} variant="headerPage" />
    ) : (
      <img src={image?.nameImage?.url} alt={image?.nameImage?.name} className={`card-bgImage`}/>
    )
  const categoryBlock = category && (
    <div className="headline__main__category">
      <div className="headline__main__category__icon">
        <Icon type={iconType} code={icon} />
      </div>
      <p className="headline__main__category__text">{category}</p>
    </div>
  )
  const subtitleBlock = subtitle && (
    <p className="headline__main__subtitle">{subtitle}</p>
  )
  const summaryBlock = summary && <p>{summary}</p>
  const timeBlock = time && (
    <div className="headline__main__time">
      <Icon type="far" code="clock" />
      <p>{time.slice(0, 5)}hs.</p>
    </div>
  )
  const dateBlock = dateDay && dateMonth && (
    <div className="headline__main__date">
      <p className="headline__main__date--month">{dateMonth.slice(0, 3)}.</p>
      <p className="headline__main__date--day">{dateDay}</p>
    </div>
  )
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="popoverContent">
          <Icon type="fab" code="whatsapp" />
          <a
            href={`https://api.whatsapp.com/send?text=${shareUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            Invitar a un amigo
          </a>
        </div>
      </Popover.Content>
    </Popover>
  )
  const buttonsBlock = dateDay && dateMonth && (
    <div className="d-flex mt-2">
      {form && (
        <Button variant={"light mr-3"} href={`#${form}`}>
          Inscribite
        </Button>
      )}
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <Button variant="button-outline">Invita a un amigo</Button>
      </OverlayTrigger>
    </div>
  )

  return (
    <div className="container px-0">
      <div className="headline__breadcrumb col-12 pr-0">
        <Breadcrumb
          title={title}
          pageUrl={pageUrl}
          paginaName={paginaName}
          pageSectionLink={pageSectionLink}
        />
      </div>
      <div className="headline__main">
        <div className="col-12 col-md-7 py-4 d-flex">
          {dateBlock}
          <div>
            {categoryBlock}
            {subtitleBlock}
            <h1>{title}</h1>
            {summaryBlock}
            {timeBlock}
            {buttonsBlock}
          </div>
        </div>
        {(video || image) && (
          <div className="col-12 col-md-5 px-0">
            <div className="headline__media">{media}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderPage
