import React from "react"
import Markdown from "react-markdown"
import { Icon } from "../"

const Text = ({ text, variant, icon }) => {

  return (
    <div>
      {icon && (
        <div style={{ paddingLeft: "16px", marginBottom: "-14px"}}>
            <Icon type={icon?.icon?.type} code={icon?.icon?.code} />
        </div>
      )}
      <div className={`markdown text ${variant}__text`}>
        <Markdown escapeHtml={true}>{text}</Markdown>
      </div>      
    </div>
  )
}

export default Text
