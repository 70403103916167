import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import { useEvents } from "../../hooks"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import "./_carouselEvents.scss"

const CarouselEvents = ({ events }) => {
    const [index, setIndex] = useState(0)
    const data = useEvents()
    const eventsData = data?.allStrapiEvents?.nodes

    const customEvent = events.map(event =>
        eventsData.find(art => art.strapiId === event.id)
    )

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex)
    }

    const destacados = customEvent.map((event, idx) => (
        event !== undefined &&  
        <Carousel.Item key={idx} className="CarouselEvent">
            <Link to={event?.slug} className="CarouselEvent__link">
                <div className="row CarouselEvent__row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 CarouselEvent__colImg">
                        <BgImage image={getImage(event?.eventImage?.nameImage)} className="CarouselEvent__BgImage">
                        </BgImage>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 CarouselEvent__colText">
                        <div className="card CardEventCarousel">
                            <div className="card-body CardEventCarousel__body">
                                <p className="card-text">{event?.formatDateDay} de {event?.formatDateMonth}</p>
                                <p className="card-title CardEventCarousel__title">{event?.title}</p>
                                <p className="card-text">{event?.summary}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </Link>
        </Carousel.Item>
    ))

    return (
        <Carousel fade activeIndex={index} onSelect={handleSelect}>
            {destacados}
        </Carousel>
    )
}

export default CarouselEvents
