import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "../../hooks/"

const BotIframe = () => {
  const iframeRef = useRef(null)
  const [iframeSize, setIframeSize] = useState("minimized")
  const { windowWidth } = useWindowSize()
  let width
  let height

  useEffect(() => {
    const handleResize = (width, height) => {
      const iframe = iframeRef.current
      if (iframe) {
        iframe.style.width = width
        iframe.style.height = height
        iframe.style.transition = "all 0.3s ease"
      }
    }

    const handleChatResize = size => {
      const isMobile = windowWidth <= 768

      if (size === "minimized" && iframeSize !== "minimized") {
        const width = isMobile ? "220px" : "260px"
        const height = "105px"
        handleResize(width, height)
        setIframeSize("minimized")
      } else if (size === "expanded" && iframeSize !== "expanded") {
        const width = isMobile ? "95%" : "480px"
        const height = isMobile ? "100%" : `calc(100vh - 8.2%)`
        handleResize(width, height)
        setIframeSize("expanded")
      }
    }

    const handleDocumentClick = ({ x, y, isTrusted, targetClass }) => {
      if (!isTrusted || (x === 0 && y === 0)) {
        return
      }

      if (targetClass === "minimize-btn" || targetClass === "close-btn") {
        handleChatResize("minimized")
      } else if (
        targetClass === "messages" ||
        targetClass === "minimized-title" ||
        targetClass === "notification-badge" ||
        targetClass === "minimized"
      ) {
        handleChatResize("expanded")
      }
    }

    const messageHandler = event => {
      const { event: eventName, details } = event.data
      if (!eventName) return
      if (eventName === "DocumentClick") {
        handleDocumentClick(details)
      }
    }

    window.addEventListener("message", messageHandler)

    return () => {
      window.removeEventListener("message", messageHandler)
    }
  }, [iframeSize, windowWidth])

  const isMobile = windowWidth <= 768

  if (iframeSize === "expanded") {
    width = isMobile ? "95%" : "480px"
  } else {
    width = "270px"
  }

  if (iframeSize === "expanded") {
    if (isMobile) {
      height = "100%"
    } else {
      height = `calc(100vh - 8.2%)`
    }
  } else {
    height = "105px"
  }

  const zIndex = iframeSize === "expanded" ? 2000 : 100

  return (
    <div>
      <iframe
        title="Bot Servicio"
        ref={iframeRef}
        id="botIframe"
        src="bot-servicios.html"
        style={{
          position: "fixed",
          bottom: "1px",
          right: "15px",
          width: width,
          height: height,
          border: "none",
          zIndex: zIndex,
          transition: "all 0.3s ease",
        }}
      ></iframe>
    </div>
  )
}

export default BotIframe