import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import "./_image.scss"

const Image = ({ data, background, variant, cols }) => {
  const image = getImage(data?.nameImage)
  const imageContent = background ? (
    <BgImage image={image} className={`bgImage ${variant} `} />
  ) : (
    <GatsbyImage image={image} alt={data?.alt} />
  )
  return <div className={`${variant}__img col-${cols}`}>{imageContent}</div>
}
export default Image
