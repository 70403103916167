import "./_benefitsVideo.scss"
import React from 'react'
import { InternalLink } from "../index"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"

const BenefitsVideo = ({ data }) => {

    const url = data?.video?.urlVideo.replace("watch?v=", "embed/")
    const theme = data?.fields?.theme
    let code = url.substring(url.lastIndexOf("/") + 1, url.length)
    const codeIndex = code.indexOf("?")

    if (codeIndex !== -1) {
        code = code.substring(0, code.indexOf("?"))
    }

    const externalLinks = data?.links?.externalLinks?.map((link => (
        <a className="benefits__link" target="_blank" rel="noreferrer" key={`link-${link.id}`} href={link.url}>
            {`${link.name} `}<FaChevronRight size={16} />
        </a>
    )))

    return (
        <div className={`benefits container-fluid theme-${theme}`}>
            <div className="container ">
                <h2 className="benefits__section-title col-12">{data.fields.title}</h2>
                <div className="row no-gutters videoBlock">
                    <iframe
                        loading="lazy"
                        className="benefits__video col-12 col-lg-7"
                        type="text/html"
                        src={url}
                        frameBorder="0"
                        allowFullScreen
                        title="benefits_video"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;
                            width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
                            <a href=${url}>
                            <img src=https://img.youtube.com/vi/${code}/hqdefault.jpg alt='Video'>
                            <span>▶</span></a>`}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                    ></iframe>
                    <div className="benefits__links-block col-12 col-lg-5">
                        <div className="row">
                            <h2 className="benefits__title col-12">{data.title}</h2>
                            <p className="benefits__description col-12">{data.description}</p>

                            <ul className="benefits__links col-12">
                                {(externalLinks) && ([...externalLinks])}
                                {data?.links?.internalLinks !== undefined && data?.links?.internalLinks !== null ?
                                    <InternalLink internalLinks={data?.links?.internalLinks} variant={'internalLink__link'} />
                                    : null}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitsVideo
