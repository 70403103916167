import { useStaticQuery, graphql } from "gatsby"

const useSocialMedia = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiSocialMedias(filter: { visible: { eq: true } }) {
          nodes {
            id
            name
            socialMediaCode
            strapiId
          }
        }
      }
    `)
    return query 
}

export default useSocialMedia
