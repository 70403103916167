import React from "react"
import { Link } from "gatsby"
import { SocialMediaIcons } from "../index"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./_footer.scss"
import { useFooter } from "../../hooks"
import { LinksBlock, ImagesBlock, ContactsBlock } from "../"

const Footer = () => {
  const footer = useFooter()

  const { logo, blocks, socialMediaIcons } = footer?.allStrapiFooter?.nodes[0]

  const image = getImage(logo?.nameImage)

  const iconLinks = socialMediaIcons?.iconLinks
  const socialMediaTitle = socialMediaIcons?.title

  const block = blocks
    .map(block => {
      return (
        <React.Fragment key={`${block?.id}-${block?.type}`}>
          {block?.type === "linksBlock" ? (
            <LinksBlock
              titleLink={block?.titleLink}
              externalLinks={block?.links?.externalLinks}
              internalLinks={block?.links?.internalLinks}
            />
          ) : null}

          {block?.type === "contactsBlock" ? (
            <ContactsBlock
              title={block?.title}
              contacts={block?.contacts}
              iconsContacts={block?.iconsContacts}
            />
          ) : null}

          {block?.type === "imagesBlock" ? (
            <ImagesBlock title={block?.title} images={block?.images} />
          ) : null}
        </React.Fragment>
      )
    })
    .slice(0, 6)

  return (
    <footer className="footer">
      <div className="container">
        <Link to="/">
          <GatsbyImage
            alt={logo?.alt}
            image={image}
            className="footer__container__logo"
          />
        </Link>

        <div className="footer__main">{block}</div>
      </div>
      {socialMediaIcons && (
        <SocialMediaIcons message={socialMediaTitle} iconLinks={iconLinks} />
      )}
    </footer>
  )
}

export default Footer
