import React from "react"
import { FaAngleDown, FaAngleUp } from "../icons/index"
import { OneInternalLink } from '..'
import "./_accordion.scss"

const Accordion = ({ title, titleLink, onActive, state, isLink}) => {
  const handleAccordion = () => {
    onActive(state)
  }
  return (
    <div className="Accordion">
      {
        isLink ? 
        (<OneInternalLink internalLink={titleLink} />) :
        (<p className="Accordion__title">{title}</p>)
      }
      <button
        onClick={handleAccordion}
        className="row Accordion__btn btn btn-outline "
      >
        {state ?  <FaAngleUp /> : <FaAngleDown /> }
      </button>
    </div>
  )
}

export default Accordion
