import React, { useState } from "react"
import { useGallery } from "../../hooks"
import { LoadButton, Seo, Content } from "../index"
import GalleryItem from "./galleryItem.component"
import "./_gallery.scss"
import "../../styles/_markdown.scss"

const Gallery = () => {
  const data = useGallery()
  const numberItems = 9
  const [loadMore, setLoadMore] = useState(numberItems)

  const page = data?.strapiGalleryPage
  const galleryItems = data?.allStrapiGalleryItems?.nodes

  const seeMore = () => {
    setLoadMore(loadMore + numberItems)
  }

  let SEO = ""
  if (
    page.galleryMetadata !== null &&
    page.galleryMetadata !== undefined &&
    page.galleryMetadata.pageTitle !== null
  ) {
    const { description, keywords, pageTitle } = page.galleryMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  const content =
  page.content.length !== 0 ? (
    <Content content={page.content} />
  ) : null

  const cards = galleryItems
    .sort(((a, b) => {
      return a.firstPageOrder - b.firstPageOrder
    }))
    .slice(0, loadMore)
    .map((item, idx) => <GalleryItem key={`gallery-${idx}`} item={item} />)

  return (
    <>
      {SEO}
      <div className="gallery container">
        <div className="gallery__section">
          {content}
          <h2 className="gallery__section-title">{page.cardsSectionTitle}</h2>
          <div className="gallery__section-cards row">{cards}</div>
          {galleryItems.length > numberItems && (
            <LoadButton
              content={"Cargar más"}
              variant={"button-green"}
              click={seeMore}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Gallery
