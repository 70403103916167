import * as React from "react"
import { Link } from "gatsby"
import "./_button.scss"

const Button = props => {
  const { content, variant, path, icon, icon2, extPath } = props

  let button

  if (content.startsWith("http") || content.startsWith("/static/")){
    button = <img src={content} alt={`${content}`} className={variant} />
  } else if (extPath) {
    button = (
      <a href={extPath} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" className={`${variant}`}>
          {content}
      </a>
    )
  } else {
    button = (
      <Link to={path} style={{ textDecoration: 'none' }}>
        <button className={`${variant}`}>
          {/* Si se coloca un ícono, darle margen izquierdo o derecho con style-inline */}
          {icon}
          {content}
          {icon2}
        </button>
      </Link>
    )}

  return button
}

export default Button
