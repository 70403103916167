import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import { useBlogArticles, useNews } from "../../hooks"

import { Card } from "../index"
import "./_carouselItem.scss"

const ControlledCarousel = ({ articles, variant }) => {
  const [index, setIndex] = useState(0)
  const data = useBlogArticles()
  const blogArticle = data?.allStrapiBlogArticles?.nodes
  const dataNews = useNews()
  const newsContent = dataNews?.allStrapiNewsContents?.nodes

  const customArticle =
    variant === "event"
      ? articles.map(article =>
          blogArticle.find(art => art.strapiId === article.id)
        )
      : articles.map(article =>
          newsContent.find(art => art.strapiId === article.id)
        )

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const destacados = customArticle.map((article, idx) => (
    <Carousel.Item key={idx} className="CarouselItem">
      <Card article={article} variant={variant}/>
    </Carousel.Item>
  ))

  return (
    <Carousel fade activeIndex={index} onSelect={handleSelect}>
      {destacados}
    </Carousel>
  )
}

export default ControlledCarousel
