import { useStaticQuery, graphql } from "gatsby"

const useGlobalConfig = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiGlobalConfig {
        nodes {
          id
          Script {
            enable
            id
            name
            src
          }
          formHeader{
            id
            Name
            PortalID
            formId
            formType
          }
          searchCareerHeader {
            fields {
              title
              theme
              enable
            }
            type
          }
          facebookMeta
        }
      }
    }
  `)
  return query
}
export default useGlobalConfig
