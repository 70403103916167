import { useStaticQuery, graphql } from "gatsby"

const useMetrics = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiMetrics(sort: { fields: published_at, order: ASC }) {
        nodes {
          id
          name
          cifra
          subtitle
          description
          iconCifra {
            childImageSharp {
              gatsbyImageData
            }
          }
          picture {
            childImageSharp {
              gatsbyImageData
            }
          }
          strapiId
        }
      }
    }
  `)
  return query
}

export default useMetrics
