import React from "react"
import { useContact } from "../../hooks"
import { Seo, HeaderPage, CustomSection, Content, NavegationContent } from "../index"

const Contact = () => {
    const data = useContact()
    const page = data?.allStrapiContacPage?.nodes[0]
    const sections = page?.sections

    let SEO = ""
    if (page.contactSeo !== null && page.contactSeo !== undefined && page.contactSeo.pageTitle !== null) {
        const { description, keywords, pageTitle } = page.contactSeo

        SEO = (
            <Seo
                title={`${pageTitle}`}
                description={`${description}`}
                keywords={`${keywords}`}
            />
        )
    }

    const headerPage = (
        <HeaderPage
            title={page.title}
            subtitle={page.subtitle}
            pageUrl="/contacto" 
            paginaName={["Contacto"]}
        />
    )

    const content =
        page.sections.length !== 0 ? (
            <Content content={page.sections} />
        ) : (
            <div className="col-12 col-md-7"></div>
        )

    const navegation =
        page.navegation.length !== 0 ? (
            <NavegationContent
                content={page.navegation}
                id={page?.strapiId}
                name={page.name}
                hiddecontact={true}
            />
        ) : null

    return (
        <>
            {SEO}
            <div className="headline">
                {headerPage}
            </div>
            <div className="container">

                <div className="row Page__row">
                    {navegation}
                    {content}
                </div>
            </div>
            <CustomSection sections={sections} />
        </>
    )
}

export default Contact
