import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FaUser } from "@react-icons/all-files/fa/FaUser"
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap"
import Markdown from "react-markdown"
import { OneInternalLink } from "../index"

import "./_cardsList.scss"

const CardsList = ({ data, title }) => {
    const cardsList = data.map(card => {
        const image = getImage(card?.image?.nameImage)

        return (
            <div className="col-12 person pt-4">
                <div className="personCard">
                    {card?.label && (
                        <div className="personCard__label">{card.label}</div>
                    )}
                    <GatsbyImage image={image} alt={card?.image?.alt} className="personCard__img" />
                    <div className="personCard__description">
                        <div>
                            {card.subtitle && (<p><b>{card.subtitle}</b></p>)}
                            <p>
                                {card.subtitle ?
                                    < FaUser size={16} color="#00806e" /> :
                                    < FaGraduationCap size={16} color="#00806e" />}
                                <b className="personCard__description_title">{` ${card.title}`}</b>
                            </p>
                            {card.description && (
                                <div className="markdown personCard__description_text">
                                    <Markdown escapeHtml={true}>
                                        {card.description}
                                    </Markdown>
                                </div>
                            )
                            }
                        </div>

                        {card?.internalLink &&
                            <OneInternalLink internalLink={card?.internalLink} variant={`menu`} />
                            // <Button
                            //     content={card?.internalLink?.name}
                            //     path={`/la-universidad/autoridades/${card?.internalLink?.page?.slug}`}
                            //     variant={`button-yellow`}
                            // />
                        }
                    </div>
                </div>
            </div>
        )
    })
    return (
        <div className="container-fluid">
            {title && (
                <div className="row mt-2 mb-3">
                    <h2 className="col-12 text-center cardsList__title">{title}</h2>
                </div>
            )}
            {cardsList}
        </div>
    )
}

export default CardsList
