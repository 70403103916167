import React, { useEffect, useState } from "react"
import { useBanner } from "../../hooks"
import BannerCarousel from "./bannerCarousel.component"
import BannerDesktopLayout from "./bannerDesktopLayout"
import { useWindowSize } from "../../hooks/"

const Banner = ({ bannerItems }) => {
  const data = useBanner()
  const dimensions = useWindowSize()
  const bannerItemsList = data?.allStrapiBannerItems?.nodes
  const [bannerToDisplay, setBannerToDisplay] = useState(null)

  const customBannerList = bannerItems.map(item =>
    bannerItemsList.find(it => it.strapiId === item.id)
  )

  const bannerDestacado = customBannerList.filter(item => item?.destacado)[0]

  const otherBanners = customBannerList
    .filter(item => !item.destacado)
    .slice(0, 3)
    .sort((a, b) => {
      return a.order - b.order
    })

  useEffect(() => {
    setBannerToDisplay(
      dimensions.windowWidth <= 992 ?
        <BannerCarousel
          otherBanners={otherBanners}
          bannerDestacado={bannerDestacado}
          type={'mobile'}
        />
        :
        <BannerDesktopLayout
          otherBanners={otherBanners}
          bannerDestacado={bannerDestacado}
          type={'desktop'}
        />
    )

  }, [dimensions.windowWidth])


  return (
    <>
      {bannerToDisplay ||
        <BannerCarousel
          otherBanners={otherBanners}
          bannerDestacado={bannerDestacado}
          type={'mobile'}
        />

      }
    </>
  )
}

export default Banner
