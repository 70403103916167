import React from "react"
import { useGlobalConfig } from "../../hooks"
import { SearchCareer } from "../"

const EnrollForm = () => {
  const data = useGlobalConfig()
  const dataform = data.allStrapiGlobalConfig.nodes

  // const formComponent =
  //   dataform[0]?.formHeader !== null &&
  //   dataform[0]?.formHeader !== undefined ? (
  //     <ContactForm
  //       form={dataform[0]?.formHeader}
  //       theme={'dark'}
  //       title={dataform[0]?.formHeader.Name}

  //     />
  //   ) : null



  const formComponent =
    dataform[0].searchCareerHeader !== null &&
      dataform[0].searchCareerHeader !== undefined &&
      dataform[0].searchCareerHeader.fields?.enable &&
      dataform[0].searchCareerHeader.type === "searchCareer" ?
      (
        <SearchCareer
          title={dataform[0].searchCareerHeader.fields?.title}
          theme={'dark'}
          //theme={dataform[0].searchCareerHeader.fields?.theme}
          anchor={dataform[0].searchCareerHeader.fields?.enable}
        />
      ) : null

  return (
    <>
      <div className="enroll-form">
        {formComponent}
        {/* <div>{formComponent}</div> */}
      </div>
    </>
  )
}
export default EnrollForm
